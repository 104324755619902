import TableView from "./TableView";
import Details from "./Details";
import DetailsExam from "./DetailsExam";
import CreateOrEdit from "./CreateOrEdit";
import DetailsAdministredMedication from "./DetailsAdministredMedication";
import DetailsVaccine from "./DetailsVaccine";

export const Consultations = {
	TableView,
	Details,
	DetailsExam,
	CreateOrEdit,
	DetailsAdministredMedication,
	DetailsVaccine,
};

