import { makeAutoObservable } from "mobx";
import {
	AttributeShelf,
	FetchModelShelf,
	LoaderShelf,
} from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import { ConsultationShelf } from "~/shelves/ConsultationShelf";
import { ExamShelf } from "~/shelves/ExamShelf";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { AdministeredMedicationShelf } from "~/shelves/AdministeredMedicationShelf";
import { VaccineShelf } from "~/shelves/VaccineShelf";

export class Store {
	public fetchModelShelf: FetchModelShelf<api.Consultation | api.ConsultationForClinic>;
	public ratingModelShelf: FetchModelShelf<api.Rating | null>;
	public consultationShelf = new ConsultationShelf();
	public examShelf: ExamShelf;
	public administeredMedicationShelf: AdministeredMedicationShelf;
	public vaccineShelf: VaccineShelf;
	public loader = new LoaderShelf();
	public id = new AttributeShelf<string>("");

	constructor(id: string, method: (id: string) => Promise<api.Consultation | api.ConsultationForClinic>) {
		makeAutoObservable(this);
		this.fetchModelShelf = new FetchModelShelf(id, ()=> method(id),
			{
				onFetchError,
			});
		if (id) {
			this.examShelf = new ExamShelf(id, "consultation");
			this.administeredMedicationShelf = new AdministeredMedicationShelf(id, "consultation");
			this.vaccineShelf = new VaccineShelf(id, "consultation");
			this.id.setValue(id);
			this.getConsultation();
			this.ratingModelShelf = new FetchModelShelf(id, ()=> api.getRatingByConsultationId(id),
				{
					onFetchError,
				});
		}
	}
	public getConsultation = async () => {
		await this.fetchModelShelf.fetchModel();
		if (this.fetchModelShelf.fetchedModel.finishedAt) {
			this.ratingModelShelf.fetchModel();
		}
	};

	public finishConsult = async (id: string, onSuccess: () => void) => {
		this.loader.tryStart();
		try {
			await api.finishConsultation(id);
			onSuccess();
			showSuccessToast(strings.pages.consultations.details.finish);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
