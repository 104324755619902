import { makeAutoObservable } from "mobx";
import {
	FetchModelShelf,
	LoaderShelf,
} from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import { ConsultationShelf } from "~/shelves/ConsultationShelf";
import { ExamShelf } from "~/shelves/ExamShelf";
import { AdministeredMedicationShelf } from "~/shelves/AdministeredMedicationShelf";
import { VaccineShelf } from "~/shelves/VaccineShelf";
import { HospitalizationShelf } from "~/shelves/HospitalizationShelf";

export class Store {

	public fetchModelShelf: FetchModelShelf<api.Pet>;
	public loader = new LoaderShelf();
	public consultationShelf: ConsultationShelf;
	public examShelf: ExamShelf;
	public administeredMedicationShelf: AdministeredMedicationShelf;
	public vaccineShelf: VaccineShelf;
	public hospitalizationShelf: HospitalizationShelf;

	constructor(
		id: string,
		methodForRequestConsult: (page: number, petId: string) => Promise<api.Consultation[]>,
		methodForRequestHospitalization: (page: number, petId: string) => Promise<api.Hospitalization[] | api.HospitalizationForClinic[]>,
	) {
		makeAutoObservable(this);
		this.fetchModelShelf = new FetchModelShelf(id, ()=> api.getPetById(id),
			{
				onFetchError,
			},
		);
		if (id) {
			this.examShelf = new ExamShelf(id, "pet");
			this.administeredMedicationShelf = new AdministeredMedicationShelf(id, "pet");
			this.vaccineShelf = new VaccineShelf(id, "pet");
			this.fetchModelShelf.fetchModel();
			this.consultationShelf = new ConsultationShelf(id, methodForRequestConsult);
			this.hospitalizationShelf = new HospitalizationShelf(id, methodForRequestHospitalization);
		}
	}
}
