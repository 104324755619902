import React from "react";
import { useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Button,
	IconButton,
	Tooltip,
	Flex,
	Heading,
} from "@chakra-ui/react";

import format from "~/resources/format";
import strings from "~/resources/strings";
import { useHistory } from "~/hooks/useHistory";
import { Store } from "./store";
import {
	CentralizedCard,
	DetailsRow,
	InfoPetsOfUser,
	ImageWithModal,
	ButtonBack,
} from "~/components";
interface IProps {
	isClinic?: boolean;
}

const Details: React.FC<IProps> = (props) => {
	const commonStrings = strings.common;
	const { isClinic } = props;
	const { id } = useParams<"id">();
	const store = useLocalObservable(() => new Store(id || ""));
	const history = useHistory();
	const onGoToCreate = () => history.push(`/${ isClinic ? "clinic" : "admin"}/users/create/${id}`);

	return (
		<Flex justifyContent="center" flexDirection="column" alignItems="center">
			<>
				<CentralizedCard
					altMaxWidth
					title={{
						text: commonStrings.detailsTitle,
						helper: (
							<Tooltip label={strings.common.edit}>
								<IconButton
									variant="icon"
									aria-label="Voltar"
									size="lg"
								/>
							</Tooltip>
						),
					}}
					isLoading={store.fetchModelShelf.initialLoader.isLoading}
				>
					{
						store.fetchModelShelf.model.value &&
							<>
								<Heading fontSize="md" color="primary.500">{commonStrings.fields.user}</Heading>
								<ImageWithModal src={store.fetchModelShelf.fetchedModel.image?.url} />
								<DetailsRow
									label={commonStrings.fields.name}
									value={store.fetchModelShelf.fetchedModel.name}
								/>
								<DetailsRow
									label={commonStrings.fields.email}
									value={store.fetchModelShelf.fetchedModel.email}
								/>
								<Flex w="100%" flexDir={{base: "column", md: "row"}} justifyContent={{ base: "center", md: "space-between"}}>
									<DetailsRow
										label={commonStrings.fields.cpf}
										value={format.documentNumber(store.fetchModelShelf.fetchedModel.documentNumber || "")}
										boxProps={{
											width: {
												base: "100%",
												md: "47%",
											},
											marginBottom: {
												base: 5,
												md: 0,
											},
										}}
									/>
									<DetailsRow
										label={commonStrings.fields.phone}
										value={format.phone(store.fetchModelShelf.fetchedModel.phone)}
										boxProps={{
											width: {
												base: "100%",
												md: "47%",
											},
										}}
									/>
								</Flex>
								<InfoPetsOfUser user={store.fetchModelShelf.fetchedModel} />
							</>
					}
				</CentralizedCard>
				<Button mb={5} onClick={onGoToCreate}>{strings.actions.addPet}</Button>
				<ButtonBack />
			</>
		</Flex>
	);
};

export default observer(Details);
