import { makeAutoObservable } from "mobx";
import { FormShelf } from "@startapp/mobx-utils/src/web";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";

const pageStrings = strings.pages.vets;

export default class Store {

	public id = new AttributeShelf<string | undefined>("");
	public formShelf = new FormShelf({
		name: "",
		documentNumber: "",
	});

	public loader = new LoaderShelf();

	constructor(){
		makeAutoObservable(this);
	}

	public createVet = async (onSuccess: () => void) => {
		this.loader.start();
		try {
			const data = this.formShelf.getValues();
			await api.createVetForClinic({
				name: data.name,
				documentNumber: data.documentNumber,
			}, this.id.value || "");
			showSuccessToast(pageStrings.create.success);
			onSuccess();
		} catch (e) {
			const error = Errors.treatError(e);
			if (error.type === api.ErrorType.Validation) {
				this.formShelf.fieldError.cleanAndAddErrors(JSON.parse((e as Error).message));
			} else {
				const errorMessage = Errors.handleError(e);
				showErrorToast(errorMessage);
			}
		} finally {
			this.loader.end();
		}
	};
}
