import React from "react";
import { observer } from "mobx-react-lite";
import {
	Flex,
	Grid,
	Heading,
	StackProps,
	Text,
} from "@chakra-ui/react";
import { DetailsRow, SelectedMedicationsFluidTherapyse } from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";

interface IProps extends StackProps {
	anamnese: api.Anamnese;
	fluidtherapys: api.FluidTherapys[];
}

export const DetailsAnamnese: React.FC<IProps> = observer((props) => {
	const { anamnese, fluidtherapys } = props;
	const commonStrings = strings.common;

	return (
		<Flex direction="column" gap={5}>
			<Heading fontSize="md" color="primary.500">{strings.common.fields.physicalExam}</Heading>
			<Grid
				gridTemplateColumns={{
					base: "1fr",
					md: "repeat(2,1fr)",
				}}
				gap={6}
				mb={4}
			>
				<DetailsRow
					label={commonStrings.fields.heartRate}
					value={anamnese.heartRate || strings.common.empty}
				/>
				<DetailsRow
					label={commonStrings.fields.respiratoryFrequency}
					value={anamnese.respiratoryFrequency || strings.common.empty}
				/>
				<DetailsRow
					label={commonStrings.fields.tpc}
					value={anamnese.tpc || strings.common.empty}
				/>
				<DetailsRow
					value={anamnese.mucous || strings.common.empty}
					label={commonStrings.fields.mucous}
				/>
				<DetailsRow
					label={commonStrings.fields.pulse}
					value={anamnese.pulse || strings.common.empty}
				/>
				<DetailsRow
					label={commonStrings.fields.rectalTemperature}
					value={anamnese.rectalTemperature || strings.common.empty}
				/>
				<DetailsRow
					label={commonStrings.fields.cardiopulmonaryAuscultation}
					value={anamnese.cardiopulmonaryAuscultation || strings.common.empty}
				/>
				<DetailsRow
					label={commonStrings.fields.abdominalPalpation}
					value={anamnese.abdominalPalpation || strings.common.empty}
				/>
				<DetailsRow
					label={commonStrings.fields.bodyScore}
					value={anamnese.bodyScore || strings.common.empty}
				/>
				<DetailsRow
					label={commonStrings.fields.otherSystems}
					value={anamnese.otherSystems || strings.common.empty}
				/>
				<DetailsRow
					label={commonStrings.fields.complementaryExams}
					value={anamnese.complementaryExams || strings.common.empty}
				/>
				<DetailsRow
					label={commonStrings.fields.suspicions}
					value={anamnese.suspicions || strings.common.empty}
				/>
				<DetailsRow
					label={commonStrings.fields.diagnosis}
					value={anamnese.diagnosis || strings.common.empty}
				/>
				<DetailsRow
					label={commonStrings.fields.treatment}
					value={anamnese.treatment || strings.common.empty}
				/>
			</Grid>
			<Heading fontSize="md" color="primary.500">{strings.common.fields.fluidtherapy}</Heading>
			{fluidtherapys.length !== 0 ? (
				<SelectedMedicationsFluidTherapyse
					fluidTherapyse={fluidtherapys}
				/>): (<Text fontWeight="bold" color="gray.800">{strings.common.empty}</Text>)}
			<Flex direction="column" w="100%">
				<Heading
					mt={4}
					mb={2}
					fontSize="md"
					color="primary.500"
				>
					{strings.common.fields.indicatedDiet}
				</Heading>
				<DetailsRow
					label={commonStrings.fields.description}
					value={anamnese?.description || strings.common.empty}
				/>
			</Flex>
		</Flex>
	);
});

