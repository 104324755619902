import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Button,
	Flex,
} from "@chakra-ui/react";
import { useHistory } from "~/hooks/useHistory";
import { Store } from "./store";
import {
	CentralizedCard,
	DetailsClinicHospitalization,
	ExamFormModal,
	AdministeredMedicationFormModal,
	TableOfExams,
	TableOfMedication,
	DetailsHospitalization,
	ButtonBack,
} from "~/components";
import strings from "~/resources/strings";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import api from "~/resources/api";

interface IProps {
	isClinic?: boolean;
}

const Details: React.FC<IProps> = (props) => {
	const { isClinic } = props;
	const { id } = useParams<"id">();
	const history = useHistory();
	const { authStore, dialog } = useGlobalStore();
	const methodForGetHospitalization = isClinic ? api.getHospitalizationByIdForAdminClinic : api.getHospitalizationById;
	const store = useLocalObservable(() => new Store(id || "", methodForGetHospitalization));

	const [modalExamIsOpen, setModalExamIsOpen] = useState(false);
	const [modalMedicationIsOpen, setModalMedicationIsOpen] = useState(false);

	const onSuccess = () => {
		setModalExamIsOpen(false);
		setModalMedicationIsOpen(false);
	};
	const onFinish = () => {
		history.goBack();
	};

	const onGoToExamDetails = (idExam: string) => history.push(`/${isClinic ? "clinic" : "admin"}/hospitalization/details/exam/${idExam}`);
	const onGoToMedicationDetails = (idMedication: string) => history.push(`/${isClinic ? "clinic" : "admin"}/hospitalization/details/detailsAdministredMedication/${idMedication}`);

	const openDialog = () => {
		const modal = strings.common.modal;
		dialog.showDialog({
			title: modal.titleFinishHospitalization,
			closeOnOverlayClick: true,
			description: modal.descriptionFinish,
			buttons: [
				{
					title: modal.button.finish,
					onPress: () => {
						store.finishHospitalization(id || "", onFinish);
						dialog.closeDialog();
					},
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};
	return (
		<Flex
			justifyContent="center"
			flexDirection="column"
			alignItems="center"
			w="100%"
		>
			<>
				<CentralizedCard isLoading={store.fetchModelShelf.initialLoader.isLoading} >
					{
						!authStore.currentAdminUser?.clinic && store.fetchModelShelf.model.value && (
							<DetailsClinicHospitalization hospitalization={store.fetchModelShelf.model.value as api.Hospitalization} />
						)
					}
					<DetailsHospitalization hospitalization={store.fetchModelShelf.model.value as api.Hospitalization} />
				</CentralizedCard>
				<TableOfExams
					onGoToDetails={onGoToExamDetails}
					isClinic={!!authStore.currentAdminUser?.clinic}
					isFinished={!!store.fetchModelShelf.model.value?.finishedAt}
					openModal={() => setModalExamIsOpen(true)}
					exam={store.examShelf.paginatedListShelfExamsByHospitalization.items}
					previousPage={store.examShelf.paginatedListShelfExamsByHospitalization.previousPage}
					nextPage={store.examShelf.paginatedListShelfExamsByHospitalization.nextPage}
					page={store.examShelf.paginatedListShelfExamsByHospitalization.page}
					isLoading={store.examShelf.paginatedListShelfExamsByHospitalization.loader.isLoading}
					hasNextPage={store.examShelf.paginatedListShelfExamsByHospitalization.hasNextPage}
				/>
				<ExamFormModal
					examShelf={store.examShelf}
					isOpen={modalExamIsOpen}
					onCreate={() => store.examShelf.createExam(onSuccess)}
					onClose={() => setModalExamIsOpen(!modalExamIsOpen)}
				/>
				<TableOfMedication
					onGoToDetails={onGoToMedicationDetails}
					isClinic={!!authStore.currentAdminUser?.clinic}
					isFinished={!!store.fetchModelShelf.model.value?.finishedAt}
					openModal={()=> setModalMedicationIsOpen(true)}
					medicationAdministred={store.administeredMedicationShelf.paginatedListShelfMedicationByHosṕitalization.items}
					previousPage={store.administeredMedicationShelf.paginatedListShelfMedicationByHosṕitalization.previousPage}
					nextPage={store.administeredMedicationShelf.paginatedListShelfMedicationByHosṕitalization.nextPage}
					page={store.administeredMedicationShelf.paginatedListShelfMedicationByHosṕitalization.page}
					isLoading={store.administeredMedicationShelf.paginatedListShelfMedicationByHosṕitalization.loader.isLoading}
					hasNextPage={store.administeredMedicationShelf.paginatedListShelfMedicationByHosṕitalization.hasNextPage}
				/>
				<AdministeredMedicationFormModal
					administeredMedicationShelf={store.administeredMedicationShelf}
					isOpen={modalMedicationIsOpen}
					onCreate={()=> store.administeredMedicationShelf.createAdministeredMedication(onSuccess)}
					onClose={() => setModalMedicationIsOpen(!modalMedicationIsOpen)}
				/>
				{authStore.currentAdminUser?.clinic && !store.fetchModelShelf.model.value?.finishedAt && (
					<Button
						onClick={() => openDialog()}
						w={{
							base: "50%",
							md: "15%",
						}}
						mb={5}
						my={8}
						borderRadius="5px 0px 5px 5px"
					>
						{strings.actions.finishHospitalization}
					</Button>
				)}
				<ButtonBack />
			</>
		</Flex>
	);
};

export default observer(Details);
