import TableView from "./TableView";
import Create from "./Create";
import Details from "./Details";
import DetailsAdministredMedication from "./DetailsAdministredMedication";
import DetailsExam from "./DetailsExam";

export const Hospitalization = {
	TableView,
	Create,
	Details,
	DetailsAdministredMedication,
	DetailsExam,
};

