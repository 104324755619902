import React from "react";
import { observer } from "mobx-react-lite";
import { Routes, Route } from "react-router-dom";
import { MainLayout } from "~/layout";
import {
	Pets,
	Users,
	Posts,
	Vets,
	PendingPosts,
	HomePage,
} from "~/pages";
import { useAuthAdminMasterRoute } from "~/hooks/useAuthAdminMasterRoute";
import { AdminUsers } from "~/pages/AdminsUsers";
import { Clinics } from "~/pages/Clinics";
import { Hospitalization } from "~/pages/Hospitalization";
import { Consultations } from "~/pages/Consultations";
import { PendingDeleteAccounts } from "~/pages/PendingDeleteAccounts";

const AdminRoutes = observer(() => {
	useAuthAdminMasterRoute();

	return (
		<MainLayout>
			<Routes>
				<Route
					path="/"
					element={<HomePage />}
				/>
				<Route path="adminUsers">
					<Route
						index
						element={<AdminUsers.TableView />}
					/>
					<Route
						path="details/:id"
						element={<AdminUsers.Details />}
					/>
					<Route
						path="create"
						element={<AdminUsers.CreateOrEdit />}
					/>
					<Route
						path="edit/:id"
						element={<AdminUsers.CreateOrEdit />}
					/>
				</Route>
				<Route path="users">
					<Route
						index
						element={<Users.TableView />}
					/>
					<Route
						path="details/:id"
						element={<Users.Details />}
					/>
					<Route
						path="create/:id"
						element={<Users.Create />}
					/>
				</Route>
				<Route path="clinics">
					<Route
						index
						element={<Clinics.TableView />}
					/>
					<Route
						path="create"
						element={<Clinics.CreateOrEdit />}
					/>
					<Route
						path="edit/:id"
						element={<Clinics.CreateOrEdit />}
					/>
					<Route
						path="details/:id"
						element={<Clinics.Details />}
					/>
				</Route>
				<Route path="pets">
					<Route
						index
						element={<Pets.TableView />}
					/>
					<Route
						path="edit/:id"
						element={<Pets.Edit />}
					/>
					<Route
						path="details/:id"
						element={<Pets.Details />}
					/>
					<Route
						path="details/exam/:id"
						element={<Consultations.DetailsExam />}
					/>
				</Route>
				<Route path="hospitalization">
					<Route
						index
						element={<Hospitalization.TableView />}
					/>
					<Route
						path="details/:id"
						element={<Hospitalization.Details />}
					/>
					<Route
						path="details/exam/:id"
						element={<Hospitalization.DetailsExam />}
					/>
					<Route
						path="details/detailsAdministredMedication/:id"
						element={<Hospitalization.DetailsAdministredMedication />}
					/>
				</Route>
				<Route path="posts">
					<Route
						index
						element={<Posts.TableView />}
					/>
					<Route
						path="create"
						element={<Posts.CreateOrEdit />}
					/>
					<Route
						path="edit/:id"
						element={<Posts.CreateOrEdit />}
					/>
				</Route>
				<Route path="consultations">
					<Route
						index
						element={<Consultations.TableView />}
					/>
					<Route
						path="details/:id"
						element={<Consultations.Details />}
					/>
					<Route
						path="details/exam/:id"
						element={<Consultations.DetailsExam />}
					/>
					<Route
						path="details/detailsAdministredMedication/:id"
						element={<Consultations.DetailsAdministredMedication />}
					/>
					<Route
						path="details/vaccines/:id"
						element={<Consultations.DetailsVaccine />}
					/>
				</Route>
				<Route path="vets">
					<Route
						index
						element={<Vets.TableView />}
					/>
				</Route>
				<Route path="pendingDeleteAccounts">
					<Route
						index
						element={<PendingDeleteAccounts.TableView />}
					/>
					<Route
						path="details/:id"
						element={<PendingDeleteAccounts.Details />}
					/>
				</Route>
				<Route path="pendingPosts">
					<Route
						index
						element={<PendingPosts.TableView />}
					/>
					<Route
						path="details/:id"
						element={<PendingPosts.Details />}
					/>
				</Route>
			</Routes>
		</MainLayout>
	);
});

export default AdminRoutes;
