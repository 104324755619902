import React from "react";
import {
	Flex,
	StackProps,
} from "@chakra-ui/layout";
import { Button, Grid } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import strings from "~/resources/strings";
import { DatePicker, Label } from "~/components";

interface IProps extends StackProps {
	selectedStartDate: Date | null;
	selectedEndDate: Date | null;
	onChangeStartDate: (date: Date | null) => void;
	onChangeEndDate: (date: Date | null) => void;
	refreshItems: () => void;
}

export const FilterPeriod: React.FC<IProps> = observer((props) => {
	const {
		selectedStartDate,
		onChangeStartDate,
		selectedEndDate,
		onChangeEndDate,
		refreshItems,
	} = props;

	// Filter Controller
	const setInitialDate = (date: Date | null) => {
		onChangeStartDate(date);
		refreshItems();
	};
	const setEndDate = (date: Date | null) => {
		onChangeEndDate(date);
		refreshItems();
	};
	const clearFilter = () => {
		onChangeStartDate(null);
		onChangeEndDate(null);
		refreshItems();
	};

	return (
		<>
			<Flex
				my={2}
				alignItems={{
					base:"center",
					md:"left",
				}}
				justifyContent={{
					base:"center",
					md:"left",
				}}
				w="100%"
			>
				<Label color="primary.500" fontWeight="bold">
					{strings.components.checkinPeriodFilter.filterDate}
				</Label>
			</Flex>
			<Grid
				w={{
					base:"100%",
					sm:"80%",
					md:"100%",
				}}
				mx="auto"
				bg="white"
				p={4}
				rounded="xl"
				gridTemplateColumns={{
					md:"repeat(3,1fr)",
				}}
				justifyContent="center"
				gap={7}
			>
				<DatePicker
					label={strings.components.checkinPeriodFilter.from}
					onChangeDate={setInitialDate}
					selectedDate={selectedStartDate}
					inputProps={{ h: "40px", textAlign: "center" }}
					color="primary.500"
				/>
				<DatePicker
					label={strings.components.checkinPeriodFilter.until}
					onChangeDate={setEndDate}
					selectedDate={selectedEndDate}
					inputProps={{ h: "40px", textAlign: "center" }}
					color="primary.500"
				/>
				<Button
					onClick={clearFilter}
					mt={7}
					w={{
						base: "95%",
						md: "100%",
						xl:"45%",
					}}
				>
					{strings.components.checkinPeriodFilter.clearDate}
				</Button>
			</Grid>
		</>

	);
},
);
