import React from "react";
import { useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Center } from "@chakra-ui/react";

import { Store } from "./store";
import {
	CentralizedCard,
	DetailsRow,
	ApproveOrRefuseButton,
	ImageWithModal,
	ButtonBack,
} from "~/components";
import strings from "~/resources/strings";

const Details = () => {
	const commonStrings = strings.common;
	const { id } = useParams<"id">();
	const store = useLocalObservable(() => new Store(id || ""));

	return (
		<Center flexDirection="column">
			<>
				<CentralizedCard
					title={{
						text: commonStrings.detailsTitle,
					}}
					isLoading={store.FetchModelShelf.initialLoader.isLoading}
				>
					{
						store.FetchModelShelf.model.value &&
							<>
								<ImageWithModal src={store.FetchModelShelf.model.value.user.image?.url} />
								<DetailsRow
									label={commonStrings.fields.name}
									value={store.FetchModelShelf.model.value.user.name}
								/>
								<DetailsRow
									label={commonStrings.fields.email}
									value={store.FetchModelShelf.model.value.user.email}
								/>
								<DetailsRow
									label={commonStrings.fields.cpf}
									value={store.FetchModelShelf.model.value.user.documentNumber}
								/>
								<DetailsRow
									label={commonStrings.fields.phone}
									value={store.FetchModelShelf.model.value.user.phone}
								/>
								<ApproveOrRefuseButton
									user={store.FetchModelShelf.model.value.user.name}
									approve={store.acceptDeleteUser}
									refuse={store.refuseDeleteUser}
									id={store.FetchModelShelf.fetchedModel.id}
								/>
							</>
					}
				</CentralizedCard>
				<ButtonBack />
			</>
		</Center>
	);
};

export default observer(Details);
