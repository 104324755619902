import { makeAutoObservable } from "mobx";
import {
	AttributeShelf,
	LoaderShelf,
	PaginatedListShelf,
} from "@startapp/mobx-utils";
import { FormShelf, ImagePickerShelf } from "@startapp/mobx-utils/src/web";
import api from "~/resources/api";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import { onFetchError } from "~/resources/fetchError";
import FilePickerShelf from "../FilePickerShelf";

export class AdministeredMedicationShelf {
	public loader = new LoaderShelf();
	public imageShelf = new ImagePickerShelf(api.uploadImage);
	public pdf = new FilePickerShelf(api.uploadUncertainFile) || null;
	public id = new AttributeShelf<string>("");
	public startConsumptionDate = new AttributeShelf<Date>(new Date());
	public endConsumptionDate = new AttributeShelf<Date>(new Date());
	public selectedHour = new AttributeShelf<Date>(new Date());
	public consumptionHours = new AttributeShelf<Date[]>([]);
	public type = new AttributeShelf<string>("");
	public paginatedListShelfMedicationByConsultation: PaginatedListShelf<api.AdministeredMedication>;
	public paginatedListShelfMedicationByPet: PaginatedListShelf<api.AdministeredMedication>;
	public paginatedListShelfMedicationByHosṕitalization: PaginatedListShelf<api.AdministeredMedication>;

	public formShelf = new FormShelf({
		name: "",
		dosage: "",
		code: "",
	});

	constructor(id: string, type: "consultation" | "pet" | "hospitalization") {
		makeAutoObservable(this);
		if (id) {
			this.id.setValue(id);
			this.type.setValue(type);

			if (type === "consultation") {
				this.paginatedListShelfMedicationByConsultation = new PaginatedListShelf(
					(page) => api.getAllAdministeredMedicationsByConsultation(page, id),
					{
						fetchOnConstructor: true,
						onFetchError,
					},
				);
			}

			if (type === "pet") {
				this.paginatedListShelfMedicationByPet = new PaginatedListShelf(
					(page) => api.getAllAdministeredMedicationsByPet(page, id),
					{
						fetchOnConstructor: true,
						onFetchError,
					},
				);
			}

			if (type === "hospitalization") {
				this.paginatedListShelfMedicationByHosṕitalization = new PaginatedListShelf(
					(page) => api.getAllAdministeredMedicationsByHospitalization(page, id),
					{
						fetchOnConstructor: true,
						onFetchError,
					},
				);
			}
		}
	}

	public medicationData = (): api.NewAdministeredMedication => {
		const data = this.formShelf.getValues();
		return {
			name: data.name,
			code: Number(data.code),
			dosage: data.dosage,
			image: this.imageShelf.uploadedImage || null,
			document: this.pdf.uncertainfiedFile?.url || null,
			startConsumptionDate: this.startConsumptionDate.value,
			endConsumptionDate: this.endConsumptionDate.value,
			consumptionHours: this.consumptionHours.value,
		};
	};
	public clearMedicationFields = () => {
		const data = this.formShelf.getValues();
		this.startConsumptionDate.value = new Date();
		this.endConsumptionDate.value  = new Date();
		this.imageShelf.getPickerFields().setUploadedImage(null);
		this.consumptionHours.setValue([]);
		return {
			name: data.name = "",
			code: data.code = "",
			dosage: data.dosage = "",
		};
	};

	public createAdministeredMedication = async (onSuccess: () => void) => {
		this.loader.start();
		try {
			if (this.type.value === "consultation")  {
				await api.createAdministeredMedicationByConsultation(
					this.medicationData(),
					this.id.value,
				);
				this.paginatedListShelfMedicationByConsultation.refresh();
			}
			if (this.type.value === "hospitalization")  {
				await api.createAdministeredMedicationByHospitalization(
					this.medicationData(),
					this.id.value,
				);
				this.paginatedListShelfMedicationByHosṕitalization.refresh();
			}
			showSuccessToast(strings.pages.consultations.createAdministeredMedication.onSuccess);
			this.clearMedicationFields();
			onSuccess();
		} catch (e) {
			const error = Errors.treatError(e);
			if (error.type === api.ErrorType.Validation) {
				this.formShelf.fieldError.cleanAndAddErrors(JSON.parse((e as Error).message));
			} else {
				const errorMessage = Errors.handleError(e);
				showErrorToast(errorMessage);
			}
		} finally {
			this.loader.end();
		}
	};
}

