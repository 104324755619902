import React from "react";
import { observer } from "mobx-react-lite";
import {
	BoxProps,
	Flex,
	Spinner,
	Text,
	useMediaQuery,
} from "@chakra-ui/react";

export interface ICardProps extends BoxProps {
	title: string;
	value: number | undefined;
	isLoading: boolean;
}

export const ResultCard: React.FC<ICardProps> = observer((props) => {
	const {
		value,
		title,
		isLoading,
		...rest
	} = props;

	const [isSm] = useMediaQuery("(min-width: 600px)");

	return (
		<Flex
			w={isSm ? 253 : "unset"}
			h={isSm ? 178 : "unset"}
			direction="column"
			bg="white"
			boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
			rounded="lg"
			p={5}
			{...rest}
		>
			<Text
				size="sm"
				fontWeight="bold"
				color="teal.800"
			>
				{title}
			</Text>
			<Text
				color="primary.600"
				fontSize="7xl"
				textAlign="center"
			>
				{ isLoading
					? <Spinner size="xl" />
					: value }
			</Text>
		</Flex>
	);
});
