import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { CentralizedCard, CreateOrEditConsult } from "~/components";
import strings from "~/resources/strings";
import Store from "./store";
import { useHistory } from "~/hooks/useHistory";
import { useGlobalStore } from "~/contexts/useGlobalContext";

const CreateOrEdit: React.FC = () => {
	const history = useHistory();
	const { authStore } = useGlobalStore();
	const { id } = useParams();
	const store = useLocalObservable(() => new Store(id));
	const pageStrings = strings.pages.consultations;
	const commonStrings = strings.common;
	const onSucess = () => {
		history.goBack();
	};

	React.useEffect(()=> {
		const fetchData = async () => {
			await authStore.getCurrentAdminUser();
			store.clinicId.setValue(authStore.currentAdminUser?.clinic?.id || "");
			await store.paginetedListShelfToPet.fetchPage(0);
		};
		fetchData();
	},[]);

	const onAdd = () =>{
		store.createConsult(id || "", onSucess);
	};

	return (
		<CentralizedCard
			title={{ text: pageStrings.createOrEdit.title(!!id)}}
			altMaxWidth
			button={(
				<Button
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={() => onAdd()}
				>
					{commonStrings.buttons.add}
				</Button>
			)}
		>
			<CreateOrEditConsult
				date={store.date}
				hour={store.hour}
				isLoading={store.loader.isLoading}
				dataVet={{
					id: store.vetId,
					paginatedListShelf: store.paginetedListShelfToVet,
					searchProps: store.vetSearched,
				}}
				dataPet={{
					id: store.petId,
					paginatedListShelf: store.paginetedListShelfToPet,
					searchProps: store.petSearched,
				}}
				formValues={{
					field: store.formShelf.field,
				}}
				addMedicationTofluidtherapy={store.addMedicationTofluidtherapy}
				consultationShelf={store.consultationShelf}
			/>
		</CentralizedCard>
	);
};

export default observer(CreateOrEdit);
