import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Flex,
	Td,
	Tr,
} from "@chakra-ui/react";
import api from "~/resources/api";
import Store  from "./store";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import { Table, TableCellWithActionButtons } from "~/components";
import strings from "~/resources/strings";
import { useHistory } from "~/hooks/useHistory";
import format from "~/resources/format";

const TableView: React.FC = () => {
	const pageStrings = strings.pages.clinic.table;
	const modal = strings.common.modal;
	const store = useLocalObservable(() => new Store());
	const { dialog } = useGlobalStore();
	const history = useHistory();
	const onGoToCreateClinic = () => history.push("create");
	const onGoToEditClinic = (id: string) => history.push(`edit/${id}`);

	const onDeleteClinics = (id: string) => {
		store.deleteClinic(id);
		dialog.closeDialog();
	};

	const openDialog = (clinic: api.AdminUser) => {
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(clinic.name),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => onDeleteClinics(clinic.id),
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	const onGoToDetails = (idClinic: string) => history.push(`details/${idClinic}`);

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				onAdd={onGoToCreateClinic}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				renderRow={(item, index) => (
					<Tr key={index}>
						<Td whiteSpace="nowrap">{item.name}</Td>
						<Td>{item.clinic?.codeFormat}</Td>
						<Td>{item.email}</Td>
						<Td whiteSpace="nowrap">{format.phone(item.clinic?.phone)}</Td>
						<Td whiteSpace="nowrap">{item.documentNumber}</Td>
						<TableCellWithActionButtons
							onEdit={() => onGoToEditClinic(item.id)}
							onDelete={() => openDialog(item)}
							onView={() => onGoToDetails(item.id)}
						/>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
				addButtonText={pageStrings.onAdd}
				headerColor="gray.800"
			/>
		</Flex>
	);
};

export default observer(TableView);
