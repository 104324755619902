import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Flex,
	Tr,
	Td,
	Text,
	Avatar,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import { SearchInput, Table, TableCellWithActionButtons } from "~/components";
import { useHistory } from "~/hooks/useHistory";
import Store from "./store";
import api from "~/resources/api";
import format from "~/resources/format";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import ImagePlaceHolder from "../../../../static/pick_image.svg";

const TableView: React.FC = () => {
	const store = useLocalObservable(() => new Store());
	const pageStrings = strings.pages.users.table;
	const history = useHistory();

	const { dialog } = useGlobalStore();

	const onGoToDetails = (id: string) => history.push(`details/${id}`);

	const openDialog = (user: api.User) => {
		const modal = strings.common.modal;
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(user.name),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => {
						store.deleteUser(user.id);
						dialog.closeDialog();
					},
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				addButtonText={pageStrings.addButtonText}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				headerFilter={
					<SearchInput
						value={store.cpf.value}
						onChange={store.cpf.setValue}
						onClickSearch={() => store.paginetedListShelf.fetchPage(0)}
						placeholder={pageStrings.placeholder}
						select={{
							onSelect: store.cpf.setValue,
							optionsValues: pageStrings.options,
							selectedValue: store.cpf.value,
						}}
					/>
				}
				renderRow={(item, index) => (
					<Tr key={index}>
						<Td>
							<Flex alignItems="center">
								<Avatar
									w={35}
									h={35}
									src={item.image ? item.image.url : ImagePlaceHolder}
									marginRight={7}
								/>
								<Text >{item.name}</Text>
							</Flex>
						</Td>
						<Td>{item.email}</Td>
						<Td>{format.phone(item.phone)}</Td>
						<Td>{item.documentNumber ? item.documentNumber : strings.common.noRegistered}</Td>
						<TableCellWithActionButtons
							onDelete={() => openDialog(item)}
							onView={() => onGoToDetails(item.id)}
						/>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
