import React from "react";
import { Heading } from "@chakra-ui/react";
import {
	DetailsRow,
	ClinicAddressDetails,
	ImageWithModal,
} from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";

interface IProps {
	hospitalization: api.Hospitalization;
}

export const DetailsClinicHospitalization: React.FC<IProps> = (props) => {
	const { hospitalization } = props;
	const commonStrings = strings.common;

	return (
		<>
			<Heading
				mt={1}
				fontSize="md"
				color="green.500"
			>
				{strings.common.fields.clinic}
			</Heading>
			<ImageWithModal src={hospitalization.clinic?.image.url} />
			<DetailsRow
				label={commonStrings.fields.clinicName}
				value={hospitalization.clinic?.adminUser?.name || ""}
			/>
			<DetailsRow
				label={commonStrings.fields.documentNumber}
				value={hospitalization.clinic?.adminUser?.documentNumber || ""}
			/>
			<ClinicAddressDetails address={hospitalization.clinic?.address} />
		</>
	);
};
