import React from "react";
import { useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	IconButton,
	Tooltip,
	Flex,
	Box,
	Grid,
} from "@chakra-ui/react";

import { Store } from "./store";
import {
	CentralizedCard,
	DetailsRow,
	Label,
	ImageWithModal,
	ButtonBack,
} from "~/components";
import strings from "~/resources/strings";
import { DocumentLink } from "~/components/DocumentLink";

const DetailsAdministredMedication: React.FC = () => {
	const commonStrings = strings.common;
	const { id } = useParams<"id">();
	const store = useLocalObservable(() => new Store(id || ""));

	return (
		<Flex
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			w="100%"
		>
			<CentralizedCard
				title={{
					text: commonStrings.detailsTitle,
					helper: (
						<Tooltip label={strings.common.edit}>
							<IconButton
								variant="icon"
								aria-label={strings.actions.back}
								size="lg"
							/>
						</Tooltip>
					),
				}}
				isLoading={store.fetchModelShelf.initialLoader.isLoading}
				altMaxWidth
			>
				{
					store.fetchModelShelf.model.value &&
						<>
							<Flex
								w="100%"
								direction="column"
								gap={4}
							>
								<DetailsRow
									label={commonStrings.fields.medicationName}
									value={store.fetchModelShelf.fetchedModel.name}
									isOutline
								/>
								<Grid
									gap={4}
									gridTemplateColumns={{
										md:"repeat(2,1fr)",
									}}
								>
									<DetailsRow
										label={commonStrings.fields.code}
										value={store.fetchModelShelf.fetchedModel.code}
										isOutline
									/>
									<DetailsRow
										label={commonStrings.fields.period}
										value={`${strings.format.dateCalculator(store.fetchModelShelf.fetchedModel.startConsumptionDate, store.fetchModelShelf.fetchedModel.endConsumptionDate)} ${strings.common.fields.day}`}
										isOutline
									/>
									<DetailsRow
										label={commonStrings.fields.startConsumptionDate}
										value={strings.format.date(store.fetchModelShelf.fetchedModel.startConsumptionDate)}
										isOutline
									/>
									<DetailsRow
										label={commonStrings.fields.endConsumptionDate}
										value={strings.format.date(store.fetchModelShelf.fetchedModel.endConsumptionDate)}
										isOutline
									/>
									<DetailsRow
										label={commonStrings.fields.dosage}
										value={store.fetchModelShelf.fetchedModel.dosage}
										isOutline
									/>
								</Grid>
								<Flex direction="column">
									<Label
										fontSize="md"
										color="gray.900"
										fontWeight="bold"
									>
										{strings.common.fields.hourSelecteds}
									</Label>
									<Grid gap={2} gridTemplateColumns="repeat(2,1fr)">
										{store.fetchModelShelf.fetchedModel.consumptionHours.map((item, index) => (
											<DetailsRow
												key={index}
												value={strings.format.hour(item)}
												isOutline
											/>
										))}
									</Grid>
								</Flex>
								<Box>
									<ImageWithModal label={commonStrings.fields.photo} src={store.fetchModelShelf.fetchedModel.image?.url} />
								</Box>
								<DocumentLink pdf={store.fetchModelShelf.model.value.document} />
							</Flex>
						</>
				}
			</CentralizedCard>
			<ButtonBack />
		</Flex>
	);
};

export default observer(DetailsAdministredMedication);
