import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { Grid, useDisclosure } from "@chakra-ui/react";
import { AttributeShelf, PaginatedListShelf } from "@startapp/mobx-utils";

import {
	PetAutoCompleteModalCard,
	VetAutoCompleteModalCard,
	DatePicker,
	TextArea,
	TimePicker,
	AnamnseForm,
} from "~/components";
import strings from "~/resources/strings";
import { useDebounce } from "~/hooks/useDebounce";
import api from "~/resources/api";
import { SectionAutoCompleteModal } from "../SectionAutoCompleteModal";
import { ConsultationShelf } from "~/shelves/ConsultationShelf";

type FieldType = "description";

interface FormValues {
	field: (field: FieldType) => {
		name: FieldType;
		onChange: (element: {
			target: {
				value: string;
			};
		}) => void;
		value: string;
		error: string | null;
	};
}

interface IProps {
	isLoading: boolean;
	dataVet: {
		paginatedListShelf: PaginatedListShelf<api.Vet>;
		id: AttributeShelf<string>;
		searchProps: AttributeShelf<string>;
	};
	dataPet: {
		paginatedListShelf: PaginatedListShelf<api.Pet>;
		id: AttributeShelf<string>;
		searchProps: AttributeShelf<string>;
	};
	date: AttributeShelf<Date>;
	hour: AttributeShelf<Date>;
	formValues: FormValues;
	addMedicationTofluidtherapy: () => void;
	consultationShelf: ConsultationShelf;
}

export const CreateOrEditConsult: React.FC<IProps> = observer((props) => {

	const { id } = useParams();
	const {
		isLoading,
		dataVet,
		dataPet,
		date,
		hour,
		formValues,
		addMedicationTofluidtherapy,
		consultationShelf,
	} = props;
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const { isOpen, onClose, onOpen } = useDisclosure();
	const commonStrings = strings.common;
	const debounce = useDebounce();

	// PET Modal functions***
	const setValuesModalPet = (item: api.Pet) =>{
		dataPet.id.setValue(item.id);
		dataPet.searchProps.setValue(item.name);
		setModalIsOpen(false);
	};
	const openModalPet = () => {
		dataPet.searchProps.setValue("");
		setModalIsOpen(true);
	};
	const petFiltered = (items: api.Pet[]) => items.filter((pet)=> dataPet.searchProps.value === pet.name);

	const onKeyUpPet = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const input = e.target as HTMLInputElement;
		debounce.clearTimer();
		debounce.setTimer(setTimeout(() => dataPet.searchProps.setValue(input.value), 500));
	};

	// VET Modal functions***
	const setValuesModalVet = (item: api.Vet) =>{
		dataVet.id.setValue(item.id);
		dataVet.searchProps.setValue(item.name);
		onClose();
	};
	const openModalVet = () => {
		dataVet.searchProps.setValue("");
		onOpen();
	};
	const vetFiltered = (items: api.Vet[]) => items.filter((vet)=> dataVet.searchProps.value === vet.name);

	const onKeyUpVet = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const input = e.target as HTMLInputElement;
		debounce.clearTimer();
		debounce.setTimer(setTimeout(() => dataVet.searchProps.setValue(input.value), 500));
	};

	return (
		<>
			{!id && (
				<>
					<SectionAutoCompleteModal
						isOpen={isOpen}
						onClose={onClose}
						methodForFiltered={vetFiltered}
						onKeyUp={onKeyUpVet}
						openModal={openModalVet}
						paginatedListShelf={dataVet.paginatedListShelf}
						renderItem={(item) => (
							<VetAutoCompleteModalCard
								vet={item as api.Vet}
								onClick={() => setValuesModalVet(item as api.Vet)}
							/>
						)}
						searchProps={{
							isDisabled: isLoading,
							label: commonStrings.fields.vet,
							value: dataVet.searchProps.value,
						}}
					/>
					<SectionAutoCompleteModal
						isOpen={modalIsOpen}
						onClose={onClose}
						methodForFiltered={petFiltered}
						onKeyUp={onKeyUpPet}
						openModal={openModalPet}
						paginatedListShelf={dataPet.paginatedListShelf}
						renderItem={(item) => (
							<PetAutoCompleteModalCard
								pet={item as api.Pet}
								onClick={() => setValuesModalPet(item as api.Pet)}
							/>
						)}
						searchProps={{
							isDisabled: isLoading,
							label: commonStrings.fields.pet,
							value: dataPet.searchProps.value,
						}}
					/>
				</>
			)}
			<Grid
				gap={5}
				gridTemplateColumns={{
					base: "1fr",
					sm: "1fr 1fr",
				}}
			>
				<DatePicker
					label={commonStrings.fields.dateConsult}
					onChangeDate={date.setValue}
					selectedDate={date.value}
					inputProps={{ h: "40px", textAlign: "center" }}
					color="primary.500"
				/>
				<TimePicker
					label={commonStrings.fields.hour}
					onChangeDate={hour.setValue}
					selectedDate={hour.value}
					inputProps={{ h: "40px", textAlign: "center" }}
					w="100%"
				/>
			</Grid>
			<TextArea
				labelText={commonStrings.fields.description}
				labelProps={{ fontWeight: "bold" }}
				labelColor="primary"
				isDisabled={isLoading}
				errorText={formValues.field("description").error}
				{...formValues.field("description")}
			/>
			<AnamnseForm
				addMedicationTofluidtherapy={addMedicationTofluidtherapy}
				consultationShelf={consultationShelf}
			/>
		</>
	);
});
