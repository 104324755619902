import React from "react";
import { observer } from "mobx-react-lite";
import {
	Button,
	Flex,
	Grid,
	Heading,
	StackProps,
} from "@chakra-ui/react";
import {
	SelectedMedicationsFluidTherapyse,
	TextArea,
	TextInput,
} from "~/components";
import strings from "~/resources/strings";
import { ConsultationShelf } from "~/shelves/ConsultationShelf";
import { FluidtherapyForm } from "../FluidtherapyForm";

interface IProps extends StackProps {
	consultationShelf: ConsultationShelf;
	addMedicationTofluidtherapy: () => void;
}

export const AnamnseForm: React.FC<IProps> = observer((props) => {
	const { consultationShelf, addMedicationTofluidtherapy } = props;
	const commonStrings = strings.common;

	const onRemove = (id: number) =>{
		consultationShelf.fluidTherapyse.value.splice(id, 1);
	};

	const checkFilledFields = () =>
		!(!!consultationShelf.formShelfAnamnese.field("solution").value
    || !!consultationShelf.formShelfAnamnese.field("fluidtherapyRate").value
	|| !!consultationShelf.formShelfAnamnese.field("velocity").value);


	return (
		<Flex direction="column" gap={5}>
			<Heading fontSize="md" color="primary.500">{strings.common.fields.physicalExam}</Heading>
			<Grid
				gridTemplateColumns={{
					base: "1fr",
					md: "repeat(2,1fr)",
				}}
				gap={6}
				mb={4}
			>
				<TextInput
					labelText={commonStrings.fields.heartRate}
					labelProps={{ fontWeight: "bold", fontSize: 14 }}
					variant="filled"
					type="text"
					isDisabled={consultationShelf.loader.isLoading}
					errorText={consultationShelf.formShelfAnamnese.field("heartRate").error}
					{...consultationShelf.formShelfAnamnese.field("heartRate")}
				/>
				<TextInput
					labelText={commonStrings.fields.respiratoryFrequency}
					labelProps={{ fontWeight: "bold", fontSize: 14 }}
					variant="filled"
					type="text"
					isDisabled={consultationShelf.loader.isLoading}
					errorText={consultationShelf.formShelfAnamnese.field("respiratoryFrequency").error}
					{...consultationShelf.formShelfAnamnese.field("respiratoryFrequency")}
				/>
				<TextInput
					labelText={commonStrings.fields.tpc}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					type="text"
					isDisabled={consultationShelf.loader.isLoading}
					errorText={consultationShelf.formShelfAnamnese.field("tpc").error}
					{...consultationShelf.formShelfAnamnese.field("tpc")}
				/>
				<TextInput
					labelText={commonStrings.fields.mucous}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					type="text"
					isDisabled={consultationShelf.loader.isLoading}
					errorText={consultationShelf.formShelfAnamnese.field("mucous").error}
					{...consultationShelf.formShelfAnamnese.field("mucous")}
				/>
				<TextInput
					labelText={commonStrings.fields.pulse}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					type="text"
					isDisabled={consultationShelf.loader.isLoading}
					errorText={consultationShelf.formShelfAnamnese.field("pulse").error}
					{...consultationShelf.formShelfAnamnese.field("pulse")}
				/>
				<TextInput
					labelText={commonStrings.fields.rectalTemperature}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					type="text"
					isDisabled={consultationShelf.loader.isLoading}
					errorText={consultationShelf.formShelfAnamnese.field("rectalTemperature").error}
					{...consultationShelf.formShelfAnamnese.field("rectalTemperature")}
				/>
				<TextInput
					labelText={commonStrings.fields.cardiopulmonaryAuscultation}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					type="text"
					isDisabled={consultationShelf.loader.isLoading}
					errorText={consultationShelf.formShelfAnamnese.field("cardiopulmonaryAuscultation").error}
					{...consultationShelf.formShelfAnamnese.field("cardiopulmonaryAuscultation")}
				/>
				<TextInput
					labelText={commonStrings.fields.abdominalPalpation}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					type="text"
					isDisabled={consultationShelf.loader.isLoading}
					errorText={consultationShelf.formShelfAnamnese.field("abdominalPalpation").error}
					{...consultationShelf.formShelfAnamnese.field("abdominalPalpation")}
				/>
				<TextInput
					labelText={commonStrings.fields.bodyScore}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					type="text"
					isDisabled={consultationShelf.loader.isLoading}
					errorText={consultationShelf.formShelfAnamnese.field("bodyScore").error}
					{...consultationShelf.formShelfAnamnese.field("bodyScore")}
				/>
				<TextInput
					labelText={commonStrings.fields.otherSystems}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					type="text"
					isDisabled={consultationShelf.loader.isLoading}
					errorText={consultationShelf.formShelfAnamnese.field("otherSystems").error}
					{...consultationShelf.formShelfAnamnese.field("otherSystems")}
				/>
				<TextInput
					labelText={commonStrings.fields.complementaryExams}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					type="text"
					isDisabled={consultationShelf.loader.isLoading}
					errorText={consultationShelf.formShelfAnamnese.field("complementaryExams").error}
					{...consultationShelf.formShelfAnamnese.field("complementaryExams")}
				/>
				<TextInput
					labelText={commonStrings.fields.suspicions}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					type="text"
					isDisabled={consultationShelf.loader.isLoading}
					errorText={consultationShelf.formShelfAnamnese.field("suspicions").error}
					{...consultationShelf.formShelfAnamnese.field("suspicions")}
				/>
				<TextInput
					labelText={commonStrings.fields.diagnosis}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					type="text"
					isDisabled={consultationShelf.loader.isLoading}
					errorText={consultationShelf.formShelfAnamnese.field("diagnosis").error}
					{...consultationShelf.formShelfAnamnese.field("diagnosis")}
				/>
				<TextInput
					labelText={commonStrings.fields.treatment}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					type="text"
					isDisabled={consultationShelf.loader.isLoading}
					errorText={consultationShelf.formShelfAnamnese.field("treatment").error}
					{...consultationShelf.formShelfAnamnese.field("treatment")}
				/>
			</Grid>
			<FluidtherapyForm consultationShelf={consultationShelf} />
			<Flex direction="column" w="100%">
				<Button
					maxW={{
						base:"100%",
						md:"70%",
						xl:"49%",
					}}
					mx={0}
					fontSize={{
						base: 10,
						sm: 15,
						md: "unset",
					}}
					mb={4}
					isDisabled={checkFilledFields()}
					onClick={addMedicationTofluidtherapy}
				>
					{strings.actions.addFluidTherapy}
				</Button>
				<SelectedMedicationsFluidTherapyse
					onRemove={onRemove}
					fluidTherapyse={consultationShelf.fluidTherapyse.value}
				/>
				<Heading
					mt={4}
					mb={2}
					fontSize="md"
					color="primary.500"
				>
					{strings.common.fields.indicatedDiet}
				</Heading>
				<TextArea
					labelText={commonStrings.fields.description}
					labelProps={{ fontWeight: "bold", color: "gray.900" }}
					labelColor="primary"
					isDisabled={consultationShelf.loader.isLoading}
					errorText={consultationShelf.formShelfAnamnese.field("description").error}
					{...consultationShelf.formShelfAnamnese.field("description")}
				/>
			</Flex>
		</Flex>
	);
});

