import { makeAutoObservable } from "mobx";
import { ImagePickerShelf } from "@startapp/mobx-utils/src/web";
import {
	AttributeShelf,
	LoaderShelf,
	PaginatedListShelf,
} from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { onFetchError } from "~/resources/fetchError";
const pageStrings = strings.pages.hospitalization;

export default class Store {

	public startDate = new AttributeShelf<Date>(new Date());
	public entryTime = new AttributeShelf<Date>(new Date());
	public endDate = new AttributeShelf<Date | null>(null);
	public exitTime = new AttributeShelf<Date | null>(null);
	public paginetedListShelfToPet: PaginatedListShelf<api.Pet>;
	public loader = new LoaderShelf();
	public id = new AttributeShelf("");
	public name = new AttributeShelf("");
	public petId = new AttributeShelf<string>("");
	public petSearched = new AttributeShelf<string>("");
	public imageShelf = new ImagePickerShelf(api.uploadImage);
	public imageList = new AttributeShelf<api.Image[]>([]);
	public clinicId = new AttributeShelf<string>("");

	constructor(isClinic?: boolean) {
		makeAutoObservable(this);
		if (isClinic) {
			this.paginetedListShelfToPet = new PaginatedListShelf(
				() => api.getAllPetsForClinic(this.clinicId.value),
				{
					fetchOnConstructor: false,
					onFetchError,
				},
			);
		}

	}
	public createHospitalization = async (id: string, onSuccess: () => void) => {
		this.loader.start();
		try {
			await api.createHospitalizationForAdminClinic({
				petId: this.petId.value,
				startDate: this.startDate.value,
				endDate: this.endDate.value || null,
				entryTime: this.entryTime.value,
				exitTime: this.exitTime.value || null,
				images: this.imageList.value[0] !== null ? this.imageList.value : null,
			});
			showSuccessToast(pageStrings.create.success);
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

}

