import { makeAutoObservable } from "mobx";
import { LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import { onFetchError } from "~/resources/fetchError";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";

export class Store {

	public paginetedListShelf: PaginatedListShelf<api.UserDeleteRequest>;
	public loader = new LoaderShelf();

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			(page) => api.getAllPendingUserDeleteRequests(page),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		) ;
	}

	public approveOrRefuseDelete = async(id: string, isApprove: boolean) => {
		this.loader.tryStart();
		try {
			if (isApprove) {
				await api.deleteUser(id);
			} else {
				await api.refuseUserDeleteRequest(id);
			}
			showSuccessToast(strings.pages.pendingDeleteAccounts.table.successApproveOrRefuse(isApprove));
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.paginetedListShelf.refresh();
			this.loader.end();
		}
	};
}
