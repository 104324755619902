import { makeAutoObservable } from "mobx";
import {
	AttributeShelf,
	LoaderShelf,
	PaginatedListShelf,
} from "@startapp/mobx-utils";
import { FormShelf, ImagePickerShelf } from "@startapp/mobx-utils/src/web";
import api from "~/resources/api";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import { onFetchError } from "~/resources/fetchError";
import FilePickerShelf from "../FilePickerShelf";

export class ExamShelf {
	public loader = new LoaderShelf();
	public imageShelf = new ImagePickerShelf(api.uploadImage);
	public code = new AttributeShelf<number>(0);
	public pdf = new FilePickerShelf(api.uploadUncertainFile);
	public id = new AttributeShelf<string>("");
	public type = new AttributeShelf<string>("");
	public paginatedListShelfExamsByPet: PaginatedListShelf<api.Exam>;
	public paginatedListShelfExamsByConsultation: PaginatedListShelf<api.Exam>;
	public paginatedListShelfExamsByHospitalization: PaginatedListShelf<api.Exam>;

	public formShelf = new FormShelf({
		name: "",
		type: "",
	});

	constructor(id: string, type: "consultation" | "pet" | "hospitalization") {
		makeAutoObservable(this);
		if (id) {
			this.id.setValue(id);
			this.type.setValue(type);
			if (type === "consultation") {
				this.paginatedListShelfExamsByConsultation = new PaginatedListShelf(
					(page) => api.getAllExamByConsultation(page, id),
					{
						fetchOnConstructor: true,
						onFetchError,
					},
				);
			}
			if (type === "pet") {
				this.paginatedListShelfExamsByPet = new PaginatedListShelf(
					(page) => api.getAllExamByPet(page, id),
					{
						fetchOnConstructor: true,
						onFetchError,
					},
				);
			}
			if (type === "hospitalization") {
				this.paginatedListShelfExamsByHospitalization = new PaginatedListShelf(
					(page) => api.getAllExamByHospitalization(page, id),
					{
						fetchOnConstructor: true,
						onFetchError,
					},
				);
			}
		}

	}

	public examData = (): api.EditExam => {
		const data = this.formShelf.getValues();
		return {
			name: data.name,
			code: this.code.value || null,
			type: data.type,
			image: this.imageShelf.uploadedImage,
			pdf: this.pdf.uncertainfiedFile || null,
		};
	};
	public clearFields = () => {
		const data = this.formShelf.getValues();
		this.imageShelf.getPickerFields().setUploadedImage(null);
		return {
			name: data.name = "",
			type: data.type = "",
		};
	};

	public createExam = async (onSuccess: () => void) => {
		this.loader.start();
		try {
			if (this.type.value === "consultation") {
				await api.createExamConsultation(
					this.examData(),
					this.id.value,
				);
				this.paginatedListShelfExamsByConsultation.refresh();
			}
			if (this.type.value === "hospitalization") {
				await api.createExamHospitalization(
					this.examData(),
					this.id.value,
				);
				this.paginatedListShelfExamsByHospitalization.refresh();
			}
			this.clearFields();
			showSuccessToast(strings.pages.consultations.createExams.onSuccess);
			onSuccess();
		} catch (e) {
			const error = Errors.treatError(e);
			if (error.type === api.ErrorType.Validation) {
				this.formShelf.fieldError.cleanAndAddErrors(JSON.parse((e as Error).message));
			} else {
				const errorMessage = Errors.handleError(e);
				showErrorToast(errorMessage);
			}
		} finally {
			this.loader.end();
		}
	};
}

