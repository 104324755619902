import React from "react";
import { observer } from "mobx-react-lite";
import { Grid } from "@chakra-ui/react";
import strings from "~/resources/strings";
import api from "~/resources/api";
import { ResultCard } from "../ResultCard";

interface IProps {
	status: api.ClinicStatsForAdmin;
	isClinic?: boolean;
	isLoading: boolean;
}

export const SectionStatusHome: React.FC<IProps> = observer((props) => {
	const {
		status,
		isClinic,
		isLoading,
	} = props;
	const componentStrings = strings.components.statusSection;

	return (
		<Grid
			gridTemplateColumns={{
				base: "repeat(2,1fr)",
				lg: "repeat(3,1fr)",
				xl: "repeat(4,1fr)",
			}}
			mt={6}
			mx="auto"
			gap={6}
			p={3}
		>
			{!isClinic &&
				<ResultCard
					isLoading={isLoading}
					title={componentStrings.registredClinics}
					value={status?.clinics}
				/>}
			<ResultCard
				isLoading={isLoading}
				title={componentStrings.hospitalizedPets}
				value={status?.hospitalizedPets}
			/>
			<ResultCard
				isLoading={isLoading}
				title={componentStrings.castredPets}
				value={status?.pets}
			/>
			<ResultCard
				isLoading={isLoading}
				title={componentStrings.registredUsers}
				value={status?.tutors}
			/>
			<ResultCard
				isLoading={isLoading}
				title={componentStrings.consultationAppointments}
				value={status?.consultations}
			/>
		</Grid>
	);
});

