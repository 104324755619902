import { makeAutoObservable } from "mobx";
import { FormShelf, ImagePickerShelf } from "@startapp/mobx-utils/src/web";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";

const pageStrings = strings.pages.pets.edit;

export default class Store {

	public formShelf = new FormShelf({
		name: "",
		age: "",
		breed: "",
	});

	public isCastrated = new AttributeShelf(false);
	public animalSex = new AttributeShelf(api.AnimalSex.male);
	public species = new AttributeShelf(api.Species.dog);
	public loader = new LoaderShelf();
	public birthdate = new AttributeShelf(new Date());

	public imageShelf = new ImagePickerShelf(api.uploadImage);

	public id = new AttributeShelf("");

	constructor(id?: string){
		makeAutoObservable(this);

		if (id) {
			this.id.setValue(id);
			this.getPet(id);
		}
	}

	public getPet = async (id: string) => {
		this.loader.start();
		try {
			const pet = await api.getPetById(id);
			this.setInitValues(pet);
		} catch (e) {
			const error = Errors.handleError(e);
			showErrorToast(error);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (pet: api.Pet) => {
		this.formShelf = new FormShelf({
			name: pet.name,
			age: pet.age.toString(),
			breed: pet.breed,
		});
		this.species.setValue(pet.species);
		if (pet.image) {
			this.imageShelf.getPickerFields().setUploadedImage(pet.image);
		}

		if (pet.animalSex){
			this.animalSex.setValue(pet.animalSex);
		}

		if (pet.species){
			this.species.setValue(pet.species);
		}

		if (pet.isCastrated){
			this.isCastrated.setValue(pet.isCastrated);
		}

		if (pet.birthdate){
			this.birthdate.setValue(pet.birthdate);
		}
	};

	public editPet = async (onSuccess: () => void) => {
		this.loader.start();
		try {

			const data = this.formShelf.getValues();
			await api.editPet(this.id.value, {
				name: data.name,
				age: data.age,
				species: this.species.value,
				breed: data.breed,
				birthdate: this.birthdate.value,
				image: this.imageShelf.uncertainfiedImage!,
				animalSex: this.animalSex.value,
				isCastrated: this.isCastrated.value,
			});

			showSuccessToast(pageStrings.success);
			onSuccess();
		} catch (e) {

			const error = Errors.treatError(e);
			if (error.type === api.ErrorType.Validation) {
				this.formShelf.fieldError.cleanAndAddErrors(JSON.parse((e as Error).message));
			} else {
				const errorMessage = Errors.handleError(e);
				showErrorToast(errorMessage);
			}
		} finally {
			this.loader.end();
		}
	};
}
