import React from "react";
import { observer } from "mobx-react-lite";
import api from "~/resources/api";
import { Avatar, Flex, Text } from "@chakra-ui/react";
import strings from "~/resources/strings";

interface IProps {
	user: api.UserWithPets;
}

export const InfoPetsOfUser: React.FC<IProps> = observer((props) => {
	const componentStrings = strings.components.infoPetsOfUser;
	const { user } = props;

	return (
		<>
			<Text>{componentStrings.petAmount(user.pets.length || 0, user.petParents)}</Text>
			<Flex flexWrap="wrap">
				{
					user.pets.map((pet) => (
						<Flex
							key={pet.id}
							justifyContent="flex-start"
							alignItems="center"
							width={{ base: "100%",  md: "auto"}}
							flexDirection={{ base: "row", md: "column"}}
							pr={2}
							pb={2}
						>
							<Avatar src={pet.image.url} w={10} h={10} />
							<Text mx={4}>{pet.name}</Text>
						</Flex>
					))
				}
			</Flex>
		</>
	);
});
