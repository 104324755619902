import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Text, useDisclosure } from "@chakra-ui/react";
import { DateModal, PeriodGroup, SectionStatusHome } from "~/components";
import Store from "./store";
import api from "~/resources/api";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import strings from "~/resources/strings";
import format from "~/resources/format";

interface IProps {
	isClinic?: boolean;
}

export const HomePage: React.FC<IProps> = observer((props) => {
	const { isClinic } = props;
	const pageStrings = strings.pages.home;
	const { authStore } = useGlobalStore();
	const store = useLocalObservable(() => new Store(authStore.currentAdminUser?.clinic?.id || "", isClinic));
	const { isOpen, onClose, onOpen } = useDisclosure();

	const getCustomDescription = (startDate: Date | null, endDate: Date | null) => {
		if (startDate && endDate){
			return filterStrings.betweenDates(format.date(startDate), format.date(endDate));
		}

		return filterStrings.custom;
	};
	const getDate = (time: number) => {
		const todayTime = new Date().getTime();
		return new Date(todayTime - time);
	};

	const filterStrings = strings.components.filterPeriod;
	const todayStringDate = format.dateWithoutYear(new Date());

	const oneDay = ((1000 * 60) * 60) * 24;
	const sevenDays = oneDay * 7;
	const fifteenDays = oneDay * 15;
	const thirtyDays = oneDay * 30;

	const dates = {
		yesterday: () => {
			const date = getDate(oneDay);
			store.startDate.setValue(date);
			store.endDate.setValue(date);
			store.fetchModelShelf.fetchModel();
		},
		today: () => {
			store.startDate.setValue(new Date());
			store.endDate.setValue(new Date());
			store.fetchModelShelf.fetchModel();
		},
		sevenDaysBefore: () => {
			const date = getDate(sevenDays);
			store.startDate.setValue(date);
			store.endDate.setValue(new Date());
			store.fetchModelShelf.fetchModel();
		},
		fifteenDaysBefore: () => {
			const date = getDate(fifteenDays);
			store.startDate.setValue(date);
			store.endDate.setValue(new Date());
			store.fetchModelShelf.fetchModel();
		},
		thirtyDaysBefore: () => {
			const date = getDate(thirtyDays);
			store.startDate.setValue(date);
			store.endDate.setValue(new Date());
			store.fetchModelShelf.fetchModel();
		},
	};

	const items = [
		{
			title: filterStrings.yesterday,
			description: format.dateWithoutYear(getDate(oneDay)),
			onClick: dates.yesterday,
		},
		{
			title: filterStrings.today,
			description: format.dateWithoutYear(new Date()),
			onClick: dates.today,
		},
		{
			title: filterStrings.sevenDaysAgo,
			description: filterStrings.betweenDates(format.dateWithoutYear(getDate(sevenDays)), todayStringDate),
			onClick: dates.sevenDaysBefore,
		},
		{
			title: filterStrings.fifteenDaysAgo,
			description: filterStrings.betweenDates(format.dateWithoutYear(getDate(fifteenDays)), todayStringDate),
			onClick: dates.fifteenDaysBefore,
		},
		{
			title: filterStrings.thirtyDaysAgo,
			description: filterStrings.betweenDates(format.dateWithoutYear(getDate(thirtyDays)), todayStringDate),
			onClick: dates.thirtyDaysBefore,
		},
		{
			title: filterStrings.custom,
			description: getCustomDescription(store.startDate.value, store.endDate.value),
			onClick: onOpen,
		},
	];

	const onSubmitDateModal = async () => {
		await store.fetchModelShelf.fetchModel();
		onClose();
	};


	const onCancelAndClearFilter = async () => {
		store.startDate.setValue(null);
		store.endDate.setValue(null);
		await store.fetchModelShelf.fetchModel();
		onClose();
	};

	React.useEffect(()=> {
		const fetchData = async () => {
			await authStore.getCurrentAdminUser();
			if (authStore.currentAdminUser?.type === api.AdminUserType.marketing){
				return ;
			}
			store.id.setValue(authStore.currentAdminUser?.clinic?.id || "");
			await store.fetchModelShelf.fetchModel();
		};
		fetchData();
	},[]);

	return (
		<Flex
			justifyContent="center"
			direction="column"
			mx="auto"
			w="100%"
		>
			{
				authStore.currentAdminUser?.type !== api.AdminUserType.marketing ? (
					<>
						<PeriodGroup items={items} />
						<SectionStatusHome
							isLoading={store.fetchModelShelf.loader.isLoading}
							isClinic={!!authStore.currentAdminUser?.clinic?.id}
							status={store.fetchModelShelf.model.value as api.ClinicStatsForAdmin}
						/>
						<DateModal
							onSubmit={onSubmitDateModal}
							isOpen={isOpen}
							onClose={onCancelAndClearFilter}
							closeOnOverlayClick
							title={filterStrings.selectThePeriod}
							startInput={{
								label: filterStrings.startDate,
								onChange: store.startDate.setValue,
								selectedDate: store.startDate.value,
								isDisabled: false,
							}}
							endInput={{
								label: filterStrings.endDate,
								onChange: store.endDate.setValue,
								selectedDate: store.endDate.value,
								isDisabled: false,
							}}
						/>
					</>
				) : (
					<Text
						textAlign="center"
						fontSize={18}
						py={5}
					>
						{pageStrings.welcome(authStore.currentAdminUser.name)}
					</Text>
				)
			}
		</Flex>
	);
});

