import React from "react";
import { observer } from "mobx-react-lite";
import {
	FormControlProps,
	Flex,
	Grid,
} from "@chakra-ui/react";
import { GrFormClose } from "react-icons/gr";
import strings from "~/resources/strings";
import { DetailsRow } from "~/components";

interface IProps extends FormControlProps {
	value: Date[];
}

export const SelectedHours: React.FC<IProps> = observer((props) => {
	const { value } = props;
	const onRemove = (id: number) =>{
		value.splice(id, 1);
	};

	return (
		<Grid
			w="100%"
			gap={1}
			gridTemplateColumns="repeat(2,1fr)"
		>
			{value.map((hour, index) => (
				<Flex
					alignItems="center"
					bg="#f2f2f2"
					_hover={
						{bg:"#D3D3D3"}
					}
					transition=".3s"
					justifyContent="space-between"
					rounded="xl"
					key={index}
					px={1}
				>
					<DetailsRow value={strings.format.hour(hour)} />
					<GrFormClose size={30} onClick={() => onRemove(index)} />
				</Flex>
			))}
		</Grid>
	);

});
