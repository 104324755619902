import React from "react";
import { observer } from "mobx-react-lite";
import { Td, Text } from "@chakra-ui/react";
import { TableModel } from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import format from "~/resources/format";

interface IProps{
	isClinic?: boolean;
}

const TableView: React.FC<IProps> = (props) => {
	const { isClinic } = props;
	const pageStrings = strings.pages.vets;
	const { authStore } = useGlobalStore();
	const apiGetMethod = isClinic ? api.getAllVetsInCurrentClinic : api.getAllVetsToNotShowInSite;

	return (
		<TableModel
			header={isClinic ? pageStrings.table.header : pageStrings.table.headerAdmin}
			tableTitle={pageStrings.table.title}
			onAdd={isClinic ? true : undefined}
			initStoreSettings={{
				apiGetAllMethod: apiGetMethod,
				deleteShelfInitValues: {
					apiMethod: api.deleteVet,
				},
			}}
			renderRow={(item) => (
				<>
					<Td>
						<Text>{item.name}</Text>
					</Td>
					{
						!authStore.currentAdminUser?.clinic &&
							<Td>
								<Text>{item.clinic?.adminUser?.name}</Text>
							</Td>
					}
					<Td>
						<Text>{format.documentNumber(item.documentNumber!)}</Text>
					</Td>
				</>
			)}
			hasDelete
		/>
	);
};

export default observer(TableView);
