import { makeAutoObservable } from "mobx";
import {
	AttributeShelf,
	LoaderShelf,
	PaginatedListShelf,
} from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";

export default class Store {
	public paginetedListShelf: PaginatedListShelf<api.Consultation>;
	public loader = new LoaderShelf();
	public startDate = new AttributeShelf<Date | null>(null);
	public endDate = new AttributeShelf<Date | null>(null);

	constructor(method: (page: number, filterOptions: api.FilterByPeriod | null) => Promise<api.Consultation[] | api.ConsultationForClinic[]>) {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			(page) => method(page, this.filterOptions),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
	}
	public get filterOptions(): api.FilterByPeriod | null{
		return {
			startDate: this.startDate.value,
			endDate: this.endDate.value,
		};
	}

}
