import React from "react";
import { observer } from "mobx-react-lite";
import { StackProps } from "@chakra-ui/react";
import strings from "~/resources/strings";
import {
	ModalComponent,
	TextArea,
} from "~/components";

type FieldType = "reason";

interface FormValues {
	field: (field: FieldType) => {
		name: FieldType;
		onChange: (element: {
			target: {
				value: string;
			};
		}) => void;
		value: string;
		error: string | null;
	};
}

interface IProps extends StackProps {
	formValues: FormValues;
	isOpen: boolean;
	onClose: () => void;
	onReprove: () => void;
}

export const ReprovePostModal: React.FC<IProps> = observer((props) => {
	const {
		onClose,
		isOpen,
		onReprove,
		formValues,
	} = props;
	const commonStrings = strings.common;

	return (
		<ModalComponent
			buttonText={commonStrings.send}
			title={strings.common.modal.titleReprove}
			onClick={()=> onReprove()}
			isOpen={isOpen}
			onClose={onClose}
			gap={5}
		>
			<TextArea
				mt={2}
				labelText={commonStrings.fields.reasonForRejection}
				labelProps={{ fontWeight: "bold" }}
				variant="filled"
				errorText={formValues.field("reason").error}
				{...formValues.field("reason")}
			/>
		</ModalComponent>
	);
});

