import { makeAutoObservable } from "mobx";
import {
	AttributeShelf,
	LoaderShelf,
	PaginatedListShelf,
} from "@startapp/mobx-utils";
import { FormShelf, ImagePickerShelf } from "@startapp/mobx-utils/src/web";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";

export class ConsultationShelf {
	public loader = new LoaderShelf();
	public id = new AttributeShelf<string>("");
	public imageShelf = new ImagePickerShelf(api.uploadImage);
	public endDate = new AttributeShelf<Date | null>(null);
	public startDate = new AttributeShelf<Date | null>(null);
	public paginatedListShelfConsultationByPet: PaginatedListShelf<api.Consultation | api.ConsultationForClinic>;
	public fluidTherapyse = new AttributeShelf<api.FluidTherapys[]>([]);

	public formShelfAnamnese = new FormShelf({
		heartRate: "",
		respiratoryFrequency: "",
		tpc: "",
		mucous: "",
		pulse: "",
		rectalTemperature: "",
		cardiopulmonaryAuscultation: "",
		abdominalPalpation: "",
		bodyScore: "",
		otherSystems: "",
		complementaryExams: "",
		suspicions: "",
		diagnosis: "",
		treatment: "",
		description: "",
		solution: "",
		fluidtherapyRate: "",
		velocity: "",
	});

	constructor(petId?: string, method?: (page: number, petId: string) => Promise<api.Consultation[] | api.ConsultationForClinic[]>) {
		makeAutoObservable(this);
		if (petId && method) {
			this.paginatedListShelfConsultationByPet = new PaginatedListShelf(
				(page) => method(page, petId),
				{
					fetchOnConstructor: true,
					onFetchError,
				},
			);
		}
	}

	public get filterOptions () {
		return {
			startDate: this.startDate.value,
			endDate: this.endDate.value,
		};
	}
}

