import React from "react";
import {
	Box,
	Link,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import { Label } from "../Label";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export interface IProps {
	pdf: string | null;
}

export const DocumentLink: React.FC<IProps> = (props) => {

	const {
		pdf,
	} = props;
	const commonStrings = strings.common;

	return (
		<Box>
			{
				pdf !== null && !!pdf &&
					<Box>
						<Label fontWeight="bold" marginBottom={2}>
							{commonStrings.fields.pdf}
						</Label>
						<Link
							href={pdf}
							isExternal
							border="2px solid"
							p={2}
							rounded={8}
							borderColor="primary.500"
							color="primary.500"
						>
							{commonStrings.pdfView} <ExternalLinkIcon mx='2px' />
						</Link>
					</Box>
			}
		</Box>
	);
};
