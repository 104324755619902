import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {
	Button,
	Flex,
	Grid,
} from "@chakra-ui/react";
import {
	CalendarPicker,
	CentralizedCard,
	EnumSelect,
	Label,
	TextInput,
	RadioBoolean,
	ImagePicker,
} from "~/components";
import strings from "~/resources/strings";
import Store from "./store";
import { useHistory } from "~/hooks/useHistory";
import api from "~/resources/api";

const Create: React.FC = () => {
	const { id } = useParams<{id: string}>();
	const history = useHistory();
	const store = useLocalObservable(() => new Store());
	const pageStrings = strings.pages.users;
	const commonStrings = strings.common;

	const onSucess = () => {
		history.goBack();
	};

	return (
		<CentralizedCard
			altMaxWidth
			title={{ text: pageStrings.create.title }}
			button={(
				<Button
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={() => store.createPet(onSucess, id || "")}
				>
					{commonStrings.buttons.add}
				</Button>
			)}
		>
			<TextInput
				labelText={commonStrings.fields.name}
				labelProps={{ fontWeight: "bold" }}
				labelColor="primary"
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("name").error}
				{...store.formShelf.field("name")}
			/>
			<TextInput
				labelText={commonStrings.fields.breed}
				labelProps={{ fontWeight: "bold" }}
				labelColor="primary"
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("breed").error}
				{...store.formShelf.field("breed")}
			/>
			<Grid
				gridTemplateColumns={{
					sm: "1fr",
					md: "1fr 1fr",
				}}
				gap={5}
			>
				<EnumSelect
					items={api.allValuesSpecies()}
					currentValue={store.species.value}
					label={commonStrings.fields.specie}
					onChangeEnum={store.species.setValue}
					tranlateEnum={api.translateSpecies}
				/>
				<TextInput
					labelText={commonStrings.fields.age}
					labelProps={{ fontWeight: "bold" }}
					labelColor="primary"
					type="text"
					isDisabled={store.loader.isLoading}
					onChange={(e) => store.age.setValue(e.target.value)}
				/>
				<EnumSelect
					items={api.allValuesAnimalSex()}
					tranlateEnum={api.translateAnimalSex}
					onChangeEnum={store.animalSex.setValue}
					currentValue={store.animalSex.value}
					label={commonStrings.fields.animalSex}
				/>
				<CalendarPicker
					label={commonStrings.fields.birthdate}
					onChange={store.birthdate.setValue}
					value={store.birthdate.value}
					isOutiline
				/>
				<RadioBoolean label={commonStrings.fields.isCastrated} attributeShelf={store.isCastrated} />
				<Flex direction="column">
					<Label
						fontWeight="bold"
						marginBottom={1}
						color="primary.500"
					>
						{strings.components.imagePicker.selectAnImage}
					</Label>
					<ImagePicker
						pickImage={store.imageShelf.getPickerFields().pick}
						src={store.imageShelf.src}
					/>
				</Flex>
			</Grid>
		</CentralizedCard>
	);
};

export default observer(Create);
