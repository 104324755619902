import React from "react";
import { observer } from "mobx-react-lite";
import { PaginatedListShelf } from "@startapp/mobx-utils";

import {
	AutoCompleteModal,
	TextInput,
} from "..";
import strings from "~/resources/strings";
import api from "~/resources/api";

type DataType = api.Pet | api.Vet;

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	paginatedListShelf: PaginatedListShelf<DataType>;
	methodForFiltered: (items: DataType[]) => DataType[];
	openModal: () => void;
	searchProps: {
		label: string;
		value: string;
		isDisabled: boolean;
	};
	renderItem: (data: DataType) => React.ReactElement<React.JSXElementConstructor<any>>;
}

export const SectionAutoCompleteModal: React.FC<IProps> = observer((props) => {
	const {
		isOpen,
		onClose,
		openModal,
		onKeyUp,
		paginatedListShelf,
		methodForFiltered,
		searchProps,
		renderItem,
	} = props;

	return (
		<>
			<TextInput
				isReadOnly
				cursor="pointer"
				onClick={()=> openModal()}
				labelText={searchProps.label}
				labelProps={{ fontWeight: "bold" }}
				labelColor="primary"
				type="text"
				value={searchProps.value}
				isDisabled={searchProps.isDisabled}
			/>
			<AutoCompleteModal
				isOpen={isOpen}
				onClose={onClose}
				header={
					<TextInput
						placeholder={strings.components.searchInput.placeholder}
						onKeyUp={onKeyUp}
					/>
				}
				listProps={{
					data: searchProps.value ?
						methodForFiltered(paginatedListShelf.items)
						:
						paginatedListShelf.items,
					loading: paginatedListShelf.loader.isLoading,
					renderItem,
					paginantionProps: {
						currentPage: paginatedListShelf.page,
						nextPage: paginatedListShelf.nextPage,
						prevPage: paginatedListShelf.previousPage,
						hasNextPage: paginatedListShelf.hasNextPage,
					},
				}}
			/>
		</>
	);
});
