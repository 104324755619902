import React from "react";
import { useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	IconButton,
	Tooltip,
	Flex,
	Box,
	Heading,
} from "@chakra-ui/react";
import { useHistory } from "~/hooks/useHistory";
import {
	ButtonBack,
	CentralizedCard,
	DetailsRow,
	ImageWithModal,
	TableOfConsultByPet,
	TableOfExams,
	TableOfHospitalizationByPet,
	TableOfMedication,
	TableOfVaccines,
} from "~/components";
import { Store } from "./store";
import strings from "~/resources/strings";
import format from "~/resources/format";
import api from "~/resources/api";

interface IProps {
	isClinic?: boolean;
}

const Details: React.FC<IProps> = (props) => {
	const { isClinic } = props;
	const methodForRequestConsult = isClinic ? api.getAllConsultationByPetForAdminClinic : api.getAllConsultationByPet;
	const methodForRequestHospitalization = isClinic ? api.getAllHospitalizationsForAdminClinicByPet : api.getAllHospitalizationsByPet;
	const commonStrings = strings.common;
	const pageStrings = strings.pages.pets.details;
	const { id } = useParams<"id">();
	const store = useLocalObservable(() => new Store(id || "", methodForRequestConsult, methodForRequestHospitalization));
	const history = useHistory();
	const onGoToExamDetails = (idExam: string) => history.push(`/${isClinic ? "clinic" : "admin"}/pets/details/exam/${idExam}`);
	const onGoToMedicationDetails = (idMedication: string) => history.push(`/${isClinic ? "clinic" : "admin"}/consultations/details/detailsAdministredMedication/${idMedication}`);
	const onGoToConsultDetails = (idMedication: string) => {
		history.push(`/${isClinic ? "clinic" : "admin"}/consultations/details/${idMedication}`);
		window.scrollTo(0,0);
	};
	const onGoToHospitalizationDetails = (idMedication: string) => {
		history.push(`/${isClinic ? "clinic" : "admin"}/hospitalization/details/${idMedication}`);
		window.scrollTo(0,0);
	};

	return (
		<Flex
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			w="100%"
		>
			<CentralizedCard
				title={{
					text: commonStrings.detailsTitle,
					helper: (
						<Tooltip label={strings.common.edit}>
							<IconButton
								variant="icon"
								aria-label="Voltar"
								size="lg"
							/>
						</Tooltip>
					),
				}}
				isLoading={store.fetchModelShelf.initialLoader.isLoading}
				altMaxWidth
			>
				{
					store.fetchModelShelf.model.value &&
						<>
							<Box>
								<Heading fontSize="md" color="primary.500">{commonStrings.fields.pet}</Heading>
								<ImageWithModal src={store.fetchModelShelf.fetchedModel.image.url} />
							</Box>
							<Flex w="100%" flexDir={{ base: "column",  xl: "row"}} justifyContent={{ base: "center", xl: "space-between"}}>
								<DetailsRow
									label={commonStrings.fields.name}
									value={store.fetchModelShelf.fetchedModel.name}
									boxProps={{
										width: {
											base: "100%",
											xl: "48.5%",
										},
										marginBottom: {
											base: 5,
											xl: 0,
										},
									}}
								/>
								<DetailsRow
									label={commonStrings.fields.id}
									value={store.fetchModelShelf.fetchedModel.codeFormat}
									boxProps={{
										width: {
											base: "100%",
											xl: "48.5%",
										},
									}}
								/>
							</Flex>
							<Flex
								w="100%"
								flexDir={{ base: "column",  xl: "row"}}
								justifyContent={{ base: "center", xl: "space-between"}}
							>
								<DetailsRow
									label={commonStrings.fields.birthdate}
									value={format.date(store.fetchModelShelf.fetchedModel.birthdate)}
									boxProps={{
										width: {
											base: "100%",
											xl: "48.5%",
										},
										marginBottom: {
											base: 5,
											xl: 0,
										},
									}}
								/>
								<DetailsRow
									label={commonStrings.fields.age}
									value={store.fetchModelShelf.fetchedModel.age}
									boxProps={{
										width: {
											base: "100%",
											xl: "48.5%",
										},
									}}
								/>
							</Flex>
							<Flex
								w="100%"
								flexDir={{ base: "column",  xl: "row"}}
								justifyContent={{ base: "center", xl: "space-between"}}
							>
								<DetailsRow
									label={commonStrings.fields.specie}
									value={api.translateSpecies(store.fetchModelShelf.fetchedModel.species)}
									boxProps={{
										width: {
											base: "100%",
											xl: "48.5%",
										},
									}}
								/>
								<DetailsRow
									label={commonStrings.fields.breed}
									value={store.fetchModelShelf.fetchedModel.breed}
									boxProps={{
										width: {
											base: "100%",
											xl: "48.5%",
										},
										marginTop: {
											base: 7,
											xl: 0,
										},
									}}
								/>
							</Flex>
							<Flex
								w="100%"
								flexDir={{ base: "column",  xl: "row"}}
								justifyContent={{ base: "center", xl: "space-between"}}
							>
								<DetailsRow
									label={commonStrings.fields.animalSex}
									value={api.translateAnimalSex(store.fetchModelShelf.fetchedModel.animalSex)}
									boxProps={{
										width: {
											base: "100%",
											xl: "48.5%",
										},
										marginBottom: {
											base: 7,
											xl: 0,
										},
									}}
								/>
								<DetailsRow
									label={commonStrings.fields.isCastrated}
									value={pageStrings.isCastrated(store.fetchModelShelf.fetchedModel.isCastrated)}
									boxProps={{
										width: {
											base: "100%",
											xl: "48.5%",
										},
									}}
								/>
							</Flex>
						</>
				}
			</CentralizedCard>
			<TableOfExams
				onGoToDetails={onGoToExamDetails}
				exam={store.examShelf.paginatedListShelfExamsByPet.items}
				previousPage={store.examShelf.paginatedListShelfExamsByPet.previousPage}
				nextPage={store.examShelf.paginatedListShelfExamsByPet.nextPage}
				page={store.examShelf.paginatedListShelfExamsByPet.page}
				isLoading={store.examShelf.paginatedListShelfExamsByPet.loader.isLoading}
				hasNextPage={store.examShelf.paginatedListShelfExamsByPet.hasNextPage}
			/>
			<TableOfMedication
				onGoToDetails={onGoToMedicationDetails}
				medicationAdministred={store.administeredMedicationShelf.paginatedListShelfMedicationByPet.items}
				previousPage={store.administeredMedicationShelf.paginatedListShelfMedicationByPet.previousPage}
				nextPage={store.administeredMedicationShelf.paginatedListShelfMedicationByPet.nextPage}
				page={store.administeredMedicationShelf.paginatedListShelfMedicationByPet.page}
				isLoading={store.administeredMedicationShelf.paginatedListShelfMedicationByPet.loader.isLoading}
				hasNextPage={store.administeredMedicationShelf.paginatedListShelfMedicationByPet.hasNextPage}
			/>
			<TableOfVaccines
				vaccines={store.vaccineShelf.paginatedListShelfVaccinesByPet.items}
				previousPage={store.vaccineShelf.paginatedListShelfVaccinesByPet.previousPage}
				nextPage={store.vaccineShelf.paginatedListShelfVaccinesByPet.nextPage}
				page={store.vaccineShelf.paginatedListShelfVaccinesByPet.page}
				isLoading={store.vaccineShelf.paginatedListShelfVaccinesByPet.loader.isLoading}
				hasNextPage={store.vaccineShelf.paginatedListShelfVaccinesByPet.hasNextPage}
			/>
			<TableOfConsultByPet
				onGoToDetails={onGoToConsultDetails}
				consultation={store.consultationShelf.paginatedListShelfConsultationByPet.items}
				previousPage={store.consultationShelf.paginatedListShelfConsultationByPet.previousPage}
				nextPage={store.consultationShelf.paginatedListShelfConsultationByPet.nextPage}
				page={store.consultationShelf.paginatedListShelfConsultationByPet.page}
				isLoading={store.consultationShelf.paginatedListShelfConsultationByPet.loader.isLoading}
				hasNextPage={store.consultationShelf.paginatedListShelfConsultationByPet.hasNextPage}
			/>
			<TableOfHospitalizationByPet
				onGoToDetails={onGoToHospitalizationDetails}
				hospitalization={store.hospitalizationShelf.paginatedListShelfHospitalizationsByPet.items}
				previousPage={store.hospitalizationShelf.paginatedListShelfHospitalizationsByPet.previousPage}
				nextPage={store.hospitalizationShelf.paginatedListShelfHospitalizationsByPet.nextPage}
				page={store.hospitalizationShelf.paginatedListShelfHospitalizationsByPet.page}
				isLoading={store.hospitalizationShelf.paginatedListShelfHospitalizationsByPet.loader.isLoading}
				hasNextPage={store.hospitalizationShelf.paginatedListShelfHospitalizationsByPet.hasNextPage}
			/>
			<ButtonBack />
		</Flex>
	);
};

export default observer(Details);
