import "moment/locale/pt-br";
import LocalizedStrings from "localized-strings";
import format from "./format";
import api from "./api";

const strings = new LocalizedStrings({
	ptBR: {
		removeCharactersExceptLetterNumber: (character: string) => character.replace(/[^a-zA-Z0-9]/g, ""),
		enum: {
			AdminType: {
				master: "Admin",
				clinic: "clínica",
			},
			AnimalSex:{
				male: "Macho",
				female: "Fêmea",
			},
			StateUF:{
				AC: "Acre",
				AL: "Alagoas",
				AP: "Amapá",
				AM: "Amazonas",
				BA: "Bahia",
				CE: "Ceará",
				DF: "Distrito Federal",
				ES: "Espírito Santo",
				GO: "Goiás",
				MA: "Maranhão",
				MT: "Mato Grosso",
				MS: "Mato Grosso do Sul",
				MG: "Minas Gerais",
				PA: "Pará",
				PB: "Paraíba",
				PR: "Paraná",
				PE: "Pernambuco",
				PI: "Piauí",
				RJ: "Rio de Janeiro",
				RN: "Rio Grande do Norte",
				RS: "Rio Grande do Sul",
				RO: "Rondônia",
				RR: "Roraima",
				SC: "Santa Catarina",
				SP: "São Paulo",
				SE: "Sergipe",
				TO: "Tocantins",
			},
			Species:{
				dog: "Cão",
				cat: "Gato",
				rodents: "Roedor",
				reptiles: "Réptil",
				fish: "Peixe",
			},
			Pet:{
				dog: "Cão",
				cat: "Gato",
				rodents: "Roedor",
				reptiles: "Réptil",
				fish: "Peixe",
			},
		},
		components: {
			petAutoCompleteModalCard: {
				petCode: (code: string) => `Código: ${code}`,
				petPlaceholder: "Digite aqui para pesquisar pets...",
			},
			documentAttach: {
				attachDocument: "Anexar Documento",
			},
			avaliation: {
				title: "Avaliação",
				clinicAvaliation: "Avaliação da Clínica",
				vetAvaliation: "Avaliação do Veterinário",
				evaluationNote:(avaliation: number | string) =>  `Nota: ${avaliation}`,
				notAvaliation: "Ainda não há avaliações",
			},
			tooltip: "Clique para abrir a imagem",
			vetAutoCompleteModalCard: {
				vetDocument: (document: string | null) => `Documento: ${document}`,
				vetPlaceholder: "Digite aqui para pesquisar veterinários...",
			},
			imageViewer: {
				seeImages: "Ver fotos",
				noImages: "Sem imagens",
				previous: "Anterior",
				next: "Próximo",
				imageCount: (current: number, total: number) => `${current} de ${total}`,
			},
			imagePicker: {
				selectAnImage: "Selecione uma imagem:",
				pdf: "PDF:",
				await: "Aguarde...",
			},
			pdfPicker: {
				selectAnDocument: "Anexar Documento",
				selectAnPdf: "Selecione um PDF.",
			},
			filterPeriod: {
				sevenDaysAgo: "7 dias atrás",
				fifteenDaysAgo: "15 dias atrás",
				thirtyDaysAgo: "30 dias atrás",
				today: "Hoje",
				yesterday: "Ontem",
				custom: "Customizado",
				selectThePeriod: "Escolher período",
				startDate: "Data inicial",
				endDate: "Data final",
				filter: "Filtrar",
				betweenDates: (startDate: string, hourDate: string) => `${startDate} até ${hourDate}`,
			},
			statusSection: {
				registredClinics: "Clínicas Registradas",
				hospitalizedPets: "PET's Internados",
				castredPets: "PET's Castrados",
				registredUsers: "Usuários Tutores",
				consultationAppointments: "Consultas Marcadas",
			},
			cpfFilter: {
				cpfFilter: "Filtrar por CPF",
				searchButton: "Buscar",
				incompleteCpf: "Digite o CPF completo para realizarmos a busca",
			},
			autoCompleteModal: {
				back: "Voltar",
			},
			autoCompleteList: {
				empty: "Não há itens na lista",
			},
			searchInput: {
				search: "Buscar por",
				placeholder: "Digite aqui...",
			},
			detailsCard: {
				clinic: "Clínica",
				name: "Nome",
				documment: "CNPJ",
				address: "Endereço",
				street: "Rua",
				complement: "Complemento",
				city: "Cidade",
				number: "número",
				district: "Bairro",
				state: "Estado",
				tutor: "Usuário Tutor",
				pet: "PET",
				date: "Data",
				hour: "Horário",
				petInformation: "Informações do Pet",
				avaliation: "Avaliação",
				realizedExams: "Exames Realizados",
				hospitalization: "Internação",
				medicationGiven: "Medicações Ministradas",
			},
			checkinPeriodFilter: {
				filterDate: "Filtrar por data:",
				clearDate: "Limpar Filtro",
				from: "De:",
				until: "Até:",
			},
			infoPetsOfUser: {
				petAmount: (amount: number, petParent: string) => petParent === api.PetParents.father ? `Pai de ${amount} PET's` : `Mãe de ${amount} PET's`,
			},
		},
		nav: {
			home: "Home",
			adminMaster: "Adminstrador Master",
			adminMarketing: "Adminstrador Marketing",
			acessLogs: "Lista de Acessos",
			users: "Usuário Tutor",
			clinics: "Clínicas",
			consultations: "Consultas",
			clinicsShowInSite: "Clínicas (Site)",
			pendingUsers: "Usuários Pendentes",
			hospitalization: "Internações",
			posts: "BLOG",
			vets: "Veterinários",
			vetsShowInSite: "Minha Equipe",
			pets: "PET",
			logout: "Sair",
			pendingDeleteAccounts:"Exclusão de Contas",
			reprovedPosts: "Posts Reprovados",
			pendingPosts:"Postagens Pendentes",
		},
		actions: {
			save: "Salvar",
			finish: "Finalizar Consulta",
			finishHospitalization: "Finalizar Internação",
			delete: "Apagar",
			create: "Criar",
			cancel: "Cancelar",
			filter: "Filtrar",
			clear: "Limpar",
			select: "Selecionar",
			back: "Voltar",
			add: "Adicionar",
			addFluidTherapy: "+ adicionar medicamento na Fluidoterapia",
			addPet: "Cadastrar PET",
			send: "Enviar",
			attach: "Anexar",
			change: "Alterar",
		},
		moment: {
			day: "DD",
			dateMonth: "DD/MM",
			date: "DD/MM/YYYY",
			dateHour: "DD/MM/YYYY HH[h]",
			dateTime: "DD/MM/YYYY HH:mm",
		},
		error: {
			stillLoading: "Ainda Carregando, aguarde.",
			notLoggedIn: "Usuario não logado",
			default: "Houve um erro, tente novamente mais tarde.",
			currentUserIsNotClinic: "O Usuário não é uma clínica.",
			currentUserIsNotMarketing: "O Usuário não é um administrador marketing.",
			currentUserIsNotAdminMaster: "O Usuário não é um administrador.",
			selectImage:"É necessário selecionar uma imagem.",
		},
		common: {
			yes: "Sim",
			no: "Não",
			date: "Data",
			create: "Criar",
			noResutls: "Sem Resultados",
			delete: "Clique para deletar",
			disable: "Clique para desabilitar",
			enable: "Clique para habilitar",
			details: "Clique para ver mais informações",
			approve: "Clique para aprovar ou recusar",
			edit: "Clique para editar",
			clinicCreate: "Criar Clínica",
			cancel: "Cancelar",
			finish: "Finalizar",
			confirm: "Confirmar",
			detailsTitle: "Detalhes",
			editTitle: "Editar",
			send: "Enviar",
			documentPhoto: "Foto:",
			documentProof: "Comprovante:",
			documentProofNoResult: "Comprovante não encontrado",
			pdfView: "Visualizar PDF",
			noRegistered: "Não Cadastrado",
			noAvaliation: "Ainda não há avaliação.",
			close: "Fechar",
			buttons: {
				confirmButton: (isEdit?: boolean) => isEdit ? "Editar" : "Enviar",
				saveOrEdit: (isEdit?: boolean) => isEdit ? "Editar" : "Salvar",
				backButton: "Voltar",
				add: "Adicionar",
				create: "Criar",
				approve: "Aprovar",
				refuse: "Recusar",
			},
			empty: "Campo vazio",
			infoUser: "Informações do Usuaŕio:",
			fields: {
				images: "Imagens",
				documentNumber: "CNPJ",
				day: "dia(s)",
				scheduling: "Consulta",
				clinic: "Clínica",
				vet: "Veterinário",
				user: "Usuário Tutor",
				type: "Tipo de exame:",
				date: "Dia:",
				period: "Período:",
				hour: "Horário:",
				author: "Autor do Post: ",
				hourSelecteds: "Horários Selecionados:",
				consumptionHours: "Horários de ingerir:",
				document: "Documento:",
				dosage: "Dosagem:",
				endConsumptionDate: "Fim da medicação:",
				startConsumptionDate: "Início da medicação:",
				petName: "Pet",
				examName: "Nome do Exame:",
				physicalExam: "Exame Físico",
				indicatedDiet: "Dieta Indicada",
				medicationName: "Nome da Medicação:",
				vaccineName: "Nome do Vacina:",
				examType: "Tipo do Exame:",
				examCode: "Código",
				appliedAt: "Data de aplicação:",
				pdf: "PDF:",
				description: "Descrição:",
				commentDescription: "Comentários Descritivos",
				avaliation: "Avaliação:",
				dateConsult: "Dia da consulta:",
				examsPerfomed: "Exames Realizados",
				medicationsGiven: "Medicações Ministradas",
				vaccines: "Vacinação",
				phone: "Número",
				street: "Rua",
				streetNumber: "Número",
				complementary: "Complemento",
				city: "Cidade",
				state: "Estado",
				email: "Email",
				zipcode: "CEP",
				password: "Senha",
				neighborhood: "Bairro",
				clinicName: "Nome da clínica",
				which: "Quais",
				id: "ID",
				name: "Nome",
				reasonForRejection: "Motivo da rejeição:",
				endDatedate: "Data:",
				startDate: "Data de entrada:",
				endDate: "Data de saída:",
				entryTime: "Horário de entrada:",
				exitTime: "Horário de saída:",
				birthdate: "Nascimento:",
				isCastrated: "Castrado:",
				age: "Idade:",
				species: "Espécie:",
				animalSex: "Sexo:",
				breed: "Raça:",
				cpf: "CPF:",
				cnpj: "CNPJ",
				function: "Função:",
				address: "Endereço:",
				disable: "Desabilitar:",
				approvedBy: "Aprovado por:",
				photo: "Foto:",
				mobilePhoto:"Foto Mobile:",
				account: "Conta:",
				typeAccount: "Tipo da Conta:",
				accountDv: "Dígito da Conta:",
				agency: "Agência:",
				agencyDv: "Dígito da Agência:",
				bankCode: "Código do Banco:",
				bankName: "Banco:",
				documentPhoto:"Comprovante de residência:",
				receiptImage: "Comprovante de transferência:",
				value: "Valor:",
				createAt: "Criado em:",
				acceptedAt: "Aceito em:",
				acceptedAtHour: "Horário:",
				status: "Situação:",
				approvedValue: "Valor aprovado:",
				pendingValue: "Valor a ser aprovado:",
				approvedAt: "Data de confirmação:",
				amount: "Quantidade",
				concatData: (left: string, right: string | null) =>
					right ? `${left} - ${right}` : left,
				pix: "Pix:",
				code: "Código",
				available: "Disponível: ",
				adm: "ADM",
				others: "Outros",
				specie: "Espécie:",
				services: "Serviços",
				title: "Título:",
				slug: "Slug:",
				image: "Imagem:",
				text: "Texto:",
				finish: "Finalizada",
				notFinish: "Não finalizada.",
				nextVaccinationAt: "Proxima vacinação:",
				expirationDateAt: "Validade:",
				manufacturingDateAt: "Data de Fabricação:",
				batch: "Lote:",
				pet: "PET",
				review: "Revisão:",
				reviewOfAdmin: "Revisão do Admin",
				heartRate: "Frequência cardíaca (FC) em bpm",
				respiratoryFrequency: "Frequência respiratória (FR) em bpm",
				tpc: "TPC",
				mucous: "Mucosas",
				pulse: "Pulso",
				rectalTemperature: "Temperatura retal",
				cardiopulmonaryAuscultation: "Ausculta cardiopulmonar",
				abdominalPalpation: "Palpação abdominal",
				bodyScore: "Escore corporal",
				otherSystems: "Outros sistemas",
				complementaryExams: "Exames complementares",
				suspicions: "Suspeita",
				diagnosis: "Diagnóstico	",
				treatment: "Tratamento",
				solution: "Solução",
				fluidtherapyRate: "Taxa de fluidoterapia",
				fluidtherapy: "Fluidoterapia",
				velocity: "Velocidade",
			},
			mask: {
				date: "__/__/____",
				time: "__:__",
			},
			modal: {
				title: "Deletar",
				titleCreateExam: "Cadastrar Exame",
				titleReprove: "Rejeitar Post",
				titleMedication: "Cadastrar Medicação",
				titleVaccine: "Cadastrar Vacina",
				titleConsult: "Apagar Consulta",
				titleFinishConsult: "Finalizar Consulta e enviar para histórico de PET?",
				titleBlock: (isBlock: boolean) => isBlock ? "Desbloquear" : "Bloquear",
				titleApproveOrRefuse: "Aprovar ou Recusar",
				description: (itemName: string) => `Tem certeza que deseja deletar ${itemName}?`,
				descriptionApprove: "Deseja aprovar este POST?",
				descriptionFinish: "Tem certeza que deseja finalizar?",
				descriptionRemove: (itemName: string) => `Tem certeza que deseja remover ${itemName}?`,
				descriptionCancelRequest: (itemName: string) => `Tem certeza que deseja cancelar exclusão de ${itemName}?`,
				descriptionBlock: (itemName: string, isBlock: Date | null) => isBlock ? `Tem certeza que deseja desbloquear ${itemName}?` : `Tem certeza que deseja bloquear ${itemName}?`,
				descriptionApproveOrRefuse: (ItemName: string) => `Deseja aprovar ou recusar a solicitação de exclusão de conta do usuario ${ItemName}?`,
				descriptionApproveOrRefuseUser: (approve: boolean | null, userName: string) => approve ? `Tem certeza que gostaria de aprovar a solicitação de exclusão de conta do usuario ${userName} ?` :
					`Tem certeza que gostaria de recusar a solicitação de exclusão de conta do usuario ${userName} ?`,
				statusUser: (isUserBlocked: boolean) => `O usuário ${isUserBlocked ? "foi bloqueado" : "foi desbloqueado"}`,
				titleFinishHospitalization: "Finalizar Hospitalização e enviar para histórico de PET?",
				button: {
					approve: "Aprovar",
					finish: "Finalizar",
					refuse: "Recusar",
					remove: "Remover",
					delete: "Deletar",
					cancel: "Cancelar",
					block: "Bloquear",
					disable: "Bloquear",
					enable: "Desbloquear",
					confirm: "Confirmar",
				},
			},
		},
		home: {
			message: (userName: string) => `Bem vindo ao Dashboard,  ${userName}!`,
		},
		pages: {
			adminUserTable: {
				table: {
					title: "Lista de Administradores Master",
					header: ["Nome", "Email", "CPF", ""],
					delete: "Administrador deletado com sucesso!",
					totalText: "Administrador cadastrados",
					addButtonText: "Cadastrar Funcionário",
				},
				createOrEdit: {
					title: (isEdit?: boolean) => isEdit ? "Editar Administrador" : "Cadastrar Administrador",
					success: (isEdit?: boolean) => isEdit ? "Administrador editado com sucesso" : "Administrador criado com sucesso",
				},
				logAcess: {
					table: {
						header: ["Data", "Hora", "Usuário", "Ação"],
					},
				},
			},
			ClinicTable: {
				table: {
					title: "Clínicas",
					header: ["Clínica", "Código", "E-mail", "Telefone", "CNPJ"],
					delete: "a clínica foi deletada!",
				},
				createOrEdit: {
					title: (isEdit?: boolean) => isEdit ? "Editar Clínica" : "Cadastrar Clínica",
					success: (isEdit?: boolean) => isEdit ? "Clínica editada com sucesso" : "Clínica criada com sucesso",
				},
			},
			consultations: {
				table: {
					title: "Lista de consultas",
					header: ["Usuário Tutor", "Veterinário", "Pet", "Dia", "Horário","Finalizada em", ""],
					headerForAdmin: ["Clínica","Usuário Tutor", "Veterinário", "Pet", "Dia", "Horário","Finalizada em", ""],
					delete: "Consulta finalizada!",
					modalDescription: "Deseja excluir essa consulta?",
				},
				tableVaccines: {
					noReturn: "Sem data de retorno.",
					header: ["Nome", "Código", "Data da Aplicação", "Retorno da Próxima Vacina","Fabricação", "Validade", "Lote"],
				},
				tableExams: {
					header: ["Nome", "Tipo", "Código", ""],
				},
				tableMedication: {
					header: ["Nome", "Código", "Período", "Início da medicação", "Fim da medicação"],
				},
				createExams: {
					onSuccess: "Exame cadastrado!",
				},
				createAdministeredMedication: {
					onSuccess: "Medicações cadastradas!",
				},
				createVaccine: {
					onSuccess: "Vacina cadastradas!",
				},
				createOrEdit: {
					title: (isEdit?: boolean) => isEdit ? "Editar Consulta" : "Criar Consulta",
					onSuccess: (isEdit?: boolean) => isEdit ? "Consulta editada com sucesso!" : "Consulta criada com sucesso!",
				},
				details: {
					finish: "Consulta finalizada!",
				},
			},
			login: {
				loginIn: "Login",
				altImg: "Connect-vet logo",
				recoveryPassword: "Recuperar Senha",
				success: (userName: string) => `Bem vindo ${userName}.`,
				fields: {
					email: "E-mail",
					password: "Senha",
				},
			},
			pendingPost: {
				title: "Postagens Pendentes",
				success: "Post aprovado!",
				refuse: "Post reprovado!",
				headers: ["Título", "Slug", "Autor do Post", "Usuário", "Criado em: ", ""],
			},
			clinic: {
				table: {
					title: "Clínicas",
					header: ["Clínica", "Código", "Email", "Telefone", "CNPJ", ""],
					delete: "Clínica deletada!",
					onAdd: "Cadastrar nova clínica",
				},
				createOrEdit: {
					title: (isEdit?: boolean) => isEdit ? "Editar Clínica" : "Cadastrar Clínica",
					success: (isEdit?: boolean) => isEdit ? "Clínica editada com sucesso" : "Clínica criada com sucesso",
					titleSection: {
						clinic: "Clínica",
						address: "Endereço",
						image: "Foto",
					},
				},
			},
			hospitalization: {
				table: {
					title: "Lista de Internações",
					header: [ "Nome do Pet", "Data de entrada", "Data de saída", "Horário de Entrada", "Horário de Saída", "Finalizado em"],
					headerForAdmin: [ "Clínica", "Nome do Pet", "Data de entrada", "Data de saída", "Horário de Entrada", "Horário de Saída", "Finalizado em"],
					unscheduled: "Não agendado",
				},
				create: {
					title: "Criar Internação",
					success: "Internação criada com sucesso",
				},
				details: {
					finish: "Internação finalizada!",
					title: "Detalhes da Internação",
					unscheduled: "Não agendado",
				},
			},
			recoveryPage: {
				success: "Bem vindo!",
				altImg: "Connect-vet logo",
				title: "Recuperação de Senha",
				newAccess: "Novo Acesso",
				sendSuccess: "E-mail enviado com sucesso!",
				recoverPasswordButton: "Enviar e-mail",
				newPasswordButton: "Alterar Senha",
				email: "Email",
				token: "Token",
				validToken: "Token válido!",
				confirmTokenButton: "Confirmar Token",
				tokenButton: "Já possui um token?",
				noTokenButton: "Não possuo token",
				newPassword: "Nova senha",
				confirmNewPassword: "Confirme sua nova senha",
				samePasswordError: "As senhas não correspondem",
				successPassword: "Senha alterada com sucesso",
				shortPassword: "Senha muito curta",
			},
			users: {
				table: {
					title: "Lista de Usuários Tutores",
					header: ["Nome do Usuário Tutor", "Email", "Telefone", "CPF", ""],
					delete: (title: string) => `Usuário ${title} foi deletado!`,
					totalText: "Usuário cadastrado",
					addButtonText: "Cadastrar Funcionário",
					placeholder: "Digite aqui...",
					options: ["CPF"],
				},
				create: {
					title: "Cadastrar PET",
					created: "Pet Cadastrado!",
				},
			},
			pets: {
				table: {
					title: "Lista de Pets",
					header: ["Nome do Pet", "Código", "Idade", "Sexo", "Raça", "Espécie", ""],
					delete: (title: string) => `Pet ${title} foi deletado!`,
					totalText: "Pet cadastrado",
					placeholder: "Digite aqui...",
					options: ["Nome"],
				},
				details: {
					isCastrated: (isCastrated: boolean) => isCastrated ? "Sim" : "Não",
					exams: "Exames Realizados",
					administredMedication: "Medicações Ministradas",
					vaccines: "Vacinas",
					hospitalization: "Internações Feitas",
					consults: "Consultas Feitas",
					history: "Histórico",
				},
				edit: {
					title: "Editar Pet",
					success: "Pet editado com sucesso",
				},
			},
			posts: {
				table: {
					title: "Lista de Posts",
					header: ["Postagens", "Slug", "Data", ""],
					delete: "Post deletado com sucesso!",
					success: (post: string) => `Post ${post} deletado com sucesso!`,
				},
				createOrEdit: {
					title: (isEdit?: boolean) => isEdit ? "Editar Postagem" : "Nova Postagem",
					success: (isEdit?: boolean) => isEdit ? "Post editado com sucesso" : "Post criado com sucesso",
					textAddImage: "Clique em adicionar para escolher uma imagem",
					titlePlaceholder: "Título da postagem",
					slugPlaceholder: "Slug da postagem",
				},
			},
			pendingDeleteAccounts: {
				table: {
					title:"Solicitações de Exclusão de Contas",
					header: ["Usuario","id",""],
					successApproveOrRefuse: (isApprove: boolean) => `Exclusão de Conta${isApprove ? " Aprovada" : " Recusada"}`,
				},
			},
			vets: {
				table: {
					title: "Lista de Veterinários",
					header: ["Nome do Veterinário", "Descrição"],
					headerAdmin: ["Nome do Veterinário","Clínica", "Descrição"],
					delete: "Veterinário apagado com sucesso!",
				},
				create: {
					title: "Cadastrar Veterinário",
					success: "Veterinário Cadastrado com sucesso!",
				},
			},
			vetsShowInSite: {
				table: {
					title: "Minha Equipe",
					header: ["Nome do Veterinário", "Descrição", ""],
				},
				createOrEdit: {
					title: (isEdit?: boolean) => isEdit ? "Editar Veterinário" : "Nova Veterinário",
					success: (isEdit?: boolean) => isEdit ? "Veterinário editado com sucesso" : "Veterinário criado com sucesso",
				},
			},
			reprovedPosts: {
				table: {
					title: "Lista de Posts Reprovados",
					headers: [ "Clinica", "Título", "Slug", "Criado Em", ""],
				},
			},
			home: {
				welcome: (adminName: string) => `Bem vindo ao dashboard ${adminName}!`,
			},
		},
		format,
	},

});

export default strings;
