import { makeAutoObservable } from "mobx";
import {
	AttributeShelf,
	FetchModelShelf,
	LoaderShelf,
} from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";

export class Store {

	public fetchModelShelf: FetchModelShelf<api.AdminUser>;
	public clinicId = new AttributeShelf<string>("");
	public clinicRating = new AttributeShelf<number | null>(null);
	public loader = new LoaderShelf();

	constructor(id: string) {
		makeAutoObservable(this);
		this.fetchModelShelf = new FetchModelShelf(id, ()=> api.getAdminUser(id),
			{
				onFetchError,
			},
		);
		if (id) {
			this.getClinicRating();
		}
	}
	public getClinicRating = async () => {
		this.loader.start();
		try {
			await this.fetchModelShelf.fetchModel();
			this.clinicId.setValue(this.fetchModelShelf.fetchedModel.clinic?.id || "");
			const getRating = await api.getClinicAverageRating(this.clinicId.value);
			this.clinicRating.setValue(getRating);
		} catch (e) {
			onFetchError(e);
		} finally {
			this.loader.end();
		}
	};
}
