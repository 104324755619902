import React from "react";
import {
	Input,
	BoxProps,
	Flex,
	Select,
	Box,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { colors } from "~/themes/colors";
import strings from "~/resources/strings";

interface IProps<DateType> {
	value: string;
	onChange: (e: string) => void;
	onClickSearch: () => void;
	placeholder?: string;
	containerProps?: BoxProps;
	select?: {
		selectedValue: DateType;
		onSelect: (value: DateType) => void;
		optionsValues: DateType[];
	};
}

export const SearchInput = <DateType extends string,>(props: IProps<DateType>) => {
	const componentStrings = strings.components.searchInput;
	const {
		value,
		onChange,
		onClickSearch,
		placeholder,
		containerProps,
		select,
	} = props;

	return (
		<Flex w="100%"
			alignItems="center"
			h={50}
			{...containerProps}
		>
			{select && (
				<Select
					value={select.selectedValue || ""}
					bg="primary.500"
					color="white"
					maxW={{ base: 24, sm: 32, md: 52 }}
					iconColor="secondary.500"
					h={50}
					onChange={(e) => {
						const chosenValue = e.target.value as unknown as DateType;
						select.onSelect(chosenValue);
					}}
					fontSize="sm"
					fontWeight="bold"
					borderRadius={10}
					borderRightRadius={0}
				>
					{
						select.optionsValues.map((option, index)=> (
							<option
								style={{ backgroundColor: colors.primary[500] }}
								aria-selected="true"
								value={option}
								key={index}
							>
								{componentStrings.search} {option}
							</option>
						))
					}
				</Select>
			)}

			<Flex
				flexGrow={1}
				alignItems="center"
				bg="white"
				borderRadius={5}
			>
				<Input
					borderRadius={5}
					{...select && { borderLeftRadius: 0 }}
					value={value}
					h={50}
					bg="white"
					placeholder={placeholder}
					_focus={{
						bg: "white",
					}}
					_hover={{
						bg: "white",
					}}
					onChange={(e) => onChange(e.target.value)}
				/>
				<Box
					w="1px"
					bg="gray.300"
					h={10}
					mx={2}
				/>
				<SearchIcon
					color="primary.500"
					cursor="pointer"
					mr={{ base: 2, md: 5}}
					onClick={onClickSearch}
				/>
			</Flex>
		</Flex>
	);
};
