import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Tr } from "@chakra-ui/react";
import Store, { TableModelStore } from "./store";
import strings from "~/resources/strings";
import { Table, TableCellWithActionButtons } from "~/components";
import { useHistory } from "~/hooks/useHistory";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import { showSuccessToast } from "~/resources/toast";

interface IIdentifier {
	id: string;
}

interface IProps<DataType> {
	initStoreSettings: TableModelStore<DataType>;
	header: string[];
	renderRow: (item: DataType) => JSX.Element;
	tableTitle?: string;
	hasEdit?: boolean;
	hasDetails?: boolean;
	hasDelete?: boolean;
	onSuccessMessage?: string;
	onAdd?: boolean;
}

export const TableModel = observer(<DataType extends IIdentifier,>(props: IProps<DataType>) => {
	const { dialog } = useGlobalStore();
	const {
		initStoreSettings,
		header,
		tableTitle,
		hasDelete,
		hasDetails,
		hasEdit,
		renderRow,
		onSuccessMessage,
		onAdd,
	} = props;

	const store = useLocalObservable(() => new Store(initStoreSettings));
	const history = useHistory();

	const showActionButtons = hasEdit || hasDelete || hasDetails;

	const onGoToCreate = () => {
		history.push("create");
	};

	const onGoToEdit = (id: string) => {
		history.push(`edit/${id}`);
	};

	const onGoToDetails = (id: string) => {
		history.push(`details/${id}`);
	};

	const openDialog = (item: DataType) => {
		const modal = strings.common.modal;
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(""),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => {
						store.deleteShelf.value?.deleteDataType(item.id);
						dialog.closeDialog();
						if (onSuccessMessage){
							showSuccessToast(onSuccessMessage);
						}
					},
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				onAdd={onAdd ? onGoToCreate : undefined}
				addButtonText={strings.common.buttons.add}
				tableTitle={tableTitle}
				headers={header}
				renderRow={(item, index) => (
					<Tr key={index}>
						{renderRow(item)}
						{showActionButtons && (
							<TableCellWithActionButtons
								{...hasDelete && { onDelete: () => openDialog(item) }}
								{...hasDetails && { onView: () => onGoToDetails(item.id) }}
								{...hasEdit && { onEdit: () => onGoToEdit(item.id) }}
								isRight
							/>
						)}
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
		</Flex>
	);
});
