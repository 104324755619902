import React from "react";
import { observer } from "mobx-react-lite";
import {
	Routes,
	Route,
	useLocation,
} from "react-router-dom";
import { useAuthRoute } from "~/hooks/useAuthRoute";
import AdminRoutes from "./AdminRoutes";
import {
	Loading,
	Login,
	NewPassword,
	Recovery,
} from "~/pages";
import api from "~/resources/api";
import { useHistory } from "~/hooks/useHistory";
import ClinicRoutes from "./ClinicRoutes";

const App = observer(() => {
	const history = useHistory();
	const location = useLocation();

	const onSuccess = (currentAdmin: api.AdminUser) => {
		const routesToBeRedirect = ["/", "/login"];
		const hasToBeRedirected = routesToBeRedirect.includes(location.pathname);

		if (hasToBeRedirected) {
			if (currentAdmin.clinic) {
				return history.push("/clinic");
			} else {
				return history.push("/admin");
			}
		}
	};

	useAuthRoute(onSuccess);

	return (
		<Routes>
			<Route
				index
				element={<Loading />}
			/>
			<Route
				path="/admin/*"
				element={<AdminRoutes />}
			/>
			<Route
				path="/clinic/*"
				element={<ClinicRoutes />}
			/>
			<Route
				path="/login"
				element={<Login />}
			/>
			<Route
				path="/recovery"
				element={<Recovery />}
			/>
			<Route
				path="/recovery/token/:token"
				element={<NewPassword />}
			/>
		</Routes>
	);
});

export default App;
