import { makeAutoObservable } from "mobx";
import {
	AttributeShelf,
	LoaderShelf,
	PaginatedListShelf,
} from "@startapp/mobx-utils";
import { FormShelf, ImagePickerShelf } from "@startapp/mobx-utils/src/web";
import api from "~/resources/api";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import { onFetchError } from "~/resources/fetchError";

export class VaccineShelf {
	public loader = new LoaderShelf();
	public id = new AttributeShelf<string>("");
	public nextVaccinationAt = new AttributeShelf<Date | null>(null);
	public appliedAt = new AttributeShelf<Date>(new Date());
	public expirationDateAt = new AttributeShelf<Date>(new Date());
	public manufacturingDateAt = new AttributeShelf<Date>(new Date());
	public code = new AttributeShelf<number | null>(null);
	public imageShelf = new ImagePickerShelf(api.uploadImage);
	public paginatedListShelfVaccinesByConsult: PaginatedListShelf<api.Vaccine>;
	public paginatedListShelfVaccinesByPet: PaginatedListShelf<api.Vaccine>;

	public formShelf = new FormShelf({
		name: "",
		batch: "",
	});

	constructor(id: string, type: "pet" | "consultation") {
		makeAutoObservable(this);
		this.id.setValue(id);
		if (type === "pet") {
			this.paginatedListShelfVaccinesByPet = new PaginatedListShelf(
				(page) => api.getAllVaccinesByPet(page, id),
				{
					fetchOnConstructor: true,
					onFetchError,
				},
			);
		}
		if (type === "consultation"){
			this.paginatedListShelfVaccinesByConsult = new PaginatedListShelf(
				(page) => api.getAllVaccinesByConsult(page, id),
				{
					fetchOnConstructor: true,
					onFetchError,
				},
			);
		}
	}

	public vaccineData = (): api.EditVaccine => {
		const data = this.formShelf.getValues();
		return {
			name: data.name,
			code: this.code.value || null,
			nextVaccinationAt: this.nextVaccinationAt.value || null,
			appliedAt: this.appliedAt.value,
			expirationDateAt: this.expirationDateAt.value,
			manufacturingDateAt: this.manufacturingDateAt.value,
			batch: Number(data.batch),
			image: this.imageShelf.uploadedImage || null,
		};
	};
	public clearVaccineFields = () => {
		const data = this.formShelf.getValues();
		this.nextVaccinationAt.setValue(null);
		this.appliedAt.setValue(new Date());
		this.code.setValue(null);
		return {
			name: data.name = "",
		};
	};

	public createVaccine = async (onSuccess: () => void) => {
		this.loader.start();
		try {
			await api.createVaccine(
				this.vaccineData(),
				this.id.value,
			);
			showSuccessToast(strings.pages.consultations.createVaccine.onSuccess);
			this.paginatedListShelfVaccinesByConsult.refresh();
			this.clearVaccineFields();
			onSuccess();
		} catch (e) {
			const error = Errors.treatError(e);
			if (error.type === api.ErrorType.Validation) {
				this.formShelf.fieldError.cleanAndAddErrors(JSON.parse((e as Error).message));
			} else {
				const errorMessage = Errors.handleError(e);
				showErrorToast(errorMessage);
			}
		} finally {
			this.loader.end();
		}
	};
}

