import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { Box, Button } from "@chakra-ui/react";
import {
	CentralizedCard,
	ImagePicker,
	Label,
	TextInput,
} from "~/components";
import strings from "~/resources/strings";
import { useHistory } from "~/hooks/useHistory";
import Store from "./store";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useGlobalStore } from "~/contexts/useGlobalContext";

const CreateOrEdit: React.FC = () => {
	const commonStrings = strings.common;
	const pageStrings = strings.pages.posts.createOrEdit;
	const { authStore } = useGlobalStore();

	const { id } = useParams<{ id: string }>();
	const history = useHistory();

	const store = useLocalObservable(() => new Store(id || "", !!authStore.currentAdminUser?.clinic));

	store.currentAdminType.setValue(authStore.currentAdminUser?.type);

	const onSucess = () => {
		history.goBack();
	};

	return (
		<CentralizedCard
			title={{
				text: pageStrings.title(!!id),
			}}
			button={(
				<Button
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={() => store.createOrEditPost(onSucess, store.methodForCreatePost)}
				>
					{commonStrings.buttons.saveOrEdit(!!id)}
				</Button>
			)}
			altPaddingForCard
		>
			<Box>
				<Label fontWeight="bold" color="gray.700" marginBottom={1}>
					{strings.components.imagePicker.selectAnImage}
				</Label>
				<ImagePicker
					pickImage={store.imageShelf.getPickerFields().pick}
					src={store.imageShelf.src}
				/>
			</Box>
			<TextInput
				labelText={commonStrings.fields.title}
				labelProps={{ fontWeight: "bold" }}
				variant="filled"
				placeholder={pageStrings.titlePlaceholder}
				labelColor="secondary"
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("title").error}
				{...store.formShelf.field("title")}
			/>
			<TextInput
				labelText={commonStrings.fields.slug}
				labelProps={{ fontWeight: "bold" }}
				variant="filled"
				labelColor="secondary"
				placeholder={pageStrings.slugPlaceholder}
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("slug").error}
				{...store.formShelf.field("slug")}
			/>
			<Box paddingBottom={45}>
				<Label fontWeight="bold" color="gray.700">{commonStrings.fields.text}</Label>
				<Box>
					<ReactQuill
						modules={store.editorModules}
						value={store.description.value}
						onChange={store.description.setValue}
						style={{ height: 200, paddingBottom: 40 }}
					/>
				</Box>
			</Box>
		</CentralizedCard>
	);
};

export default observer(CreateOrEdit);
