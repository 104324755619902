import React from "react";
import { observer } from "mobx-react-lite";
import {
	Box,
	Flex,
	HStack,
	StackProps,
	Text,
} from "@chakra-ui/react";

interface IProps extends StackProps {
	label: string;
	onClick?: () => void;
	isSelected?: boolean;
}

export const Radio: React.FC<IProps> = observer((props) => {
	const { label, onClick, isSelected, ...rest } = props;
	return (
		<HStack {...rest}>
			<Box
				w="20px"
				h="20px"
				borderColor="primary.500"
				borderWidth={1}
				borderRadius="50%"
				p="2px"
				onClick={onClick}
				cursor="pointer"
			>
				{isSelected && (
					<Flex
						borderRadius="50%"
						w="100%"
						h="100%"
						bgColor="gray.300"
					/>
				)}
			</Box>
			<Text>{label}</Text>
		</HStack>
	);
});

