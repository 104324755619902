import React from "react";
import { observer } from "mobx-react-lite";
import {
	Flex,
	Td,
	Tr,
} from "@chakra-ui/react";
import {
	Label,
	Table,
	TableCellWithActionButtons,
} from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";

interface IProps {
	exam: api.Exam[];
	previousPage: () => void;
	nextPage: () => void;
	page: number;
	isLoading: boolean;
	hasNextPage: boolean;
	openModal?: () => void;
	isClinic?: boolean;
	isFinished?: boolean;
	onGoToDetails: (id: string) => void;
}

export const TableOfExams: React.FC<IProps> = observer((props) => {
	const {
		exam,
		isLoading,
		page,
		nextPage,
		previousPage,
		hasNextPage,
		openModal,
		isFinished,
		isClinic,
		onGoToDetails,
	} = props;

	const pageStrings = strings.pages.consultations.tableExams;

	return (
		<Flex
			direction="column"
			w={{base:"95%",md:"100%"}}
			maxW={{
				base:"100%",
				md:"75%",
				lg:"70%",
			}}
			borderRadius={12}
			mb={5}
		>
			<Label
				mb={2}
				fontWeight="bold"
				color="teal.700"
			>
				{strings.pages.pets.details.exams}
			</Label>
			<Table
				maxHeight={{ base: 450, md: 600 }}
				data={exam}
				loading={isLoading}
				headers={pageStrings.header}
				isCard
				onAdd={!isFinished && isClinic ? openModal : undefined}
				boxShadow="xl"
				renderRow={(item, index) => (
					<Tr key={index}>
						<Td>{item.name}</Td>
						<Td>{item.type}</Td>
						<Td>{item.code}</Td>
						<TableCellWithActionButtons
							onView={() => onGoToDetails(item.id)}
						/>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={page}
				prevPage={previousPage}
				nextPage={nextPage}
				hasNextPage={hasNextPage}
			/>
		</Flex>
	);
});
