import React from "react";
import { observer } from "mobx-react-lite";
import {
	Flex,
	Td,
	Tr,
} from "@chakra-ui/react";
import {
	Label,
	Table,
	TableCellWithActionButtons,
} from "~/components";
import strings from "~/resources/strings";
import { useHistory } from "~/hooks/useHistory";
import api from "~/resources/api";

interface IProps {
	vaccines: api.Vaccine[];
	previousPage: () => void;
	nextPage: () => void;
	page: number;
	isLoading: boolean;
	hasNextPage: boolean;
	openModal?: () => void;
	isFinished?: boolean;
	isClinic?: boolean;
}

export const TableOfVaccines: React.FC<IProps> = observer((props) => {
	const {
		vaccines,
		isLoading,
		page,
		nextPage,
		previousPage,
		hasNextPage,
		openModal,
		isFinished,
		isClinic,
	} = props;

	const pageStrings = strings.pages.consultations.tableVaccines;
	const history = useHistory();
	const onGoToDetails = (id: string) => history.push(`/${isClinic ? "clinic" : "admin"}/consultations/details/vaccines/${id}`);
	return (
		<Flex
			direction="column"
			w={{base:"95%",md:"100%"}}
			maxW={{
				base:"100%",
				md:"75%",
				lg:"70%",
			}}
			borderRadius={12}
			mb={5}
		>
			<Label
				mb={2}
				fontWeight="bold"
				color="#606060"
			>
				{strings.pages.pets.details.vaccines}
			</Label>
			<Table
				data={vaccines}
				loading={isLoading}
				headers={pageStrings.header}
				isCard
				onAdd={!isFinished && isClinic ? openModal : undefined}
				boxShadow="xl"
				renderRow={(item, index) => (
					<Tr key={index}>
						<Td>{item.name}</Td>
						<Td>{item.code}</Td>
						<Td>{strings.format.date(item.appliedAt)}</Td>
						<Td>{strings.format.date(item.nextVaccinationAt) || pageStrings.noReturn}</Td>
						<Td>{strings.format.date(item.manufacturingDateAt)}</Td>
						<Td>{strings.format.date(item.expirationDateAt)}</Td>
						<Td>{item.batch}</Td>
						<TableCellWithActionButtons
							onView={() => onGoToDetails(item.id)}
						/>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={page}
				prevPage={previousPage}
				nextPage={nextPage}
				hasNextPage={hasNextPage}
			/>
		</Flex>
	);
});
