import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {
	Button,
	Flex,
	Heading,
} from "@chakra-ui/react";
import {
	CentralizedCard,
	ClinicAdressForm,
	ImagePicker,
	Label,
	TextInput,
} from "~/components";
import strings from "~/resources/strings";
import { useHistory } from "~/hooks/useHistory";
import Store from "./store";

const CreateOrEdit: React.FC = () => {
	const { id } = useParams<{id: string}>();
	const history = useHistory();
	const store = useLocalObservable(() => new Store(id || ""));
	const pageStrings = strings.common.fields;
	const commonStrings = strings.common;

	const onSucess = () => {
		history.goBack();
	};

	return (
		<CentralizedCard
			button={(
				<Button
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={() => store.createOrEditClinic(onSucess)}
				>
					{commonStrings.buttons.saveOrEdit(!!id)}
				</Button>
			)}
		>
			<>
				<Heading fontSize="md" color="primary.500">{commonStrings.clinicCreate}</Heading>
				<TextInput
					labelText={pageStrings.clinicName}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					type="text"
					isDisabled={store.loader.isLoading}
					errorText={store.formShelf.field("name").error}
					{...store.formShelf.field("name")}
				/>
				<TextInput
					labelText={pageStrings.documentNumber}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					type="text"
					isDisabled={store.loader.isLoading}
					mask="99.999.999/9999-99"
					errorText={store.formShelf.field("documentNumber").error}
					{...store.formShelf.field("documentNumber")}
				/>
				<TextInput
					labelText={pageStrings.phone}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					type="text"
					isDisabled={store.loader.isLoading}
					mask="(99) 99999-9999"
					errorText={store.formShelf.field("phone").error}
					{...store.formShelf.field("phone")}
				/>
				<TextInput
					labelText={pageStrings.email}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					type="text"
					isDisabled={store.loader.isLoading}
					errorText={store.formShelf.field("email").error}
					{...store.formShelf.field("email")}
				/>
				{!id && (
					<TextInput
						labelText={pageStrings.password}
						labelProps={{ fontWeight: "bold" }}
						variant="filled"
						type="password"
						isDisabled={store.loader.isLoading}
						errorText={store.formPassword.field("password").error}
						{...store.formPassword.field("password")}
					/>
				)}
				<Heading fontSize="md" color="primary.500">{pageStrings.address}</Heading>
				<ClinicAdressForm
					formValues={{
						field: store.formShelf.field,
					}}
					isLoading={store.loader.isLoading}
					state={store.state.value}
					onChangeEnum={store.state.setValue}
				/>
				<Flex direction="column">
					<Label
						fontWeight="bold"
						marginBottom={1}
						color="primary.500"
					>
						{strings.components.imagePicker.selectAnImage}
					</Label>
					<ImagePicker
						pickImage={store.imageShelf.getPickerFields().pick}
						src={store.imageShelf.src}
					/>
				</Flex>
			</>
		</CentralizedCard>
	);
};

export default observer(CreateOrEdit);
