import React from "react";
import { observer } from "mobx-react-lite";
import {
	Divider,
	Flex,
	Grid,
	StackProps,
} from "@chakra-ui/react";
import {
	DatePicker,
	TimePicker,
	TextInput,
	ImagePicker,
	ModalComponent,
	Label,
	SelectedHours,
	DocumentAttach,
} from "~/components";
import { AdministeredMedicationShelf } from "~/shelves/AdministeredMedicationShelf";
import strings from "~/resources/strings";

interface IProps extends StackProps {
	administeredMedicationShelf: AdministeredMedicationShelf;
	isOpen: boolean;
	onClose: () => void;
	onCreate: () => void;
}

export const AdministeredMedicationFormModal: React.FC<IProps> = observer((props) => {
	const {
		administeredMedicationShelf,
		onCreate,
		isOpen,
		onClose,
	} = props;
	const commonStrings = strings.common;

	const getHours = (date: Date) => {
		administeredMedicationShelf.selectedHour.setValue(date);
		administeredMedicationShelf.consumptionHours.setValue([
			...administeredMedicationShelf.consumptionHours.value,
			administeredMedicationShelf.selectedHour.value]);
	};

	return (
		<ModalComponent
			buttonText={commonStrings.create}
			title={strings.common.modal.titleMedication}
			isOpen={isOpen}
			onClose={onClose}
			onClick={()=> onCreate()}
		>
			<Flex direction="column" gap={5}>
				<Label fontWeight="bold">
					{strings.components.imagePicker.selectAnImage}
				</Label>
				<ImagePicker
					src={administeredMedicationShelf.imageShelf.uploadedImage?.url || "/pick_image.svg"}
					pickImage={administeredMedicationShelf.imageShelf.getPickerFields().pick}
				/>
				<Grid
					gridTemplateColumns={{
						base: "1fr",
						md: "repeat(2,1fr)",
					}}
					gap={{
						sm:0,
						md:3,
					}}
				>
					<TextInput
						labelText={commonStrings.fields.name}
						labelProps={{ fontWeight: "bold" }}
						variant="filled"
						type="text"
						isDisabled={administeredMedicationShelf.loader.isLoading}
						errorText={administeredMedicationShelf.formShelf.field("name").error}
						{...administeredMedicationShelf.formShelf.field("name")}
					/>
					<TextInput
						labelText={commonStrings.fields.code}
						labelProps={{ fontWeight: "bold" }}
						variant="filled"
						type="number"
						isDisabled={administeredMedicationShelf.loader.isLoading}
						errorText={administeredMedicationShelf.formShelf.field("code").error}
						{...administeredMedicationShelf.formShelf.field("code")}
					/>
					<TextInput
						labelText={commonStrings.fields.dosage}
						labelProps={{ fontWeight: "bold" }}
						variant="filled"
						type="text"
						isDisabled={administeredMedicationShelf.loader.isLoading}
						errorText={administeredMedicationShelf.formShelf.field("dosage").error}
						{...administeredMedicationShelf.formShelf.field("dosage")}
					/>
				</Grid>
				<DocumentAttach
					title={commonStrings.fields.pdf}
					pickFile={administeredMedicationShelf.pdf.getPickerFields().pick}
					fileName={administeredMedicationShelf.pdf.localFile.value?.name || null}
					onDelete={administeredMedicationShelf.pdf.getPickerFields().clear}
					url={administeredMedicationShelf.pdf.uncertainfiedFile?.url || null}
				/>
				<Divider my={2} height="1px" bg="primary.500" />
				<Grid
					gridTemplateColumns={{
						base: "1fr",
						md:"repeat(2,1fr)",
					}}
					gap={5}
				>
					<DatePicker
						color="gray.800"
						label={commonStrings.fields.startConsumptionDate}
						onChangeDate={administeredMedicationShelf.startConsumptionDate.setValue}
						selectedDate={administeredMedicationShelf.startConsumptionDate.value}
						inputProps={{ h: "40px", textAlign: "center" }}
					/>
					<DatePicker
						color="gray.800"
						label={commonStrings.fields.endConsumptionDate}
						onChangeDate={administeredMedicationShelf.endConsumptionDate.setValue}
						selectedDate={administeredMedicationShelf.endConsumptionDate.value}
						inputProps={{ h: "40px", textAlign: "center" }}
					/>
					<TimePicker
						color="gray.800"
						label={commonStrings.fields.consumptionHours}
						onChangeDate={getHours}
						selectedDate={administeredMedicationShelf.selectedHour.value}
						inputProps={{ h: "40px", textAlign: "center" }}
						w="100%"
					/>
					<Flex>
						<SelectedHours value={administeredMedicationShelf.consumptionHours.value} />
					</Flex>
				</Grid>
			</Flex>
		</ModalComponent>
	);
});

