import React from "react";
import { observer } from "mobx-react-lite";
import {
	FormControlProps,
	Flex,
	Image,
	Box,
} from "@chakra-ui/react";
import { AiFillDelete } from "react-icons/ai";

interface IProps extends FormControlProps {
	src: string;
	onRemove: () => void;
}

export const SelectedImages: React.FC<IProps> = observer((props) => {
	const { src, onRemove } = props;

	return (
		<Flex position="relative" >
			<Image
				h={120}
				w={120}
				p={0}
				m={0}
				src={src}
				rounded="lg"
			/>
			<Box
				cursor="pointer"
				position="absolute"
				top="1"
				right="1"
				borderRadius="50%"
				bg="#f2f2f2"
				p={1}
				transition=".3s"
				_hover={{background:"gray.300"}}
			>
				<AiFillDelete
					size={15}
					onClick={onRemove}
					color="gray.800"
				/>
			</Box>
		</Flex>
	);

});
