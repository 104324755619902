import React from "react";
import { observer } from "mobx-react-lite";
import {
	Flex,
	Center,
	Image,
	Modal,
	Tooltip,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	IconButton,
	Text,
	useDisclosure,
	useBreakpointValue,
} from "@chakra-ui/react";
import {
	ArrowLeftIcon,
	ArrowRightIcon,
} from "@chakra-ui/icons";
import strings from "~/resources/strings";
import imagePlaceholder from "../../../static/pick_image.svg";

export interface IProps {
	imageUrls: string[] | null;
}
export const ImageViewer: React.FC<IProps> = observer((props) => {
	const { imageUrls } = props;

	const componentStrings = strings.components.imageViewer;

	const modalSize = useBreakpointValue(["full", "xl"]);

	const [selectedIndex, setSelectedIndex] = React.useState(0);

	const onClickPrevious = () => {
		const lastIndex = imageUrls ? imageUrls.length - 1 : 0;
		const newIndex = selectedIndex === 0 ? lastIndex : selectedIndex - 1;
		setSelectedIndex(newIndex);
	};

	const onClickNext = () => {
		const lastIndex = imageUrls ? imageUrls.length - 1 : 0;
		const newIndex = selectedIndex === lastIndex ? 0 : selectedIndex + 1;
		setSelectedIndex(newIndex);
	};

	const {
		isOpen: isModalOpen,
		onOpen: openModal,
		onClose: closeModal,
	} = useDisclosure();

	return (
		<>
			<Flex
				wrap="wrap"
				height="80px"
				overflow="hidden"
				gap={3}
				w="100%"
			>
				{imageUrls && imageUrls.map((item, index) => (
					<Tooltip key={index} label={strings.components.tooltip}>
						<Image
							w="120px"
							objectPosition="center"
							p={0}
							m={0}
							src={item}
							onClick={imageUrls.length === 0 ? undefined : openModal}
							fallbackSrc={imagePlaceholder}
							cursor="pointer"
						/>
					</Tooltip>
				))}
			</Flex>
			<Modal isOpen={isModalOpen}
				onClose={closeModal}
				size={modalSize}
				scrollBehavior="inside"
			>
				<ModalOverlay />
				<ModalContent h="100%" w="100%" bg="gray.900">
					<ModalHeader bg="gray.900" >
						<ModalCloseButton onClick={closeModal} color="white" size="lg" />
					</ModalHeader>
					<ModalBody m={0} p={0} bg="gray.900">
						<Center
							position="relative"
							h="100%"
							w="100%"
							p={0}
							m={0}
							overflow="hidden"
						>
							<Image
								h="100%"
								w="100%"
								objectFit="contain"
								src={imageUrls ? imageUrls[selectedIndex] : ""}
								fallbackSrc={imagePlaceholder}
								p={0}
								m={0}
							/>
							<IconButton
								onClick={onClickPrevious}
								aria-label={componentStrings.previous}
								bg="transparent"
								position="absolute"
								top="50%"
								left={0}
								w="20%"
								maxW="80px"
								_focus={{boxShadow: "none"}}
								icon={
									<ArrowLeftIcon
										h="25px"
										w="25px"
										color="white"
										boxShadow="lg"
									/>
								}
							/>
							<IconButton
								onClick={onClickNext}
								aria-label={componentStrings.next}
								bg="transparent"
								position="absolute"
								top="50%"
								right={0}
								w="20%"
								maxW="80px"
								_focus={{boxShadow: "none"}}
								icon={
									<ArrowRightIcon
										h="25px"
										w="25px"
										color="white"
										boxShadow="lg"
									/>
								}
							/>
						</Center>
					</ModalBody>
					<ModalFooter py={0} m={0} bg="gray.900">
						<Text color="white" fontSize="md">
							{componentStrings.imageCount(selectedIndex + 1, imageUrls ? imageUrls.length : 0)}
						</Text>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
});
