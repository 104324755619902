import React from "react";
import { observer } from "mobx-react-lite";
import {
	Flex,
	Tooltip,
} from "@chakra-ui/react";
import { Loading } from "../Loading";
import strings from "../../resources/strings";

export interface IProps {
	pickImage?: () => void;
	src: string | null;
	loading?: boolean;
}
export const PdfPicker: React.FC<IProps> = observer((props) => {
	const {
		pickImage,
		src,
		loading,
	} = props;
	const componentStrings = strings.components.pdfPicker;

	return (
		<>
			<Flex
				direction="column"
				mr={4}
			>
				<Flex
					width={200}
					position="relative"
					overflow="hidden"
					mb={2}
					mt={4}
					color="primary.500"
				>
					<Tooltip label={componentStrings.selectAnPdf}>
						<Flex
							cursor="pointer"
							border="3px solid"
							borderColor="primary.500"
							rounded="xl"
							p={2}
							fontWeight="bold"
							onClick={pickImage}
							__css={{ filter: loading && "brightness(0.5)" }}
						>
							{src ? src : strings.components.pdfPicker.selectAnDocument}
						</Flex>
					</Tooltip>
					{loading && (
						<Loading
							position="absolute"
						/>
					)}
				</Flex>
			</Flex>
		</>
	);
});
