import { makeAutoObservable } from "mobx";
import {
	AttributeShelf,
	LoaderShelf,
	PaginatedListShelf,
} from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";

export class HospitalizationShelf {
	public loader = new LoaderShelf();
	public id = new AttributeShelf<string>("");
	public endDate = new AttributeShelf<Date | null>(null);
	public startDate = new AttributeShelf<Date | null>(null);
	public paginatedListShelfHospitalizationsByPet: PaginatedListShelf<api.Hospitalization | api.HospitalizationForClinic>;

	constructor(petId: string, method: (page: number, petId: string) => Promise<api.Hospitalization[] | api.HospitalizationForClinic[]>) {
		makeAutoObservable(this);
		if (petId) {
			this.paginatedListShelfHospitalizationsByPet = new PaginatedListShelf(
				(page) => method(page, petId),
				{
					fetchOnConstructor: true,
					onFetchError,
				},
			);
		}
	}

	public get filterOptions () {
		return {
			startDate: this.startDate.value,
			endDate: this.endDate.value,
		};
	}
}

