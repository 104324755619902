import React from "react";
import {
	Flex,
	useRadioGroup,
	Text,
	useMediaQuery,
} from "@chakra-ui/react";
import { RadioCard } from "~/components";

interface IDataPeriod {
	title: string;
	description: string;
	onClick: () => void;
}
interface IProps {
	items: IDataPeriod[];
}

export const PeriodGroup: React.FC<IProps> = (props) => {
	const { items } = props;
	const { getRootProps, getRadioProps } = useRadioGroup({
		defaultValue: "",
	});
	const group = getRootProps();
	const [isSm] = useMediaQuery("(min-width: 700px)");

	return (
		<Flex
			overflowX={{ base: "auto", xl: "unset" }}
			mx={{
				base:4,
				xl:"auto",
			}}
			{...group}
			gap={2}
			p={2}
			mt={10}
		>
			{items.map((item, index) => {
				const radioProps = getRadioProps({ value: item.title });
				return (
					<RadioCard
						key={`${index}${item.title}`}
						radioProps={radioProps}
						onClick={item.onClick}
						boxProps={{
							w: 168,
							mr: 2,
							mt: 2,
							borderRadius: 6,
						}}
					>
						<Text
							fontSize="md"
							textAlign={isSm ? "center" : "initial"}
						>
							{item.title}
						</Text>
						{isSm && (
							<Text
								fontSize="md"
								fontWeight="bold"
								textAlign="center"
								mt={4}
							>
								{item.description}
							</Text>
						)}
					</RadioCard>
				);
			})}
		</Flex>
	);
};
