import { makeAutoObservable } from "mobx";
import { FetchModelShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";

export class Store {

	public fetchModelShelf: FetchModelShelf<api.UserWithPets>;
	public loader = new LoaderShelf();

	constructor(id: string) {
		makeAutoObservable(this);
		this.fetchModelShelf = new FetchModelShelf(id, () => api.getUser(id),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
	}
}
