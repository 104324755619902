import React from "react";
import { observer } from "mobx-react-lite";

import {
	ModalHeader,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalFooter,
	ModalBody,
	VStack,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import { DatePicker } from "..";

export interface IProps  {
	startInput: {
		label: string;
		selectedDate: Date | null;
		onChange: (date: Date) => void;
		isDisabled: boolean;
	};
	endInput: {
		label: string;
		selectedDate: Date | null;
		onChange: (date: Date) => void;
		isDisabled: boolean;
	};
	isOpen: boolean;
	title?: string;
	closeOnOverlayClick?: boolean;
	onClose: () => void;
	onSubmit: () => void;
}

export const DateModal: React.FC<IProps> = observer((props) => {
	const {
		startInput,
		endInput,
		onClose,
		isOpen,
		title,
		closeOnOverlayClick,
		onSubmit,
	} = props;

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			closeOnOverlayClick={closeOnOverlayClick}
			isCentered
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader color="primary.500">{title}</ModalHeader>
				<ModalBody color="secondary.500">
					<VStack>
						<DatePicker
							label={startInput.label}
							selectedDate={startInput.selectedDate}
							onChangeDate={startInput.onChange}
							isDisabled={startInput.isDisabled}
						/>
						<DatePicker
							label={endInput.label}
							selectedDate={endInput.selectedDate}
							onChangeDate={endInput.onChange}
							isDisabled={endInput.isDisabled}
						/>
					</VStack>
				</ModalBody>

				<ModalFooter gap={3}>
					<Button
						colorScheme="primary"
						fontSize={{ base: "sm", sm: "md" }}
						onClick={onSubmit}
					>
						{strings.actions.filter}
					</Button>
					<Button
						variant="outline"
						colorScheme="primary"
						fontSize={{ base: "sm", sm: "md" }}
						onClick={onClose}
					>
						{strings.actions.cancel}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);

});

