import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { Box, Button } from "@chakra-ui/react";
import {
	CalendarPicker,
	CentralizedCard,
	EnumSelect,
	ImagePicker,
	Label,
	RadioBoolean,
	TextInput,
} from "~/components";
import strings from "~/resources/strings";
import Store from "./store";
import { useHistory } from "~/hooks/useHistory";
import api from "~/resources/api";

const Edit: React.FC = () => {
	const { id } = useParams<{id: string}>();
	const history = useHistory();
	const store = useLocalObservable(() => new Store(id));
	const pageStrings = strings.pages.pets.edit;
	const commonStrings = strings.common;

	const onSucess = () => {
		history.goBack();
	};

	return (
		<CentralizedCard
			title={{ text: pageStrings.title }}
			button={(
				<Button
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={() => store.editPet(onSucess)}
				>
					{commonStrings.buttons.saveOrEdit(!!id)}
				</Button>
			)}
		>
			<Box>
				<Label fontWeight="bold" marginBottom={1}>
					{strings.components.imagePicker.selectAnImage}
				</Label>
				<ImagePicker
					pickImage={store.imageShelf.getPickerFields().pick}
					src={store.imageShelf.src}
				/>
			</Box>
			<TextInput
				labelText={commonStrings.fields.name}
				labelProps={{ fontWeight: "bold" }}
				labelColor="primary"
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("name").error}
				{...store.formShelf.field("name")}
			/>
			<TextInput
				labelText={commonStrings.fields.age}
				labelProps={{ fontWeight: "bold" }}
				labelColor="primary"
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("age").error}
				{...store.formShelf.field("age")}
			/>
			<TextInput
				labelText={commonStrings.fields.breed}
				labelProps={{ fontWeight: "bold" }}
				labelColor="primary"
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("breed").error}
				{...store.formShelf.field("breed")}
			/>
			<EnumSelect
				items={api.allValuesAnimalSex()}
				currentValue={store.animalSex.value}
				label={commonStrings.fields.animalSex}
				onChangeEnum={store.animalSex.setValue}
				tranlateEnum={api.translateAnimalSex}
			/>
			<EnumSelect
				items={api.allValuesSpecies()}
				currentValue={store.species.value}
				label={commonStrings.fields.breed}
				onChangeEnum={store.species.setValue}
				tranlateEnum={api.translateSpecies}
			/>
			<CalendarPicker
				label={commonStrings.fields.birthdate}
				onChange={store.birthdate.setValue}
				value={store.birthdate.value}
				isOutiline
			/>
			<RadioBoolean label={commonStrings.fields.isCastrated} attributeShelf={store.isCastrated} />
		</CentralizedCard>
	);
};

export default observer(Edit);
