import React from "react";
import RDatePicker from "react-datepicker";
import {
	Input,
	InputProps,
	FormLabel,
	FormControl,
	FormControlProps,
	Flex,
} from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR } from "date-fns/locale";
import { InputGroup } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import format from "~/resources/format";
import { BiTime } from "react-icons/bi";

interface IProps extends FormControlProps {
	onChangeDate: (date: Date) => void;
	selectedDate: Date | null;
	isDisabled?: boolean;
	minDate?: Date;
	inputProps?: InputProps;
	label?: string;
	color?: string;
}

export const TimePicker: React.FC<IProps> = observer((props) => {
	const {
		onChangeDate,
		selectedDate,
		inputProps,
		minDate,
		label,
		isDisabled,
		color,
		...rest
	} = props;

	return (
		<FormControl cursor="pointer" isDisabled={isDisabled} {...rest}>
			{label && (
				<FormLabel
					fontSize="sm"
					fontWeight="bold"
					color={color ? color : "primary.500"}
					noOfLines={1}
				>
					{label}
				</FormLabel>
			)}

			<RDatePicker
				locale={ptBR}
				showTimeSelect
				showTimeSelectOnly
				dateFormat="dd/MM/yyyy"
				selected={selectedDate}
				timeIntervals={15}
				minDate={minDate}
				onChange={onChangeDate}
				disabled={isDisabled}
				timeCaption="Time:"
				customInput={
					<InputGroup>
						<Flex alignItems="center">
							<Input
								minWidth="180"
								color="gray.600"
								value={selectedDate ? format.hour(selectedDate) : ""}
								letterSpacing="2px"
								readOnly
								cursor="pointer"
								{...inputProps}
								marginRight={2}
							/>
							<BiTime color="#33AD49" size="25px" />
						</Flex>
					</InputGroup>
				}
			/>
		</FormControl>
	);

});
