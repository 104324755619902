import React from "react";
import { observer } from "mobx-react-lite";
import { Grid, Heading } from "@chakra-ui/react";
import { TextInput } from "../TextInput";
import strings from "~/resources/strings";
import { ConsultationShelf } from "~/shelves/ConsultationShelf";

interface IProps {
	consultationShelf: ConsultationShelf;
}

export const FluidtherapyForm: React.FC<IProps> = observer((props) => {
	const commonStrings = strings.common;
	const { consultationShelf } = props;

	return (
		<>
			<Heading fontSize="md" color="primary.500">{strings.common.fields.fluidtherapy}</Heading>
			<Grid
				gridTemplateColumns={{
					base: "1fr",
					md: "repeat(2,1fr)",
				}}
				gap={{
					sm:3,
					md:6,
				}}
			>
				<TextInput
					labelText={commonStrings.fields.solution}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					type="text"
					isDisabled={consultationShelf.loader.isLoading}
					errorText={consultationShelf.formShelfAnamnese.field("solution").error}
					{...consultationShelf.formShelfAnamnese.field("solution")}
				/>
				<TextInput
					labelText={commonStrings.fields.fluidtherapyRate}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					isDisabled={consultationShelf.loader.isLoading}
					errorText={consultationShelf.formShelfAnamnese.field("fluidtherapyRate").error}
					{...consultationShelf.formShelfAnamnese.field("fluidtherapyRate")}
				/>
				<TextInput
					labelText={commonStrings.fields.velocity}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					isDisabled={consultationShelf.loader.isLoading}
					errorText={consultationShelf.formShelfAnamnese.field("velocity").error}
					{...consultationShelf.formShelfAnamnese.field("velocity")}
				/>
			</Grid>
		</>
	);
});
