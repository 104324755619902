import React from "react";
import { observer } from "mobx-react-lite";
import { Flex, StackProps } from "@chakra-ui/react";
import strings from "~/resources/strings";
import { ExamShelf } from "~/shelves/ExamShelf";
import {
	ImagePicker,
	Label,
	TextInput,
	ModalComponent,
	DocumentAttach,
} from "~/components";

interface IProps extends StackProps {
	examShelf: ExamShelf;
	isOpen: boolean;
	onClose: () => void;
	onCreate: () => void;
}

export const ExamFormModal: React.FC<IProps> = observer((props) => {
	const {
		examShelf,
		onClose,
		isOpen,
		onCreate,
	} = props;
	const commonStrings = strings.common;

	return (
		<ModalComponent
			buttonText={commonStrings.create}
			title={strings.common.modal.titleCreateExam}
			onClick={()=> onCreate()}
			isOpen={isOpen}
			onClose={onClose}
			gap={5}
		>
			<Flex direction="column" mb={2}>
				<Label
					marginBottom={1}
					fontWeight="bold"
					fontSize="md"
					color="gray.800"
					mb={2}
				>
					{strings.components.imagePicker.selectAnImage}
				</Label>
				<ImagePicker
					src={examShelf.imageShelf.uploadedImage?.url || "/pick_image.svg"}
					pickImage={examShelf.imageShelf.getPickerFields().pick}
				/>
			</Flex>

			<Flex direction="column" gap={3}>
				<TextInput
					mt={2}
					labelText={commonStrings.fields.name}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					type="text"
					isDisabled={examShelf.loader.isLoading}
					errorText={examShelf.formShelf.field("name").error}
					{...examShelf.formShelf.field("name")}
				/>
				<TextInput
					labelText={commonStrings.fields.type}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					type="text"
					isDisabled={examShelf.loader.isLoading}
					errorText={examShelf.formShelf.field("type").error}
					{...examShelf.formShelf.field("type")}
				/>
				<TextInput
					labelText={commonStrings.fields.code}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					type="number"
					isDisabled={examShelf.loader.isLoading}
					onChange={(e)=> examShelf.code.setValue(Number(e.target.value))}
				/>
				<DocumentAttach
					title={commonStrings.fields.pdf}
					pickFile={examShelf.pdf.getPickerFields().pick}
					fileName={examShelf.pdf.localFile.value !== null ? examShelf.pdf.localFile.value.name : null}
					onDelete={examShelf.pdf.getPickerFields().clear}
					url={examShelf.pdf.uncertainfiedFile?.url || null}
				/>
			</Flex>
		</ModalComponent>
	);
});

