import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Flex,
	Tr,
	Td,
} from "@chakra-ui/react";
import { useHistory } from "~/hooks/useHistory";
import strings from "~/resources/strings";
import {
	ReprovePostModal,
	Table,
	TableCellWithActionButtons,
} from "~/components";
import format from "~/resources/format";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import api from "~/resources/api";
import { Store } from "./store";

const TableView: React.FC = () => {
	const store = useLocalObservable(() => new Store());
	const pageStrings = strings.pages.pendingPost;
	const { dialog } = useGlobalStore();
	const history = useHistory();
	const [isOpen, setIsOpen] = React.useState(false);
	const onGoToDetails = (id: string) => history.push(`details/${id}`);

	const openDialog = (post: api.PostWithoutDescription) => {
		const modal = strings.common.modal;
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(post.title),
			buttons: [
				{
					title: modal.button.approve,
					onPress: () => {
						store.pendingPostShelf.approvePost(post.id, () => store.paginetedListShelf.refresh());
						dialog.closeDialog();
					},
					buttonProps: { bg: "green.500" },
				},
				{
					title: modal.button.refuse,
					onPress: () => {
						setIsOpen(true);
						store.postId.setValue(post.id);
						dialog.closeDialog();
					},
					outlined: true,
				},
			],
		});
	};
	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				tableTitle={pageStrings.title}
				headers={pageStrings.headers}
				renderRow={(item, index) => (
					<Tr key={index}>
						<Td>{item.title}</Td>
						<Td>{item.slug}</Td>
						<Td>{item.adminUser?.name}</Td>
						<Td>{item.adminUser?.type}</Td>
						<Td>{format.date(item.createdAt)}</Td>
						<TableCellWithActionButtons
							onApprove={() => openDialog(item)}
							onView={() => onGoToDetails(item.id)}
						/>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
			<ReprovePostModal
				formValues={store.pendingPostShelf.formShelf}
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				onReprove={() => store.pendingPostShelf.refusePost(
					()=> setIsOpen(false),
					store.postId.value,
				)}
			/>
		</Flex>
	);
};

export default observer(TableView);
