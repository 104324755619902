import React from "react";
import { useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	IconButton,
	Tooltip,
	Flex,
	Box,
} from "@chakra-ui/react";

import {
	ButtonBack,
	CentralizedCard,
	DetailsRow,
	ImageWithModal,
} from "~/components";
import { Store } from "./store";
import strings from "~/resources/strings";
import { DocumentLink } from "~/components/DocumentLink";

const DetailsExam: React.FC = () => {
	const commonStrings = strings.common;
	const { id } = useParams<"id">();
	const store = useLocalObservable(() => new Store(id || ""));

	return (
		<Flex
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			w="100%"
		>
			<CentralizedCard
				title={{
					text: commonStrings.detailsTitle,
					helper: (
						<Tooltip label={strings.common.edit}>
							<IconButton
								variant="icon"
								aria-label="Voltar"
								size="lg"
							/>
						</Tooltip>
					),
				}}
				isLoading={store.fetchModelShelf.initialLoader.isLoading}
				altMaxWidth
			>
				{
					store.fetchModelShelf.model.value &&
						<>
							<Flex
								w="100%"
								direction="column"
								gap={5}
							>
								<DetailsRow
									label={commonStrings.fields.examName}
									value={store.fetchModelShelf.fetchedModel.name}
									isOutline
								/>
								<DetailsRow
									label={commonStrings.fields.examType}
									value={store.fetchModelShelf.fetchedModel.type}
									isOutline
								/>
								<DetailsRow
									label={commonStrings.fields.examCode}
									value={store.fetchModelShelf.fetchedModel.code}
									isOutline
								/>
								<DetailsRow
									label={commonStrings.fields.createAt}
									value={strings.format.date(store.fetchModelShelf.fetchedModel.createdAt)}
									isOutline
								/>
								<Box>
									<ImageWithModal label={commonStrings.fields.photo} src={store.fetchModelShelf.fetchedModel.image?.url} />
								</Box>
								<DocumentLink pdf={store.fetchModelShelf.model.value.pdf} />
							</Flex>
						</>
				}
			</CentralizedCard>
			<ButtonBack />
		</Flex>
	);
};

export default observer(DetailsExam);
