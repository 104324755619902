import { makeAutoObservable } from "mobx";
import {
	AttributeShelf,
	FetchModelShelf,
	LoaderShelf,
} from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import { Errors } from "~/resources/errors";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";
import { ConsultationShelf } from "~/shelves/ConsultationShelf";
import { ExamShelf } from "~/shelves/ExamShelf";
import { AdministeredMedicationShelf } from "~/shelves/AdministeredMedicationShelf";

export class Store {
	public fetchModelShelf: FetchModelShelf<api.Hospitalization | api.HospitalizationForClinic>;
	public consultationShelf = new ConsultationShelf();
	public examShelf: ExamShelf;
	public administeredMedicationShelf: AdministeredMedicationShelf;
	public loader = new LoaderShelf();
	public id = new AttributeShelf<string>("");

	constructor(id: string, method: (id: string) => Promise<api.Hospitalization | api.HospitalizationForClinic>) {
		makeAutoObservable(this);
		this.fetchModelShelf = new FetchModelShelf(id, method,
			{
				onFetchError,
			},
		);
		if (id) {
			this.examShelf = new ExamShelf(id, "hospitalization");
			this.administeredMedicationShelf = new AdministeredMedicationShelf(id, "hospitalization");
			this.id.setValue(id);
			this.fetchModelShelf.fetchModel();
		}
	}
	public finishHospitalization = async (id: string, onSuccess: () => void) => {
		this.loader.tryStart();
		try {
			await api.finishHospitalization(id);
			onSuccess();
			showSuccessToast(strings.pages.hospitalization.details.finish);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
