import TableView from "./TableView";
import Details from "./Details";
import Edit from "./Edit";

export const Pets = {
	TableView,
	Details,
	Edit,
};

