import React from "react";
import { observer } from "mobx-react-lite";
import { Routes, Route } from "react-router-dom";
import { MainLayout } from "~/layout";
import {
	Posts,
	Pets,
	Users,
	Vets,
	Consultations,
	Hospitalization,
	ReprovedPosts,
	HomePage,
} from "~/pages";
import { useAuthAdminClinicRoute } from "~/hooks/useAuthAdminClinicRoute";
import { useGlobalStore } from "~/contexts/useGlobalContext";

const ClinicRoutes = observer(() => {
	useAuthAdminClinicRoute();
	const { authStore } = useGlobalStore();

	return (
		<MainLayout>
			<Routes>
				<Route
					path="/"
					element={<HomePage isClinic />}
				/>
				<Route path="users">
					<Route
						index
						element={<Users.TableView />}
					/>
					<Route
						path="details/:id"
						element={<Users.Details isClinic />}
					/>
					<Route
						path="create/:id"
						element={<Users.Create />}
					/>
				</Route>
				<Route path="pets">
					<Route
						index
						element={<Pets.TableView clinicId={authStore.currentAdminUser?.clinic?.id} />}
					/>
					<Route
						path="edit/:id"
						element={<Pets.Edit />}
					/>
					<Route
						path="details/:id"
						element={<Pets.Details isClinic />}
					/>
					<Route
						path="details/exam/:id"
						element={<Consultations.DetailsExam />}
					/>
					<Route
						path="details/detailsAdministredMedication/:id"
						element={<Consultations.DetailsAdministredMedication />}
					/>
					<Route
						path="details/vaccines/:id"
						element={<Consultations.DetailsVaccine />}
					/>
					<Route
						path="details/vaccines/:id"
						element={<Consultations.DetailsVaccine />}
					/>
				</Route>
				<Route path="consultations">
					<Route
						index
						element={<Consultations.TableView isClinic />}
					/>
					<Route
						path="create"
						element={<Consultations.CreateOrEdit />}
					/>
					<Route
						path="details/:id"
						element={<Consultations.Details isClinic />}
					/>
					<Route
						path="details/exam/:id"
						element={<Consultations.DetailsExam />}
					/>
					<Route
						path="details/detailsAdministredMedication/:id"
						element={<Consultations.DetailsAdministredMedication />}
					/>
					<Route
						path="details/vaccines/:id"
						element={<Consultations.DetailsVaccine />}
					/>
				</Route>
				<Route path="posts">
					<Route
						index
						element={<Posts.TableView />}
					/>
					<Route
						path="create"
						element={<Posts.CreateOrEdit />}
					/>
					<Route
						path="edit/:id"
						element={<Posts.CreateOrEdit />}
					/>
				</Route>
				<Route path="vets">
					<Route
						index
						element={<Vets.TableView isClinic />}
					/>
					<Route
						path="create"
						element={<Vets.Create />}
					/>
				</Route>
				<Route path="hospitalization">
					<Route
						index
						element={<Hospitalization.TableView isClinic />}
					/>
					<Route
						path="create"
						element={<Hospitalization.Create isClinic />}
					/>
					<Route
						path="details/:id"
						element={<Hospitalization.Details isClinic />}
					/>
					<Route
						path="details/exam/:id"
						element={<Hospitalization.DetailsExam />}
					/>
					<Route
						path="details/detailsAdministredMedication/:id"
						element={<Hospitalization.DetailsAdministredMedication />}
					/>
				</Route>
				<Route path="reprovedPosts">
					<Route
						index
						element={<ReprovedPosts.TableView />}
					/>
					<Route
						path="details/:id"
						element={<ReprovedPosts.Details />}
					/>
				</Route>
			</Routes>
		</MainLayout>
	);
});

export default ClinicRoutes;
