import React, { useState } from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {
	Grid,
	Button,
	Flex,
} from "@chakra-ui/react";
import {
	AutoCompleteModal,
	PetAutoCompleteModalCard,
	DatePicker,
	TextInput,
	TimePicker,
	CentralizedCard,
	ImagePicker,
	SelectedImages,
} from "~/components";
import { useDebounce } from "~/hooks/useDebounce";
import { useHistory } from "~/hooks/useHistory";
import api from "~/resources/api";
import strings from "~/resources/strings";
import Store from "./store";
import { useGlobalStore } from "~/contexts/useGlobalContext";

interface IProps {
	isClinic?: boolean;
}

const Create: React.FC<IProps> = (props) => {
	const { isClinic } = props;
	const history = useHistory();
	const { id } = useParams();
	const { authStore } = useGlobalStore();
	const store = useLocalObservable(() => new Store(isClinic));
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const debounce = useDebounce();
	const pageStrings = strings.pages.hospitalization;
	const commonStrings = strings.common;

	React.useEffect(()=> {
		const fetchData = async () => {
			await authStore.getCurrentAdminUser();
			store.clinicId.setValue(authStore.currentAdminUser?.clinic?.id || "");
			await store.paginetedListShelfToPet.fetchPage(0);
		};
		fetchData();
	},[]);

	React.useEffect(()=>{
		if (store.imageList.value[0] === null) {
			store.imageList.setValue([store.imageShelf.uploadedImage!]);
		} else {
			store.imageList.setValue([...store.imageList.value, store.imageShelf.uploadedImage!]);
		}
	}, [store.imageShelf.getPickerFields().uncertainfiedImage]);

	const onSucess = () => {
		history.goBack();
	};

	const onAdd = () => {
		store.createHospitalization(id || "", onSucess);
	};

	const setValuesModalPet = (item: api.Pet) => {
		store.petId.setValue(item.id);
		store.petSearched.setValue(item.name);
		setModalIsOpen(false);
	};
	const onRemove = (index: number) =>{
		store.imageList.value.splice(index, 1);
	};
	const openModalPet = () => {
		store.petSearched.setValue("");
		setModalIsOpen(true);
	};
	const petFiltered = (items: api.Pet[]) => items.filter((pet) => store.petSearched.value === pet.name);

	return (
		<CentralizedCard
			title={{ text: pageStrings.create.title }}
			button={(
				<Button
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={() => onAdd()}
				>
					{commonStrings.buttons.add}
				</Button>
			)}
		>
			<>
				<TextInput
					isReadOnly
					cursor="pointer"
					onClick={() => openModalPet()}
					labelText={commonStrings.fields.petName}
					labelProps={{ fontWeight: "bold" }}
					labelColor="primary"
					type="text"
					value={store.petSearched.value}
					isDisabled={store.loader.isLoading}
				/>
				<AutoCompleteModal
					isOpen={modalIsOpen}
					onClose={() => setModalIsOpen(false)}
					header={
						<TextInput
							placeholder={strings.components.searchInput.placeholder}
							onKeyUp={(e) => {
								const input = e.target as HTMLInputElement;
								debounce.clearTimer();
								debounce.setTimer(setTimeout(() => store.petSearched.setValue(input.value), 500));
							}}
						/>
					}
					listProps={{
						data: store.petSearched.value ?
							petFiltered(store.paginetedListShelfToPet.items)
							:
							store.paginetedListShelfToPet.items,
						loading: store.paginetedListShelfToPet.loader.isLoading,
						renderItem: (item) => (
							<PetAutoCompleteModalCard
								pet={item}
								onClick={() => setValuesModalPet(item)}
							/>
						),
						paginantionProps: {
							currentPage: store.paginetedListShelfToPet.page,
							nextPage: store.paginetedListShelfToPet.nextPage,
							prevPage: store.paginetedListShelfToPet.previousPage,
							hasNextPage: store.paginetedListShelfToPet.hasNextPage,
						},
					}}
				/>
				<Grid
					gap={5}
					gridTemplateColumns={{
						base: "1fr",
						sm: "1fr 1fr",
					}}
				>
					<DatePicker
						label={commonStrings.fields.startDate}
						onChangeDate={store.startDate.setValue}
						selectedDate={store.startDate.value}
						inputProps={{ h: "40px", textAlign: "center" }}
						color="primary.500"
					/>
					<TimePicker
						label={commonStrings.fields.entryTime}
						onChangeDate={store.entryTime.setValue}
						selectedDate={store.entryTime.value}
						inputProps={{ h: "40px", textAlign: "center" }}
						w="100%"
					/>
					<DatePicker
						label={commonStrings.fields.endDate}
						onChangeDate={store.endDate.setValue}
						selectedDate={store.endDate.value}
						inputProps={{ h: "40px", textAlign: "center" }}
						color="primary.500"
					/>
					<TimePicker
						label={commonStrings.fields.exitTime}
						onChangeDate={store.exitTime.setValue}
						selectedDate={store.exitTime.value}
						inputProps={{ h: "40px", textAlign: "center" }}
						w="100%"
					/>
				</Grid>
			</>
			<Flex gap={2} flexWrap="wrap">
				<ImagePicker
					pickImage={() => {
						store.imageShelf.getPickerFields().pick();
					}}
					src={null}
					loading={store.imageShelf.loader.isLoading}
				/>
				{store.imageList.value[0] !== null && (
					store.imageList.value.map((image, index) =>
						<SelectedImages
							src={image.url}
							key={index}
							onRemove={() => onRemove(index)}
						/>,
					))}
			</Flex>
		</CentralizedCard>
	);
};

export default observer(Create);
