import React from "react";
import {
	Box,
	BoxProps,
	Input,
	RadioProps,
	useMediaQuery,
	useRadio,
} from "@chakra-ui/react";

interface IProps{
	radioProps: RadioProps;
	onClick: () => void;
	boxProps?: BoxProps;
	children: React.ReactNode;
}

export const RadioCard: React.FC<IProps> = (props) => {
	const {
		radioProps,
		onClick,
		boxProps,
		children,
	} = props;
	const { getInputProps, getCheckboxProps } = useRadio(radioProps);
	const [isSm] = useMediaQuery("(min-width: 700px)");
	const input = getInputProps();
	const checkbox = getCheckboxProps();

	return (
		<Box
			as='label'
			w="100%"
			bg="white"
			h={!isSm ? "unset" : 114}
			borderRadius='md'
			boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
			borderWidth='1px'
			{...boxProps}
			{...checkbox}
			color="teal.800"
			_checked={{
				bg: "primary.600",
				color: "white",
			}}
		>
			<Input display="none" {...input} />
			<Box
				{...checkbox}
				minW={168}
				onClick={onClick}
				cursor='pointer'
				px={5}
				py={3}
			>
				{children}
			</Box>
		</Box>
	);

};
