import React from "react";
import { observer } from "mobx-react-lite";
import {
	FormControlProps,
	Flex,
	Grid,
} from "@chakra-ui/react";
import { GrFormClose } from "react-icons/gr";
import { DetailsRow } from "~/components";
import api from "~/resources/api";
import strings from "~/resources/strings";

interface IProps extends FormControlProps {
	fluidTherapyse: api.FluidTherapys[];
	onRemove?: (id: number) => void;
}

export const SelectedMedicationsFluidTherapyse: React.FC<IProps> = observer((props) => {
	const { fluidTherapyse, onRemove } = props;
	const commonStrings = strings.common;


	return (
		<Grid
			w="100%"
			gap={1}
			gridTemplateColumns="1fr"
		>
			{fluidTherapyse && fluidTherapyse.map((item, index) => (
				<Grid
					key={index}
					gridTemplateColumns={{
						base: "1fr",
						xl: "repeat(2,1fr)",
					}}
					gap={3}
					border="1px solid"
					borderColor="blackAlpha.100"
					p={3}
					rounded="md"
					boxShadow="md"
					mb={2}
					position="relative"
				>
					<Flex
						position="absolute"
						right={4}
						rounded="full"
						top={1}
						cursor="pointer"
						_hover={{
							backgroundColor: "primary.100",
						}}
					>
						{onRemove && <GrFormClose size={30} onClick={() => onRemove(index)} />}
					</Flex>
					<Flex>
						<DetailsRow value={item.solution} label={commonStrings.fields.solution} />
					</Flex>
					<Flex gap={3}>
						<DetailsRow value={item.fluidtherapyRate} label={commonStrings.fields.fluidtherapyRate} />
						<DetailsRow value={item.velocity} label={commonStrings.fields.velocity} />
					</Flex>
				</Grid>
			))}
		</Grid>
	);
});
