import React from "react";
import { observer } from "mobx-react-lite";
import {
	Grid,
	Heading,
} from "@chakra-ui/react";
import {
	DetailsAnamnese,
	DetailsRow,
	ImageWithModal,
} from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";

interface IProps {
	consult: api.Consultation;
}

export const DetailsConsult: React.FC<IProps> = observer((props) => {
	const commonStrings = strings.common;
	const { consult } = props;

	return (
		<>
			<Heading
				fontSize="md"
				color="green.500"
			>
				{strings.common.fields.scheduling}
			</Heading>
			<ImageWithModal src={consult.clinic.image.url} />
			<DetailsRow
				label={commonStrings.fields.name}
				value={consult.clinic.adminUser?.name || ""}
			/>
			<DetailsRow
				label={commonStrings.fields.documentNumber}
				value={consult.clinic.adminUser?.documentNumber || ""}
			/>
			<Grid gap={4} gridTemplateColumns={{base: "fr", md:"1fr 1fr"}}>
				<DetailsRow
					label={commonStrings.fields.user}
					value={consult.user.name}
				/>
				<DetailsRow
					label={commonStrings.fields.petName}
					value={consult.pet.name}
				/>
				<DetailsRow
					label={commonStrings.fields.vet}
					value={consult.vet.name}
				/>
				<DetailsRow
					label={commonStrings.fields.date}
					value={strings.format.date(consult.date)}
				/>
				<DetailsRow
					label={commonStrings.fields.hour}
					value={strings.format.timezoneConverter(consult.hour)}
				/>
				{consult.finishedAt && (
					<DetailsRow
						label={commonStrings.fields.finish}
						value={strings.format.date(consult.finishedAt)}
					/>
				)}
			</Grid>
			{consult.description && (
				<DetailsRow
					label={commonStrings.fields.commentDescription}
					value={consult.description}
				/>
			)}
			<DetailsAnamnese anamnese={consult.anamnese} fluidtherapys={consult.fluidtherapys} />
		</>
	);
});
