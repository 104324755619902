import { makeAutoObservable } from "mobx";
import {
	AttributeShelf,
	FetchModelShelf,
	LoaderShelf,
} from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";

export default class Store {

	public loader = new LoaderShelf();
	public fetchModelShelf: FetchModelShelf<api.ClinicStatsForAdmin | api.ClinicStats>;
	public modalSelected = new AttributeShelf<number | null>(null);
	public startDate = new AttributeShelf<Date | null>(null);
	public endDate = new AttributeShelf<Date | null>(null);
	public id = new AttributeShelf<string>("");

	constructor(clinidId: string, isClinic?: boolean) {
		makeAutoObservable(this);
		if (isClinic) {
			this.fetchModelShelf = new FetchModelShelf(clinidId,
				() => api.getStatsForClinic(this.id.value, this.filterOptions),
				{
					onFetchError,
				},
			);
		} else {
			this.fetchModelShelf = new FetchModelShelf("",
				() => api.getStatsForAdmin(this.filterOptions),
				{
					onFetchError,
				},
			);
		}
	}

	public get filterOptions(): api.FilterByPeriod{
		return {
			startDate: this.startDate.value,
			endDate: this.endDate.value,
		};
	}
}
