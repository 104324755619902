import React from "react";
import { useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	chakra,
	Heading,
	Stack,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import { Store } from "./store";
import {
	ButtonBack,
	CentralizedCard,
	DetailsRow,
	ImageWithModal,
	Label,
} from "~/components";

const Details: React.FC = () => {
	const commonStrings = strings.common;
	const { id } = useParams<"id">();
	const store = useLocalObservable(() => new Store(id || ""));

	return (
		<CentralizedCard
			title={{
				text: commonStrings.detailsTitle,
			}}
			altMaxWidth
			isLoading={store.fetchModelShelf.initialLoader.isLoading}
			button={<ButtonBack />}
		>
			{
				store.fetchModelShelf.model.value &&
					<>
						<ImageWithModal src={store.fetchModelShelf.fetchedModel.image.url} />
						<DetailsRow
							label={commonStrings.fields.title}
							value={store.fetchModelShelf.fetchedModel.title}
						/>
						<DetailsRow
							label={commonStrings.fields.slug}
							value={store.fetchModelShelf.fetchedModel.slug}
						/>
						{
							store.fetchModelShelf.model.value.description &&
								<Stack spacing={1}>
									<Label fontSize="md" fontWeight="bold">{commonStrings.fields.description}</Label>
									<chakra.div
										p={2}
										borderRadius="lg"
										backgroundColor="teal.50"
										dangerouslySetInnerHTML={{ __html: store.fetchModelShelf.fetchedModel.description }}
									/>
								</Stack>
						}
						<Heading
							fontSize={20}
							color="primary.500"
							pt={5}
						>
							{commonStrings.fields.reviewOfAdmin}
						</Heading>
						<DetailsRow
							label={commonStrings.fields.review}
							value={store.fetchModelShelf.fetchedModel.review}
						/>
					</>
			}
		</CentralizedCard>
	);
};

export default observer(Details);
