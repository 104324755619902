import React from "react";
import { observer } from "mobx-react-lite";
import { Flex, Grid, StackProps } from "@chakra-ui/react";
import strings from "~/resources/strings";
import { VaccineShelf } from "~/shelves/VaccineShelf";
import {
	TextInput,
	ModalComponent,
	ImagePicker,
	Label,
	CalendarPicker,
} from "~/components";

interface IProps extends StackProps {
	vaccineShelf: VaccineShelf;
	isOpen: boolean;
	onClose: () => void;
	onCreate: () => void;
}

export const VaccineFormModal: React.FC<IProps> = observer((props) => {
	const {
		vaccineShelf,
		onClose,
		isOpen,
		onCreate,
	} = props;
	const commonStrings = strings.common;

	return (
		<ModalComponent
			buttonText={commonStrings.create}
			title={strings.common.modal.titleVaccine}
			onClick={() => onCreate()}
			isOpen={isOpen}
			onClose={onClose}
			gap={5}
		>
			<Flex direction="column" gap={3}>
				<Flex direction="column" mb={2}>
					<Label
						marginBottom={1}
						fontWeight="bold"
						fontSize="md"
						color="gray.800"
						mb={2}
					>
						{strings.components.imagePicker.selectAnImage}
					</Label>
					<ImagePicker
						src={vaccineShelf.imageShelf.uploadedImage?.url || "/pick_image.svg"}
						pickImage={vaccineShelf.imageShelf.getPickerFields().pick}
					/>
				</Flex>
				<TextInput
					mt={2}
					labelText={commonStrings.fields.name}
					labelProps={{ fontWeight: "bold" }}
					variant="filled"
					type="text"
					isDisabled={vaccineShelf.loader.isLoading}
					errorText={vaccineShelf.formShelf.field("name").error}
					{...vaccineShelf.formShelf.field("name")}
				/>
				<Grid
					gridTemplateColumns={{
						md: "repeat(2,1fr)",
					}}
					gap={3}
				>
					<TextInput
						labelText={commonStrings.fields.code}
						labelProps={{ fontWeight: "bold" }}
						variant="filled"
						type="number"
						isDisabled={vaccineShelf.loader.isLoading}
						onChange={(e) => vaccineShelf.code.setValue(Number(e.target.value))}
					/>
					<TextInput
						labelText={commonStrings.fields.batch}
						labelProps={{ fontWeight: "bold" }}
						variant="filled"
						type="number"
						isDisabled={vaccineShelf.loader.isLoading}
						errorText={vaccineShelf.formShelf.field("batch").error}
						{...vaccineShelf.formShelf.field("batch")}
					/>
				</Grid>

				<Grid
					gridTemplateColumns={{
						md: "repeat(2,1fr)",
					}}
					gap={3}
				>
					<CalendarPicker
						color="gray.800"
						label={commonStrings.fields.appliedAt}
						labelProps={{ color: "teal.900" }}
						onChange={vaccineShelf.appliedAt.setValue}
						value={vaccineShelf.appliedAt.value}
						textProps={{letterSpacing:"2px", textAlign: "center"}}
					/>
					<CalendarPicker
						color="gray.800"
						label={commonStrings.fields.nextVaccinationAt}
						labelProps={{ color: "teal.900" }}
						onChange={vaccineShelf.nextVaccinationAt.setValue}
						value={vaccineShelf.nextVaccinationAt.value}
						textProps={{letterSpacing:"2px", textAlign: "center"}}
					/>
				</Grid>
				<Grid
					gridTemplateColumns={{
						md: "repeat(2,1fr)",
					}}
					gap={3}
				>
					<CalendarPicker
						color="gray.800"
						label={commonStrings.fields.manufacturingDateAt}
						labelProps={{ color: "teal.900" }}
						onChange={vaccineShelf.manufacturingDateAt.setValue}
						value={vaccineShelf.manufacturingDateAt.value}
						textProps={{letterSpacing:"2px", textAlign: "center"}}
					/>
					<CalendarPicker
						color="gray.800"
						label={commonStrings.fields.expirationDateAt}
						labelProps={{ color: "teal.900" }}
						onChange={vaccineShelf.expirationDateAt.setValue}
						value={vaccineShelf.expirationDateAt.value}
						textProps={{letterSpacing:"2px", textAlign: "center"}}
					/>
				</Grid>
			</Flex>
		</ModalComponent>
	);
});

