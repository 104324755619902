import React from "react";
import { observer } from "mobx-react-lite";
import {
	Flex,
	Grid,
	Heading,
	Text,
} from "@chakra-ui/react";
import { DetailsRow, ImageViewer } from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";

interface IProps {
	hospitalization: api.Hospitalization;
}

export const DetailsHospitalization: React.FC<IProps> = observer((props) => {
	const commonStrings = strings.common;
	const { hospitalization } = props;
	const imageUrls = hospitalization.images! && hospitalization.images.map((item) => item.url);

	return (
		<>
			<Heading
				fontSize="md"
				color="green.500"
			>
				{strings.pages.hospitalization.details.title}
			</Heading>
			<Grid gap={4} gridTemplateColumns={{ base: "1fr", md: "1fr 1fr" }}>
				<DetailsRow
					isOutline
					label={commonStrings.fields.petName}
					value={hospitalization.pet.name}
				/>
				<DetailsRow
					isOutline
					label={commonStrings.fields.id}
					value={hospitalization.pet.codeFormat}
				/>
				<DetailsRow
					isOutline
					label={commonStrings.fields.age}
					value={hospitalization.pet.age}
				/>
				<DetailsRow
					isOutline
					label={commonStrings.fields.breed}
					value={hospitalization.pet.breed}
				/>
				<DetailsRow
					isOutline
					label={commonStrings.fields.specie}
					value={api.translateSpecies(hospitalization.pet.species)}
				/>
				<DetailsRow
					isOutline
					label={commonStrings.fields.animalSex}
					value={api.translateAnimalSex(hospitalization.pet.animalSex)}
				/>
				<DetailsRow
					isOutline
					label={commonStrings.fields.startDate}
					value={strings.format.date(hospitalization.startDate)}
				/>
				<DetailsRow
					isOutline
					label={commonStrings.fields.entryTime}
					value={strings.format.timezoneConverter(hospitalization.entryTime)}
				/>
				<DetailsRow
					isOutline
					label={commonStrings.fields.endDate}
					value={hospitalization.endDate ? strings.format.date(hospitalization.endDate) : strings.pages.hospitalization.table.unscheduled}
				/>
				<DetailsRow
					isOutline
					label={commonStrings.fields.exitTime}
					value={hospitalization.exitTime ? strings.format.timezoneConverter(hospitalization.exitTime) : strings.pages.hospitalization.table.unscheduled}
				/>
			</Grid>
			<Flex direction="column" gap={3} display={hospitalization.images ? "initial" : "none"}>
				<Text fontSize="md" color="gray.900" fontWeight="bold">{strings.common.fields.images}</Text>
				<ImageViewer imageUrls={imageUrls} />
			</Flex>
		</>
	);
});
