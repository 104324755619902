import { observer } from "mobx-react-lite";
import React from "react";
import {
	Flex,
	Image,
	Text,
} from "@chakra-ui/react";
import api from "~/resources/api";
import strings from "~/resources/strings";


interface IProps {
	pet: api.Pet;
	onClick: () => void;
}

export const PetAutoCompleteModalCard: React.FC<IProps> = observer((props) => {
	const {
		pet,
		onClick,
	} = props;
	return (
		<Flex
			w="100%"
			alignItems="center"
			onClick={onClick}
			gap={3}
		>
			<Image
				justifySelf="right"
				w={20}
				h={20}
				src={pet.image.url || "/pick_image.svg"}
				borderRadius="10px"
			/>
			<Flex ml={5} direction="column">
				<Text
					color="gray.800"
					cursor="pointer"
					fontWeight="bold"
				>
					{pet.name}
				</Text>
				<Text
					color="gray.900"
					cursor="pointer"
				>
					{strings.components.petAutoCompleteModalCard.petCode(pet.codeFormat)}
				</Text>
			</Flex>
		</Flex>
	);
});
