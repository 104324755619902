import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Flex,
	Tr,
	Td,
} from "@chakra-ui/react";
import {
	FilterPeriod,
	Table,
	TableCellWithActionButtons,
} from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";
import { useHistory } from "~/hooks/useHistory";
import Store from "./store";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import format from "~/resources/format";

interface IProps {
	isClinic?: boolean;
}

const TableView: React.FC<IProps> = (props) => {
	const { isClinic } = props;
	const pageStrings = strings.pages.consultations.table;
	const history = useHistory();
	const { authStore } = useGlobalStore();
	const methodForGetAllConsultations = isClinic ? api.getAllConsultationForAdminClinic : api.getAllConsultation;
	const headerString = isClinic ? pageStrings.header : pageStrings.headerForAdmin;
	const store = useLocalObservable(() => new Store(methodForGetAllConsultations));
	const onGoToDetails = (id: string) => history.push(`details/${id}`);
	const onGoToCreate = () => history.push("create");

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				tableTitle={pageStrings.title}
				onAdd={authStore.currentAdminUser?.clinic ? () => onGoToCreate() : undefined}
				headers={headerString}
				headerFilter={
					<FilterPeriod
						selectedStartDate={store.startDate.value}
						selectedEndDate={store.endDate.value}
						onChangeStartDate={store.startDate.setValue}
						onChangeEndDate={store.endDate.setValue}
						refreshItems={store.paginetedListShelf.refresh}
					/>
				}
				renderRow={(item, index) => (
					<Tr key={index}>
						{!isClinic && <Td>{item.clinic.adminUser?.name}</Td>}
						<Td>{item.user.name}</Td>
						<Td>{item.vet.name}</Td>
						<Td>{item.pet.name}</Td>
						<Td>{format.date(item.date)}</Td>
						<Td>{format.timezoneConverter(item.hour)}</Td>
						<Td>{item.finishedAt && format.date(item.finishedAt)}</Td>
						<TableCellWithActionButtons
							onView={() => onGoToDetails(item.id)}
						/>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
