import { useGlobalStore } from "~/contexts/useGlobalContext";
import api from "~/resources/api";
import strings from "~/resources/strings";

interface ILinkMenu {
	text: string;
	path: string;
}

const useMainRoutes = ():  ILinkMenu[] => {

	const routeAdmin = (path?: string) => `/admin${path ? "/" + path : ""}`;
	const routeClinic = (path?: string) => `/clinic${path ? "/" + path : ""}`;

	const { authStore } = useGlobalStore();

	const adminRoutes = [
		{
			text: strings.nav.home,
			path: routeAdmin(),
		},
		{
			text: strings.nav.adminMaster,
			path: routeAdmin("adminUsers"),
		},
		{
			text: strings.nav.clinics,
			path: routeAdmin("clinics"),
		},
		{
			text: strings.nav.users,
			path: routeAdmin("users"),
		},
		{
			text: strings.nav.consultations,
			path: routeAdmin("consultations"),
		},
		{
			text: strings.nav.pets,
			path: routeAdmin("pets"),
		},
		{
			text: strings.nav.posts,
			path: routeAdmin("posts"),
		},
		{
			text: strings.nav.vets,
			path: routeAdmin("vets"),
		},
		{
			text: strings.nav.hospitalization,
			path: routeAdmin("hospitalization"),
		},
		{
			text: strings.nav.pendingDeleteAccounts,
			path: routeAdmin("pendingDeleteAccounts"),
		},
		{
			text: strings.nav.pendingPosts,
			path: routeAdmin("pendingPosts"),
		},
	];

	const clinicRoutes = [
		{
			text: strings.nav.home,
			path: routeClinic(),
		},
		{
			text: strings.nav.consultations,
			path: routeClinic("consultations"),
		},
		{
			text: strings.nav.hospitalization,
			path: routeClinic("hospitalization"),
		},
		{
			text: strings.nav.users,
			path: routeClinic("users"),
		},
		{
			text: strings.nav.pets,
			path: routeClinic("pets"),
		},
		{
			text: strings.nav.posts,
			path: routeClinic("posts"),
		},
		{
			text: strings.nav.vets,
			path: routeClinic("vets"),
		},
		{
			text: strings.nav.reprovedPosts,
			path: routeClinic("reprovedPosts"),
		},
	];

	if (!authStore.currentAdminUser) {
		return [];
	}

	switch (authStore.currentAdminUser.type){
		case api.AdminUserType.master:
			return adminRoutes;
		case api.AdminUserType.clinic:
			return clinicRoutes;
		default:
			return [];
	}
};

export default useMainRoutes;
