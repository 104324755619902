import { makeAutoObservable } from "mobx";
import { AttributeShelf, LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";

const pageStrings = strings.pages.users.table;

export default class Store {
	public paginetedListShelf: PaginatedListShelf<api.User>;

	public loader = new LoaderShelf();

	public cpf = new AttributeShelf("");

	public getAllUsersByFilterOptions = (page: number) => api.getAllUsers(page, this.cpf.value);

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			this.getAllUsersByFilterOptions,
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
	}

	public deleteUser = async (id: string) => {
		this.loader.start();
		try {
			const deleteUser = await api.deleteUser(id);
			showSuccessToast(pageStrings.delete(deleteUser.name));
			this.paginetedListShelf.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
