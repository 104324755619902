import { makeAutoObservable } from "mobx";
import { FormShelf, ImagePickerShelf } from "@startapp/mobx-utils/src/web";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";

const pageStrings = strings.pages.users;

export default class Store {

	public age = new AttributeShelf<string>("");
	public imageShelf = new ImagePickerShelf(api.uploadImage);
	public birthdate = new AttributeShelf<Date>(new Date());
	public isCastrated = new AttributeShelf<boolean>(false);
	public animalSex = new AttributeShelf<api.AnimalSex>(api.AnimalSex.male);
	public species = new AttributeShelf(api.Species.dog);

	public formShelf = new FormShelf({
		name: "",
		breed: "",
	});

	public loader = new LoaderShelf();

	constructor(){
		makeAutoObservable(this);
	}

	public createPet = async (onSuccess: () => void, user: string | "") => {
		this.loader.start();
		try {
			const data = this.formShelf.getValues();
			await api.createPet({
				name: data.name,
				image: {
					bytes: this.imageShelf.uncertainfiedImage?.bytes || null,
					image: this.imageShelf.uncertainfiedImage?.image|| null,
				},
				age: this.age.value,
				breed: data.breed,
				animalSex: this.animalSex.value,
				isCastrated: this.isCastrated.value,
				species: this.species.value,
				birthdate: this.birthdate.value,
				userId: user,
			});
			showSuccessToast(pageStrings.create.created);
			onSuccess();
		} catch (e) {
			const error = Errors.treatError(e);
			if (error.type === api.ErrorType.Validation) {
				this.formShelf.fieldError.cleanAndAddErrors(JSON.parse((e as Error).message));
			} else {
				const errorMessage = Errors.handleError(e);
				showErrorToast(errorMessage);
			}
		} finally {
			this.loader.end();
		}
	};
}
