import { makeAutoObservable } from "mobx";
import {  LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";

export default class Store{
	public loader = new LoaderShelf();
	public paginetedListShelf: PaginatedListShelf<api.AdminUser>;

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			(page) => api.getAllClinicAdminUsers(page),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
	}

	public deleteClinic = async (id: string) => {
		this.loader.tryStart();
		try {
			await api.deleteAdminUser(id);
			showSuccessToast(strings.pages.clinic.table.delete);
			this.paginetedListShelf.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
