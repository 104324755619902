import React from "react";
import { observer } from "mobx-react-lite";
import { Flex } from "@chakra-ui/react";
import strings from "~/resources/strings";
import {
	CentralizedCard,
	DetailsRow,
	Label,
} from "~/components";
import api from "~/resources/api";

interface IProps {
	rating: api.Rating | null;
}

export const CardAvaliation: React.FC<IProps> = observer((props) => {
	const { rating } = props;
	const componentString = strings.components.avaliation;
	return (
		<>
			<Flex
				w={{
					base:"95%",
					md:"100%",
				}}
				maxW={{
					base:"100%",
					md:"75%",
					lg:"70%",
				}}
			>
				<Label
					fontWeight="bold"
					color="teal.700"
					textAlign="left"
					fontSize="md"
				>
					{componentString.title}
				</Label>
			</Flex>
			<CentralizedCard>
				<DetailsRow
					isAvaliation
					value={rating ? componentString.evaluationNote(rating.consultationRate!) : componentString.notAvaliation}
					label={componentString.clinicAvaliation}
				/>
				<DetailsRow
					isAvaliation
					value={rating ? componentString.evaluationNote(rating.vetRate!) : componentString.notAvaliation}
					label={componentString.vetAvaliation}
				/>
			</CentralizedCard>
		</>
	);
});
