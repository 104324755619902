import { makeAutoObservable } from "mobx";
import { AttributeShelf, PaginatedListShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";

const pageStrings = strings.pages.posts.table;

export default class Store {
	public loader = new LoaderShelf();
	public paginetedListShelf: PaginatedListShelf<api.PostWithoutDescription>;
	public currentAdminUser = new AttributeShelf<api.AdminUserType | undefined>(undefined);

	constructor() {
		makeAutoObservable(this);

		this.paginetedListShelf = new PaginatedListShelf(
			(page: number) => {
				if (this.currentAdminUser.value === api.AdminUserType.clinic) {
					return api.getAllPostsForClinicAdminUser(page);
				} else {
					return api.getAllPosts(page);
				}
			},
		);
	}

	public deletePost = async (id: string) => {
		this.loader.tryStart();
		try {
			await api.deletePost(id);
			showSuccessToast(pageStrings.delete);
			this.paginetedListShelf.refresh();
		} catch (e) {
			const error = Errors.handleError(e);
			showErrorToast(error);
		} finally {
			this.loader.end();
		}
	};
}

