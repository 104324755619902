import { makeAutoObservable } from "mobx";
import { FormShelf, ImagePickerShelf } from "@startapp/mobx-utils/src/web";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";

const pageStrings = strings.pages.adminUserTable.createOrEdit;

export default class Store {

	public state = new AttributeShelf<api.StateUF>(api.StateUF.AC);
	public loader = new LoaderShelf();
	public id = new AttributeShelf("");
	public imageShelf = new ImagePickerShelf(api.uploadImage);

	public formPassword = new FormShelf({
		password: "",
	});
	public formShelf = new FormShelf({
		phone: "",
		name: "",
		email: "",
		documentNumber: "",
		zipcode: "",
		street: "",
		streetNumber: "",
		complementary: "",
		neighborhood: "",
		city: "",
	});

	constructor(id: string){
		makeAutoObservable(this);

		if (id) {
			this.id.setValue(id);
			this.getClinicAdmin(id);
		}
	}
	public getClinicAdmin = async (id: string) => {
		this.loader.tryStart();
		try {
			const clinic = await api.getAdminUser(id);
			this.setInitValues(clinic);
		} catch (e) {
			const error = Errors.handleError(e);
			showErrorToast(error);
		} finally {
			this.loader.end();
		}
	};
	public setInitValues = (data: api.AdminUser) => {
		this.formShelf = new FormShelf({
			phone: data.clinic?.phone || "",
			name: data.name,
			email: data.email,
			documentNumber: data.documentNumber,
			zipcode: data.clinic?.address.zipcode || "",
			street: data.clinic?.address.street || "",
			streetNumber: data.clinic?.address.streetNumber || "",
			complementary: data.clinic?.address.complementary || "",
			neighborhood: data.clinic?.address.neighborhood || "",
			city: data.clinic?.address.city || "",
		});
		this.imageShelf.getPickerFields().setUploadedImage(data.clinic?.image || null);
	};

	public clinicData = () => {
		const data = this.formShelf.getValues();
		const dataPassword = this.formPassword.getValues();
		return {
			name: data.name,
			password: dataPassword.password,
			email: data.email,
			documentNumber: data.documentNumber,
			clinic: {
				phone: data.phone,
				image: {
					bytes: this.imageShelf.uncertainfiedImage?.bytes || null,
					image: this.imageShelf.uncertainfiedImage?.image || null,
				},
				address: {
					zipcode: data.zipcode,
					street: data.street,
					streetNumber: data.streetNumber,
					complementary: data.complementary,
					neighborhood: data.neighborhood,
					city: data.city,
					state: this.state.value,
				},
			},
		};
	};

	public createOrEditClinic = async (onSuccess: () => void) => {
		this.loader.start();
		try {
			if (this.id.value) {
				await api.editClinicAdminUser(this.id.value, this.clinicData());
			} else {
				await api.createClinicAdminUser(this.clinicData());
			}
			showSuccessToast(pageStrings.success(!!this.id.value));
			onSuccess();
		} catch (e) {

			const error = Errors.treatError(e);
			if (error.type === api.ErrorType.Validation) {
				this.formShelf.fieldError.cleanAndAddErrors(JSON.parse((e as Error).message));
			} else {
				const errorMessage = Errors.handleError(e);
				showErrorToast(errorMessage);
			}
		} finally {
			this.loader.end();
		}
	};
}
