import { makeAutoObservable } from "mobx";
import { FormShelf } from "@startapp/mobx-utils/src/web";
import {
	AttributeShelf,
	LoaderShelf,
	PaginatedListShelf,
} from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { onFetchError } from "~/resources/fetchError";
import { ConsultationShelf } from "~/shelves/ConsultationShelf";

const pageStrings = strings.pages.consultations;

export default class Store {

	public date = new AttributeShelf<Date>(new Date());
	public hour = new AttributeShelf<Date>(new Date());
	public paginetedListShelfToVet: PaginatedListShelf<api.Vet>;
	public paginetedListShelfToPet: PaginatedListShelf<api.Pet>;
	public consultationShelf = new ConsultationShelf();
	public loader = new LoaderShelf();
	public id = new AttributeShelf("");
	public name = new AttributeShelf("");
	public vetId = new AttributeShelf<string>("");
	public vetSearched = new AttributeShelf<string>("");
	public petId = new AttributeShelf<string>("");
	public petSearched = new AttributeShelf<string>("");
	public clinicId = new AttributeShelf<string>("");

	public formShelf = new FormShelf({
		description: "",
	});

	constructor(id?: string){
		makeAutoObservable(this);
		if (id) {
			this.getConsult(id);
			this.id.setValue(id);
		} else {
			this.paginetedListShelfToVet = new PaginatedListShelf(
				(page) => api.getAllVetsToNotShowInSite(page),
				{
					fetchOnConstructor: true,
					onFetchError,
				},
			);
			this.paginetedListShelfToPet = new PaginatedListShelf(
				() => api.getAllPetsForClinic(this.clinicId.value),
				{
					fetchOnConstructor: false,
					onFetchError,
				},
			);

		}
	}
	public getConsult = async (id: string) => {
		this.loader.start();
		try {
			const consult = await api.getConsultationByIdForAdminClinic(id);
			this.setInitValues(consult);
		} catch (e) {
			const error = Errors.handleError(e);
			showErrorToast(error);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (consult: api.ConsultationForClinic) => {
		this.formShelf = new FormShelf({
			description: consult.description,
		});
		this.date.setValue(consult.date);
	};

	public createConsult = async (id: string,onSuccess: () => void) => {
		this.loader.start();
		try {
			const dataAnamnese = this.consultationShelf.formShelfAnamnese.getValues();
			const data = this.formShelf.getValues();
			if (this.id.value) {
				await api.editConsultationForAdminClinic(this.id.value,{
					date: this.date.value,
					hour: this.hour.value,
					description: data.description,
					anamnese: dataAnamnese,
					fluidtherapys: this.consultationShelf.fluidTherapyse.value,
				});
			} else {
				await api.createConsultationForAdminClinic({
					vetId: this.vetId.value,
					petId: this.petId.value,
					date: this.date.value,
					hour: this.hour.value,
					description: data.description,
					anamnese: dataAnamnese,
					fluidtherapys: this.consultationShelf.fluidTherapyse.value,
				});
			}
			showSuccessToast(pageStrings.createOrEdit.onSuccess(!!id));
			onSuccess();
		} catch (e) {
			const error = Errors.treatError(e);
			if (error.type === api.ErrorType.Validation) {
				this.formShelf.fieldError.cleanAndAddErrors(JSON.parse((e as Error).message));
			} else {
				const errorMessage = Errors.handleError(e);
				showErrorToast(errorMessage);
			}
		} finally {
			this.loader.end();
		}
	};

	public addMedicationTofluidtherapy = () => {
		const data = this.consultationShelf.formShelfAnamnese.getValues();
		this.consultationShelf.fluidTherapyse.setValue([
			...this.consultationShelf.fluidTherapyse.value,
			{
				solution: data.solution,
				fluidtherapyRate: data.fluidtherapyRate,
				velocity: data.velocity,
			}]);
		data.solution = "";
		data.fluidtherapyRate = "";
		data.velocity = "";
	};
}
