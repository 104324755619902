import { makeAutoObservable } from "mobx";
import { AttributeShelf, LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";

const pageStrings = strings.pages.pets.table;

export default class Store {
	public paginetedListShelf: PaginatedListShelf<api.Pet>;
	public loader = new LoaderShelf();
	public name = new AttributeShelf("");
	public id = new AttributeShelf("");

	constructor(clinicId?: string) {
		makeAutoObservable(this);
		if (clinicId) {
			this.paginetedListShelf = new PaginatedListShelf(
				() => api.getAllPetsForClinic(clinicId),
				{
					fetchOnConstructor: true,
					onFetchError,
				},
			);
		} else {
			this.paginetedListShelf = new PaginatedListShelf(
				(page) => api.getAllPets(page, this.name.value),
				{
					fetchOnConstructor: true,
					onFetchError,
				},
			);
		}
	}

	public deletePet = async (id: string) => {
		this.loader.start();
		try {
			const deletePet = await api.deletePetById(id);
			showSuccessToast(pageStrings.delete(deletePet.name));
			this.paginetedListShelf.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
