import { observer } from "mobx-react-lite";
import React from "react";
import {
	Flex,
	Text,
} from "@chakra-ui/react";
import api from "~/resources/api";
import strings from "~/resources/strings";


interface IProps {
	vet: api.Vet;
	onClick: () => void;
}

export const VetAutoCompleteModalCard: React.FC<IProps> = observer((props) => {
	const {
		vet,
		onClick,
	} = props;
	return (
		<Flex
			w="100%"
			alignItems="center"
			onClick={onClick}
			gap={3}
		>
			<Flex ml={5} direction="column">
				<Text
					color="gray.800"
					cursor="pointer"
					fontWeight="bold"
				>
					{vet.name}
				</Text>
				<Text
					color="gray.900"
					cursor="pointer"
				>
					{strings.components.vetAutoCompleteModalCard.vetDocument(vet.documentNumber)}
				</Text>
			</Flex>
		</Flex>
	);
});
