import React from "react";
import { Heading } from "@chakra-ui/react";
import { ClinicAddressDetails, ImageWithModal } from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";

interface IProps {
	consultation: api.Consultation;
}

export const DetailsClinicConsultion: React.FC<IProps> = (props) => {
	const { consultation } = props;

	return (
		<>
			<Heading
				mt={1}
				fontSize="md"
				color="green.500"
			>
				{strings.common.fields.clinic}
			</Heading>
			<ImageWithModal src={consultation.clinic.image.url} />
			<ClinicAddressDetails address={consultation.clinic.address} />
		</>
	);
};
