import TableView from "./TableView";
import Details from "./Details";
import Create from "./Create";

export const Users = {
	TableView,
	Details,
	Create,
};

