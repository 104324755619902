const green = {
	50: "#EBF9EE",
	100: "#C8EFCF",
	200: "#A4E4B0",
	300: "#81DA91",
	400: "#5ECF72",
	450: "#3AC553",
	500: "#33AD49",
	600: "#2E9E42",
	700: "#237632",
	800: "#174F21",
	900: "#0C2711",
};
const teal = {
	50: "#F5F5F5",
	100: "#DBDBDB",
	200: "#C4C4C4",
	300: "#ADADAD",
	400: "#969696",
	500: "#808080",
	600: "#666666",
	700: "#606060",
	800: "#4A4A4A",
	900: "#1A1A1A",
};

export const colors = {
	primary: green,
	green,
	teal,
};
