import React from "react";
import { useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";

import {
	IconButton,
	Tooltip,
	Flex,
} from "@chakra-ui/react";

import {
	ButtonBack,
	CentralizedCard,
	ClinicAddressDetails,
	DetailsRow,
	ImageWithModal,
} from "~/components";
import strings from "~/resources/strings";
import { Store } from "./store";

const Details: React.FC = () => {
	const commonStrings = strings.common;
	const { id } = useParams<"id">();
	const store = useLocalObservable(() => new Store(id || ""));

	const avaliationValidate = (avaliation: number | null) => {
		if (avaliation) {
			return avaliation;
		}
		return commonStrings.noAvaliation;
	}
;
	return (
		<Flex
			justifyContent="center"
			flexDirection="column"
			alignItems="center"
		>
			<>
				<CentralizedCard
					title={{
						text: commonStrings.detailsTitle,
						helper: (
							<Tooltip label={strings.common.edit}>
								<IconButton
									variant="icon"
									aria-label="Voltar"
									size="lg"
								/>
							</Tooltip>
						),
					}}
					isLoading={store.fetchModelShelf.initialLoader.isLoading}
				>
					{
						store.fetchModelShelf.model.value && store.fetchModelShelf.fetchedModel.clinic &&
							<>
								<ImageWithModal src={store.fetchModelShelf.fetchedModel.clinic?.image.url} />
								<DetailsRow
									label={commonStrings.fields.name}
									value={store.fetchModelShelf.fetchedModel.name}
								/>
								<DetailsRow
									label={commonStrings.fields.email}
									value={store.fetchModelShelf.fetchedModel.email}
								/>
								<DetailsRow
									label={commonStrings.fields.cnpj}
									value={store.fetchModelShelf.fetchedModel.documentNumber}
								/>
								<DetailsRow
									label={commonStrings.fields.code}
									value={store.fetchModelShelf.fetchedModel.clinic?.code || strings.common.empty}
								/>
								<DetailsRow
									label={commonStrings.fields.id}
									value={store.fetchModelShelf.fetchedModel.clinic?.codeFormat || strings.common.empty}
								/>
								{
									store.fetchModelShelf.fetchedModel.clinic?.services &&
										<DetailsRow
											label={commonStrings.fields.services}
											value={store.fetchModelShelf.fetchedModel.clinic?.services}
										/>
								}
								<DetailsRow
									label={commonStrings.fields.phone}
									value={store.fetchModelShelf.fetchedModel.clinic?.phone || strings.common.empty}
								/>
								<ClinicAddressDetails address={store.fetchModelShelf.fetchedModel.clinic?.address} />
								<Flex
									w={{
										base: "100%",
										md:"49.3%",
									}}
								>
									<DetailsRow
										isAvaliation
										label={commonStrings.fields.avaliation}
										value={avaliationValidate(store.clinicRating.value)}
									/>
								</Flex>
							</>
					}
				</CentralizedCard>
				<ButtonBack />
			</>
		</Flex>
	);
};

export default observer(Details);
