import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Button } from "@chakra-ui/react";
import { useHistory } from "~/hooks/useHistory";
import { CentralizedCard, TextInput } from "~/components";
import strings from "~/resources/strings";
import Store from "./store";
import { useGlobalStore } from "~/contexts/useGlobalContext";

const Create: React.FC = () => {
	const history = useHistory();
	const { authStore } = useGlobalStore();
	const store = useLocalObservable(() => new Store());
	const pageStrings = strings.pages.vets;
	const commonStrings = strings.common;

	React.useEffect(()=>{
		store.id.setValue(authStore.currentAdminUser?.clinic?.id);
	},[authStore.currentAdminUser]);
	const onSucess = () => {
		history.goBack();
	};

	return (
		<CentralizedCard
			title={{ text: pageStrings.create.title }}
			button={(
				<Button
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={() => store.createVet(onSucess)}
				>
					{commonStrings.buttons.add}
				</Button>
			)}
		>
			<TextInput
				labelText={commonStrings.fields.name}
				labelProps={{ fontWeight: "bold" }}
				labelColor="primary"
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("name").error}
				{...store.formShelf.field("name")}
			/>
			<TextInput
				labelText={commonStrings.fields.cpf}
				labelProps={{ fontWeight: "bold" }}
				labelColor="primary"
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("documentNumber").error}
				{...store.formShelf.field("documentNumber")}
				mask="999.999.999-99"
			/>
		</CentralizedCard>
	);
};

export default observer(Create);
