import React from "react";
import { observer } from "mobx-react-lite";
import {
	Flex,
	Td,
	Tr,
} from "@chakra-ui/react";
import {
	Label,
	Table,
	TableCellWithActionButtons,
} from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";
import format from "~/resources/format";

interface IProps {
	hospitalization: api.Hospitalization[] | api.HospitalizationForClinic[];
	previousPage: () => void;
	nextPage: () => void;
	page: number;
	isLoading: boolean;
	hasNextPage: boolean;
	onGoToDetails: (id: string) => void;
}

export const TableOfHospitalizationByPet: React.FC<IProps> = observer((props) => {
	const {
		hospitalization,
		isLoading,
		page,
		nextPage,
		previousPage,
		hasNextPage,
		onGoToDetails,
	} = props;

	const pageStrings = strings.pages.hospitalization.table;

	return (
		<Flex
			direction="column"
			w={{base:"95%",md:"100%"}}
			maxW={{
				base:"100%",
				md:"75%",
				lg:"70%",
			}}
			borderRadius={12}
			mb={5}
		>
			<Label
				mb={2}
				fontWeight="bold"
				color="teal.700"
			>
				{strings.pages.pets.details.hospitalization}
			</Label>
			<Table
				maxHeight={{ base: 450, md: 600 }}
				data={hospitalization}
				loading={isLoading}
				headers={pageStrings.header}
				isCard
				boxShadow="xl"
				renderRow={(item, index) => (
					<Tr key={index}>
						<Td>{item.pet.name}</Td>
						<Td>{format.date(item.startDate)}</Td>
						<Td>{format.date(item.endDate)}</Td>
						<Td>{format.timezoneConverter(item.entryTime)}</Td>
						<Td>{format.timezoneConverter(item.exitTime)}</Td>
						<TableCellWithActionButtons
							onView={() => onGoToDetails(item.id)}
						/>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={page}
				prevPage={previousPage}
				nextPage={nextPage}
				hasNextPage={hasNextPage}
			/>
		</Flex>
	);
});
